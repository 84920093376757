import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useTransition } from '@react-spring/web';
import _ from 'lodash';
import { AgentType } from '@/aiAssistant/aiAssistant.types';

interface AiGetStartedCarouselProps {
  selectedAgent: AgentType;
}

export const AiGetStartedCarousel: React.FunctionComponent<AiGetStartedCarouselProps> = ({ selectedAgent }) => {
  const { t } = useTranslation();
  const sharedPrompts = _.map(_.range(3, 9), (i) => `AI_ASSISTANT.TEACHING_PROMPT_${i}`);
  // after the first prompt we need to insert some custom prompts
  const [index, setIndex] = useState(0);
  const agentPrompts = () => {
    switch (selectedAgent) {
      case 'formula':
        return ['AI_ASSISTANT.TEACHING_PROMPT_2_FORMULA_1', 'AI_ASSISTANT.TEACHING_PROMPT_2_FORMULA_2'];
      case 'code':
        return ['AI_ASSISTANT.TEACHING_PROMPT_2_DATALAB_1', 'AI_ASSISTANT.TEACHING_PROMPT_2_DATALAB_2'];
      case 'workbench':
        return [
          'AI_ASSISTANT.TEACHING_PROMPT_2_ACTIONS_1',
          'AI_ASSISTANT.TEACHING_PROMPT_2_ACTIONS_2',
          'AI_ASSISTANT.TEACHING_PROMPT_2_ACTIONS_3',
        ];
      default:
        return ['AI_ASSISTANT.TEACHING_PROMPT_2_GENERAL_1', 'AI_ASSISTANT.TEACHING_PROMPT_2_GENERAL_2'];
    }
  };

  const prompts = ['AI_ASSISTANT.TEACHING_PROMPT_1', ...agentPrompts(), ...sharedPrompts];
  const maxIndex = prompts.length * 3; // repeat 3 times
  const rotationCounterRef = React.useRef(0);

  useEffect(() => {
    setIndex(0);
    rotationCounterRef.current = 0;

    const interval = setInterval(() => {
      if (rotationCounterRef.current < maxIndex) {
        setIndex((state) => (state + 1) % prompts.length);
      } else {
        clearInterval(interval);
      }
      rotationCounterRef.current += 1;
    }, 4000);

    return () => clearInterval(interval);
  }, [selectedAgent]);

  const transitions = useTransition(index, {
    keys: null,
    exitBeforeEnter: true,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  });

  return (
    <div className="flexRowContainer promptTitle text-center width-maximum flexAlignCenter height-80 pl15 pr15">
      {transitions((style, i) => (
        <animated.div style={style}>{t(prompts[i])}</animated.div>
      ))}
    </div>
  );
};
