// @ts-strict-ignore
import { cancelRunningRequest } from '@/utilities/http.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Deferred } from '@/utilities/utilities';
import { sqRequestsApi } from '@/sdk/api/RequestsApi';
import { cancelWait, isOutstandingRequest } from '@/core/asyncResponses.utilities';
import { logError } from '@/utilities/logger';
import { APPSERVER_API_PREFIX, SOCKET_LIVELINESS_TIMEOUT } from '@/main/app.constants';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import { addCsrfHeader } from '@/utilities/auth.utilities';
import { sqWorkbenchStore } from '@/core/core.stores';

export const CANCELLATION_GROUP_GUID_SEPARATOR = ':';
let cancelers = {} as any;
let unsubscribe: () => void = _.noop;

// Array of promises that must either complete or abort before we can switch worksheets.
let volatilePromises = [];

/**
 * Creates a new deferred instance and adds it to the cancelers map.
 *
 * @param {String} id - Unique request identifier for the http request
 * @param {Boolean} [config.cancelOnServer=true] - If false then request will not be canceled on the backend when it
 *                 is canceled in the browser.
 * @param {Object[]} [groups] - One or more groups to which the canceler belongs. For use with cancelGroup()
 * @return {Promise} Unresolved promise that can be used as the `timeout` parameter for an http request.
 */
export function addPendingRequest(id, config, groups?) {
  _.defaults(config, { cancelOnServer: true });
  return addCanceler(id, config, groups);
}

export function addCanceler(id, config, groups) {
  cancelers[id] = { groups, deferred: new Deferred(), config };
  return cancelers[id].deferred;
}

export function getCancelers() {
  return cancelers;
}

/**
 * Returns a canceler object for the specified request
 *
 * @param  {String} id - Unique request identifier
 * @return {Object[]} The deferred and groups for the request
 */
export function getCanceler(id) {
  return cancelers[id];
}

export function removeCanceler(id) {
  delete cancelers[id];
}

/**
 * Returns an array of all IDs for all outstanding requests
 *
 * @returns {String[]} of IDs for all outstanding requests
 */
export function getAllRequestIds() {
  return _.keys(cancelers);
}

/**
 * Removes the canceler identified by the specified id. Should be called when a request returns.
 * Updates the progress only to ensure we do not over-write the timing and meter Information that is still
 * required to display request details.
 *
 * @param  {String} id - Unique request identifier
 */
export function removePendingRequest(id) {
  updateProgress(id, { progress: undefined });
  removeCanceler(id);
}

/**
 * Resolves the canceler for the specified request. Suppresses 404 errors, which indicate that the request is no
 * longer running.
 *
 * @param  {String} id - Unique request identifier
 * @param {Boolean} [localOnly=false] - optionally cancel browser requests only
 * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
 * to the user when the cancellation call is just prior to another call to fetch the group again (e.g. signals in
 * trend actions). The additional context allows us to guard against erroneously setting the trend item data
 * status to cancelled (see sqTrendActions.catchItemDataFailure() for more details).
 */
export function cancel(id, localOnly = false, refetching = false) {
  const canceler = getCanceler(id);
  if (canceler) {
    canceler.config.refetching = refetching;
    canceler.deferred.resolve();
    cancelWait(id);
    removeCanceler(id);

    if (canceler.config.cancelOnServer && !localOnly) {
      return cancelRunningRequest(id);
    }
  }
}

/**
 * Cancels all registered requests
 *
 * @param {Boolean} [localOnly=false] - optionally cancel browser requests only
 * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
 * @return {Promise} Resolves when all the requests have been cancelled
 */
export function cancelAll(localOnly = false, refetching = false) {
  return _.chain(getCancelers())
    .keys()
    .map((requestId) => cancel(requestId, localOnly, refetching))
    .thru((requests) => Promise.all(requests))
    .value();
}

/**
 * Cancels all registered requests for the specified group
 *
 * @param {String} group - The group to which the request belongs
 * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
 * to the user when the cancellation call is just prior to another call to fetch the group again (e.g. signals in
 * trend actions). The additional context allows us to guard against erroneously setting the trend item data
 * status to cancelled (see sqTrendActions.catchItemDataFailure() for more details).
 * @return {Promise} Resolves when all the requests have been cancelled
 */
export function cancelGroup(group, refetching = false) {
  return _.chain(getCancelers())
    .pickBy((request) => _.includes(request.groups, group))
    .keys()
    .map((requestId) => cancel(requestId, false, refetching))
    .thru((requests) => Promise.all(requests))
    .value();
}

/**
 * Cancels all server requests from the current user
 *
 * @return {Promise} a promise that gets fulfilled when request completes
 */
export function cancelCurrentUserServerRequests() {
  cancelAll(true);
  return sqRequestsApi.cancelMyRequests();
}

/**
 * Cancels all server requests
 * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
 * @return {Promise} a promise that gets fulfilled when request completes
 */
export function cancelAllServerRequests(refetching = false) {
  cancelAll(true, refetching);
  return sqRequestsApi.cancelRequests({});
}

/**
 * Updates the progress and if requested the timing and meter information of the request in the items stores. Also
 * resets the canceler expiresAt property to be the current time plus a grace period.
 *
 * @param  {String} id - Unique request identifier
 * @param  {Object} info - Object containing properties to update
 * @param  {Number|undefined} [info.progress] - the percent progress of the request
 * @param  {String} [info.timingInformation] - String providing information displayed in the "Time" section of the
 *   Request Details Panel.
 * @param  {String} [info.meterInformation] - String providing information displayed in the "Samples Read" section
 *   of the Request Details Panel.
 */
export function updateProgress(id, info) {
  const canceler = getCanceler(id);
  if (canceler) {
    _.chain(canceler.groups)
      .flatMap((group) => _.split(group, CANCELLATION_GROUP_GUID_SEPARATOR))
      .forEach((group) => {
        flux.dispatch('TREND_SET_PROGRESS', _.assign({}, { id: group }, info), PUSH_IGNORE);
      })
      .value();

    // If we've received progress for an async pending request then update the missing response expiration time
    if (isOutstandingRequest(id)) {
      canceler.expiresAt = moment.utc().add(SOCKET_LIVELINESS_TIMEOUT);
    }
  }
}

/**
 * Determines if an async request that is known to this service but is missing from the received progress message is
 * actually still in progress or not. If it is not still in progress, then it needs to be cancelled or it will remain
 * forever in the current state (e.g. loading). We wait for a grace period to expire before making an http call to
 * the backend to determine if the request is still in progress. This is required because backend garbage collection
 * or other slowdown may cause the server to stall for a short interval and then we can receive multiple progress
 * messages that don't yet contain request IDs for requests that have been made but not yet processed by the backend.
 *
 * @param {String} requestId - ID of the request that is being evaluated.
 */
export function evaluateMissingAsyncResponse(requestId: string) {
  const canceler = getCanceler(requestId);

  // Only continue processing if the request is known and asynchronous
  if (!canceler || !isOutstandingRequest(requestId)) {
    return;
  }

  // Set the missing response expiration time if it does not already exist. This expiration time is also updated in
  // .updateProgress() whenever we receive a valid progress update for the asynchronous request.
  if (_.isUndefined(canceler.expiresAt)) {
    canceler.expiresAt = moment.utc().add(SOCKET_LIVELINESS_TIMEOUT);
  }

  // If the grace period has expired then query the backend to see if the request is still in progress
  if (moment.utc() > canceler.expiresAt) {
    // Clear response expiration time so we won't check with the backend again until after another grace period
    delete canceler.expiresAt;

    // Make a call to the backend to see if the request has been cancelled
    return sqRequestsApi.getRequest({ requestId }).catch(() => {
      cancel(requestId, true);
      logError(`Cancelled missing request: ${requestId}`);
    });
  }
}

export function subscribeToPendingRequests(sessionId, subscribeFn) {
  subscribe(sessionId, subscribeFn);
}

/**
 * Subscribes to request messages for this session. Also unsubscribes from the subscription to the previous
 * interactive session, if present.
 *
 * @param {String} sessionId - Interactive session ID that identifies this client connection.
 */
export function subscribe(sessionId, subscribeFn) {
  unsubscribe();
  unsubscribe = subscribeFn({
    channelId: [SeeqNames.Channels.RequestsProgress, sessionId],
    onMessage: onProgressMessage,
    useSubscriptionsApi: false, // Channel is auto-subscribed by the backend
  });
}

/**
 * Internal handler for request messages. Updates the progress of associated requests or evaluates if an
 * outstanding pending async request is missing.
 *
 * @param {Object} message - Data received from websocket
 */
export function onProgressMessage(message) {
  const outstandingRequests = getAllRequestIds();
  _.forEach(outstandingRequests, (requestId) => {
    const requestUpdate = _.find(message.requests, { requestId }) as any;
    if (requestUpdate) {
      const payload = _.pick(requestUpdate, ['timingInformation', 'meterInformation']) as any;
      if (requestUpdate.totalCount) {
        payload.progress = ((requestUpdate.completedCount / requestUpdate.totalCount) * 100).toFixed(0);
      }
      updateProgress(requestId, payload);
    } else {
      evaluateMissingAsyncResponse(requestId);
    }
  });
}

/**
 * Keep track of promises that could corrupt the state if we switch worksheets before the promises resolve.
 * This method adds the given promise to volatilePromises, which can be accessed elsewhere (i.e. in app.module).
 * When a promise finishes, that promise is removed from volatilePromises.
 *
 * @param promise - the promise to keep track of
 */
export function registerVolatilePromise(promise: Promise<any>) {
  volatilePromises.push(promise);
  promise.finally(() => {
    volatilePromises = _.pull(volatilePromises, promise);
  });
}

export function hasVolatilePromises() {
  return volatilePromises.length > 0;
}

/**
 * Gets the total number of active cancellable requests.
 *
 * @param {String} [group] - if provided only count requests within that group
 * @returns {Number} - the total number of active cancellable requests
 */
export function count(group?) {
  return _.isNil(group)
    ? _.keys(getCancelers()).length
    : _.chain(getCancelers())
        .pickBy((request) => _.includes(request.groups, group))
        .keys()
        .thru((requestIds) => requestIds.length)
        .value();
}

export function clearCancelers() {
  cancelers = {};
}

/**
 * Helper function that cancels all the requests for a user's specific session
 *
 * @param {Object} sqWorkbenchStore - the workbench store
 */
export function cancelRequestsOnUnload() {
  const headers = new Headers(
    addCsrfHeader({
      Accept: 'application/vnd.seeq.v1+json',
    }),
  );

  const sessionId = sqWorkbenchStore.interactiveSessionId;
  // Fetch is being used here, with the keepalive flag set to true, since several browsers no longer support XHR
  // during the onbeforeunload event
  return fetch(`${APPSERVER_API_PREFIX}/requests/me/${sessionId}`, {
    method: 'DELETE',
    keepalive: true,
    headers,
  });
}
