import { PersistenceLevel, Store } from '@/core/flux.service';
import {
  FixedListSearchV1,
  ItemFinderInputV1,
  PropertyFilterInputV1,
  PropertyInputV1,
  PropertySearchV1,
  SwapAcrossAssetsSearchV1,
} from '@/sdk';
import _ from 'lodash';
import { base64guid } from '@/utilities/utilities';

const DEFAULT_ASSET_SWAP_SEARCH: SwapAcrossAssetsSearchV1 = {
  isInclude: true,
  rootAssetId: '',
  swapItemId: '',
  searchType: '' as any,
};

const DEFAULT_FIXED_LIST_SEARCH: FixedListSearchV1 = {
  isInclude: true,
  itemIds: [],
  searchType: '' as any,
};

const DEFAULT_PROPERTY_SEARCH: PropertySearchV1 = {
  isInclude: true,
  ancestorId: '',
  folderId: '',
  predicates: [],
  types: [],
  searchType: '' as any,
};

const DEFAULT_ITEM_FINDER: ItemFinderInputV1 = {
  searches: [DEFAULT_ASSET_SWAP_SEARCH],
  fixedListSearches: [DEFAULT_FIXED_LIST_SEARCH],
  propertySearches: [DEFAULT_PROPERTY_SEARCH],
  name: '',
};

const DEFAULT_PREDICATE: PropertyFilterInputV1 = {
  operator: '' as any,
  propertyName: '',
  values: [],
};

const DEFAULT_VALUE: PropertyInputV1 = {
  value: '',
  unitOfMeasure: '' || undefined,
};

export const INITIAL_SEARCHES: any[] = [
  {
    ..._.cloneDeep(DEFAULT_ASSET_SWAP_SEARCH),
    searchId: base64guid(),
  },
];
export const INITIAL_FIXED_LIST_SEARCHES: FixedListSearchV1[] = [_.cloneDeep(DEFAULT_FIXED_LIST_SEARCH)];
export const INITIAL_PROPERTY_SEARCHES: PropertySearchV1[] = [_.cloneDeep(DEFAULT_PROPERTY_SEARCH)];

export class ItemFinderStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqItemFinderStore';

  initialize() {
    this.state = this.immutable({
      id: '',
      name: DEFAULT_ITEM_FINDER.name,
      searches: INITIAL_SEARCHES,
      fixedListSearches: INITIAL_FIXED_LIST_SEARCHES,
      propertySearches: INITIAL_PROPERTY_SEARCHES,
      predicatesList: [],
      valuesList: [],
    });
  }

  get id(): string {
    return this.state.get('id');
  }

  get name(): string {
    return this.state.get('name');
  }

  get searches(): [] {
    return this.state.get('searches');
  }

  get predicatesList(): PropertyFilterInputV1[] {
    return this.state.get('predicatesList');
  }

  get valuesList(): any[] {
    return this.state.get('valuesList');
  }

  protected readonly handlers = {
    RESET_ITEM_FINDER: () => {
      this.initialize();
    },
    SET_EXISTING_ITEM_FINDER: ({
      value,
    }: {
      value: {
        name: string;
        searches: [];
      };
    }) => {
      this.state.set('name', value.name);
      this.state.set('searches', value.searches);
    },
    ITEM_FINDER_SET_NAME: ({ name }: { name: string }) => {
      this.state.set('name', name);
    },
    ITEM_FINDER_ADD_SEARCH: () => {
      const searchId = base64guid();
      const newSearch = { ...DEFAULT_ASSET_SWAP_SEARCH, searchId };
      this.state.push('searches', newSearch);
    },
    ITEM_FINDER_SET_PREDICATES_AND_VALUES: ({ searches }: { searches: any }) => {
      const allPredicates = searches.flatMap((search: any, index: number) =>
        (search.predicates ?? []).map((predicate: PropertyFilterInputV1) => ({
          ...predicate,
          predicateId: base64guid(),
          searchId: index,
        })),
      );
      const allValues = allPredicates.flatMap((predicate: any) =>
        (predicate.values ?? []).map((value: PropertyInputV1) => ({
          ...value,
          predicateId: predicate.predicateId,
          searchId: predicate.searchId,
        })),
      );
      this.state.set('predicatesList', allPredicates);
      this.state.set('valuesList', allValues);
    },
    ITEM_FINDER_ADD_PREDICATE: (searchId: string) => {
      const predicateId = base64guid();
      const newPredicate = { ...DEFAULT_PREDICATE, searchId, predicateId };
      const newValue = { ...DEFAULT_VALUE, predicateId, searchId };
      this.state.push('predicatesList', newPredicate);
      this.state.push('valuesList', newValue);
    },
    ITEM_FINDER_ADD_VALUE: ({ predicateId, searchId }: { predicateId: string; searchId: string }) => {
      const newValue = { ...DEFAULT_VALUE, predicateId, searchId };
      this.state.push('valuesList', newValue);
    },
    ITEM_FINDER_REMOVE_SEARCH: (index: number) => {
      if (index > -1) {
        this.state.splice('searches', [index, 1]);
      }
    },
    ITEM_FINDER_REMOVE_PREDICATE: (predicateIndex: number) => {
      this.state.splice('predicatesList', [predicateIndex, 1]);
    },
    ITEM_FINDER_REMOVE_VALUE: (valueIndex: number) => {
      this.state.splice('valuesList', [valueIndex, 1]);
    },
    ITEM_FINDER_UPDATE_SEARCH: ({
      value,
      index,
    }: {
      value: {
        searchType: string;
        itemIds: [];
        isInclude: boolean;
        swapItemId: any;
        rootAssetId: any;
        ancestorId: any;
        folderId: any;
        predicates: [];
        types: [];
        searchId: string;
      };
      index: number;
    }) => {
      this.state.splice('searches', [index, 1, { ...this.state.get('searches', index), ...value }]);
    },
    ITEM_FINDER_UPDATE_PREDICATE: ({
      value,
      index,
    }: {
      value: {
        operator: string;
        propertyName: string;
        values: [];
      };
      index: number;
    }) => {
      this.state.splice('predicatesList', [index, 1, { ...this.state.get('predicatesList', index), ...value }]);
    },
    ITEM_FINDER_UPDATE_VALUE: ({
      value,
      index,
    }: {
      value: {
        value: string;
        unitOfMeasure: string | null;
      };
      index: number;
    }) => {
      this.state.splice('valuesList', [index, 1, { ...this.state.get('valuesList', index), ...value }]);
    },
  };
}
