// @ts-strict-ignore
import _ from 'lodash';
import { findItemIn, getAllItems, getTrendStores } from '@/trend/trendDataHelper.utilities';
import { viewsWorkSheetView } from '@/worksheets/worksheetView.utilities';
import { cancelAll } from '@/requests/pendingRequests.utilities';
import { evaluate } from '@/utilities/autoGroup.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_WORKSTEP_IMMEDIATE } from '@/core/flux.service';
import {
  ASSET_GROUP_VIEW,
  TABLE_DEFINITION_VIEW,
  WORKSHEET_VIEW,
  WORKSHEET_VIEWS,
} from '@/worksheet/worksheet.constants';
import { sqTrendSeriesStore, sqWorksheetStore } from '@/core/core.stores';
import {
  fetchAllItems,
  fetchTableAndChartCapsules,
  removeTrendSelectedRegion,
  setItemSelected,
  setItemStatusNotRequired,
  updateCapsuleGrouping,
} from '@/trendData/trend.actions';
import { EditorPositionAndOffset } from '@/worksheet/worksheet.store';

/**
 * Changes the active tab of a specified tabset
 *
 * @param {String} tabset - The unique name of the tabset
 * @param {String} activeTab - The name of the active tab
 */
export function tabsetChangeTab(tabset: string, activeTab: string) {
  flux.dispatch('TABSET_CHANGE_TAB', {
    activeTab,
    tabset,
  });
}

/**
 * Sets the view that is displayed on the worksheet Also takes care of handling views that need to maintain separate
 * state for what items are selected in the details pane. It does this by saving off the currently selected items,
 * unselecting them all, then selecting all items that were selected in the previous view.
 *
 * @param key -The unique key of the data view to be displayed (e.g. WORKSHEET_VIEW.TREND,
 *   WORKSHEET_VIEW.TREEMAP, etc.)
 * @param fetchAll - If true all item data will be fetched
 */
export function setView(key: string, fetchAll = true) {
  setItemStatusNotRequired();
  cancelAll(false, true);
  const currentView = sqWorksheetStore.view;
  const currentSelectedItems = _.filter(getAllItems({}), 'selected');
  flux.dispatch('WORKSHEET_CHANGE_VIEW', { key });
  const newView = _.find(viewsWorkSheetView(), { key });
  const tableSelectedRealmKey = _.find(WORKSHEET_VIEWS, {
    key: WORKSHEET_VIEW.TABLE,
  }).selectedItemsRealm;

  if (currentView.selectedItemsRealm !== newView.selectedItemsRealm) {
    const newSelectedItems = _.chain(sqWorksheetStore.selectedIdsForView(newView.selectedItemsRealm))
      .map((id) => findItemIn(getTrendStores(), id))
      .compact()
      .value();
    flux.dispatch('WORKSHEET_SET_SELECTED_IDS', {
      realm: currentView.selectedItemsRealm,
      ids: _.map(currentSelectedItems, 'id'),
    });
    flux.dispatch('WORKSHEET_SET_SELECTED_IDS', {
      realm: newView.selectedItemsRealm,
      ids: [],
    });
    _.forEach(currentSelectedItems, (item) => {
      setItemSelected(item, false, false, currentView.selectedItemsRealm === tableSelectedRealmKey);
    });
    _.forEach(newSelectedItems, (item) => {
      setItemSelected(item, true, false, newView.selectedItemsRealm === tableSelectedRealmKey);
    });
  }

  removeTrendSelectedRegion();
  if (fetchAll) {
    fetchAllItems({ skipProps: true });
  }
}

export function setReturnViewKey(key: string) {
  if (key !== ASSET_GROUP_VIEW.key && key !== TABLE_DEFINITION_VIEW.key) {
    flux.dispatch('WORKSHEET_RETURN_VIEW', { key });
  }
}

/**
 * Sets the visibility of the browse panel
 *
 * @param {Boolean} isCollapsed - True if the panel is collapsed, false otherwise
 */
export function setBrowsePanelCollapsed(isCollapsed: boolean) {
  flux.dispatch('SET_BROWSE_PANEL_COLLAPSED', { isCollapsed }, PUSH_WORKSTEP_IMMEDIATE);
}

/**
 * Toggles the browse panel visibility
 */
export function toggleBrowsePanel() {
  setBrowsePanelCollapsed(!sqWorksheetStore.browsePanelCollapsed);
}

/**
 * Sets the enabled flag that determines if the formula form is resizeable or not.
 *
 * @param {Boolean} resizeEnabled - a flag enabling or disabling resize
 */
export function setDisplayResizeEnabled(resizeEnabled: boolean) {
  flux.dispatch('WORKSHEET_FORMULA_DISPLAY_RESIZE_ENABLED', {
    resizeEnabled,
  });
}

/**
 * Sets the displayWidth of the formula form as it is sized to by the user.
 *
 * @param {Number} width - Pixel width to use for the formula panel
 */
export function setDisplayWidth(width: number) {
  flux.dispatch('WORKSHEET_FORMULA_DISPLAY_WIDTH', { displayWidth: width });
}

/**
 * Sets the displayHeight of the formula form as it is sized to by the user.
 *
 * @param {Number} height - Pixel height to use for the formula panel
 */
export function setDisplayHeight(height: number) {
  flux.dispatch('WORKSHEET_FORMULA_DISPLAY_HEIGHT', {
    displayHeight: height,
  });
}

/**
 * Sets the time zone for this worksheet, or 'undefined' if this worksheet should not have have a fixed time zone.
 * When undefined, the current user's default timezone is used.
 *
 * @param [timezone] - Time zone to use for this worksheet. If undefined, no fixed time zone will be
 *   assigned to this worksheet.
 * @param [timezone.name] - Name of the time zone, to persist with the workstep
 */
export function setTimezone(timezone?: { name: string }) {
  flux.dispatch('WORKSHEET_SET_TIME_ZONE', timezone);
  fetchTableAndChartCapsules();
}

/**
 * Adds the grouping entry of a signal to a condition. Also fetches all capsule series segments to update the
 * display according to the new grouping.
 *
 * @param {string} signalId - id of a signal
 * @param {string} conditionId - id of a condition
 */
export function groupSignalToCondition(signalId: string, conditionId: string) {
  flux.dispatch('GROUP_SIGNAL_TO_CONDITION', { signalId, conditionId });
  updateCapsuleGrouping();
}

/**
 * Toggles the capsule group mode on and off.Also fetches all capsule series segments to update the
 * display according to the new grouping.
 */
export function toggleCapsuleGroupMode() {
  flux.dispatch('TOGGLE_CAPSULE_GROUP_MODE');

  // Deselect all series before switching to Group Mode, as users can't change their selection in group mode
  _.forEach(sqTrendSeriesStore.nonCapsuleSeriesAndPreview, (series) => setItemSelected(series, false));

  evaluate();
  updateCapsuleGrouping();
}

/**
 * Removes all signal mappings for the provided capsule set
 * @param {object} capsuleSet - object representing a capsule set
 * @param {string} capsuleSet.id - id of the capsule set
 */
export function removeConditionToSeriesGrouping(capsuleSet: { id: string }) {
  flux.dispatch('REMOVE_SIGNAL_TO_CONDITION_GROUP', {
    conditionId: capsuleSet.id,
  });
}

/**
 * Removes the signal identified by the id from all signal to condition groupings.
 *
 * @param {String} id - if of the signal
 */
export function removeSeriesFromGrouping(id: string) {
  flux.dispatch('REMOVE_SIGNAL_FROM_GROUPINGS', { id });
}

/**
 * Sets the columns a plugin wants to be shown
 *
 * @param {String} identifier - the plugin identifier
 * @param {String} trendPanel - one of TREND_PANELS
 * @param {String[]} keys - an array of column keys that should be shown
 */
export function pluginShowColumn(identifier: string, trendPanel: string, keys: string[]) {
  flux.dispatch('SET_PLUGIN_SHOWN_COLUMNS', { identifier, trendPanel, keys });
}

export function setShowAssetSelectionWarnings(showAssetSelectionWarnings: boolean) {
  flux.dispatch('SET_SHOW_ASSET_SELECTION_WARNINGS', {
    showAssetSelectionWarnings,
  });
}

export function setJournalPosition(position: EditorPositionAndOffset) {
  flux.dispatch('SET_JOURNAL_POSITION', {
    position,
  });
}
