import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export interface FormulaKeywordsProps {
  keywords: string[];
}

export const FormulaKeywords: React.FunctionComponent<FormulaKeywordsProps> = ({ keywords }) => {
  const { t } = useTranslation();

  return (
    <>
      {keywords?.length === 1 && <h2>{t('FORMULA.DOCUMENTATION.SEARCH_KEYWORD')}</h2>}
      {keywords?.length >= 1 && <h2>{t('FORMULA.DOCUMENTATION.SEARCH_KEYWORDS')}</h2>}
      <div>{_.join(keywords, ', ')}</div>
    </>
  );
};
