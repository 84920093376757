// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FakeLink } from '@/core/FakeLink';

export interface FormulaSeeAlsoProps {
  seeAlsos: { documentationHref: string; title: string }[];
  requestDocumentation: (href: string) => any;
}

export const FormulaSeeAlsos: React.FunctionComponent<FormulaSeeAlsoProps> = ({ seeAlsos, requestDocumentation }) => {
  const { t } = useTranslation();

  const isLast = (index) => _.toNumber(index) === seeAlsos.length - 1;

  return (
    seeAlsos?.length > 0 && (
      <>
        <h2>{t('FORMULA.DOCUMENTATION.SEE_ALSO')}</h2>
        <ul className="pl20">
          {_.map(seeAlsos, (seeAlso, index) => (
            <li key={index} className={isLast(index) ? 'mb10' : 'mb5'}>
              <FakeLink onClick={() => requestDocumentation(seeAlso.documentationHref)}>{seeAlso.title}</FakeLink>
            </li>
          ))}
        </ul>
      </>
    )
  );
};
