import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWithPopover } from '@/core/ButtonWithPopover.molecule';
import _ from 'lodash';
import { doTrack } from '@/track/track.service';
import { Icon } from '@seeqdev/qomponents';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqAiAssistantStore } from '@/core/core.stores';

interface StatusUpdateProps {
  stages?: Record<string, string | string[] | object>;
  isRunning?: boolean;
  isLast?: boolean;
  isResponding?: boolean;
}

const getTranslationKey = (stage: string) => {
  return `AI_ASSISTANT.STREAMING_STAGES.${stage.replace('stage_', '').toUpperCase()}`;
};

const toNicerCase = (input: string) =>
  _.chain(input)
    .split('_')
    .map((word) => (_.size(word) > 2 ? _.startCase(_.toLower(word)) : word))
    .join(' ')
    .value();

const toFriendlyTitle = (input: string) => {
  return toNicerCase(
    _.chain(input)
      .trim('/')
      .split('/')
      .last()
      .replace(/^[\d-]*/, '')
      .replace(/\.html$/, '')
      .replace(/-|%20/g, ' ')
      .replace(/\?/g, '')
      .value(),
  );
};

const wrapInDiv = (content: string) => <div className="flexRowContainer ml10 mr10 text-pre-wrap">{content}</div>;

const renderFullStageMessage = (stageValue: string | string[] | object) => {
  if (stageValue === null || typeof stageValue === 'undefined') {
    return wrapInDiv('');
  }
  if (typeof stageValue === 'string') {
    return wrapInDiv(stageValue);
  }
  if (Array.isArray(stageValue)) {
    return wrapInDiv(_.map(_.uniq(stageValue), (value) => toNicerCase(value)).join('\n'));
  }
  if (typeof stageValue === 'object') {
    const keys = Object.keys(stageValue);
    return (
      <div className="flexRowContainer ml10 mr10">
        {keys.map((key) => (
          <div className="flexRowContainer mb8" key={key}>
            <div className="optionSubheader">{toNicerCase(key)}</div>
            <div className="ml10 flexRowContainer">
              {_.map((stageValue as { [key: string]: any })[key], (value) => {
                if (typeof value === 'object' && 'link' in value) {
                  return (
                    <ul key={value['link']} className="mb8 pl10">
                      <li>
                        <a href={value['link']} target="_blank" rel="noopener">
                          {value['description']}
                        </a>
                      </li>
                    </ul>
                  );
                } else if (_.startsWith(value, 'http')) {
                  return (
                    <ul key={value} className="mb8 pl10">
                      <li>
                        <a href={value} target="_blank" rel="noopener">
                          {toFriendlyTitle(value)}
                        </a>
                      </li>
                    </ul>
                  );
                } else {
                  return (
                    <ul key={value} className="mb8 pl10">
                      <li>{toNicerCase(value)}</li>
                    </ul>
                  );
                }
              })}
            </div>
          </div>
        ))}
      </div>
    );
  }
  return JSON.stringify(stageValue);
};

export const StatusUpdate: React.FunctionComponent<StatusUpdateProps> = ({
  stages,
  isRunning = false,
  isLast = false,
  isResponding = false,
}) => {
  const { t } = useTranslation();
  const stageKeys = Object.keys(stages || {});
  const lastStageKey = _.last(stageKeys) || '';

  const [stageQueue, setStageQueue] = React.useState<string[]>([]);
  const queueLastEntryTime = useRef(0);
  const aiAssistantFontSize = useFluxPath(sqAiAssistantStore, () => sqAiAssistantStore.fontSize);

  useEffect(() => {
    if (!lastStageKey) return;

    // Add element to queue
    const now = Date.now();
    const lastEntryTime = queueLastEntryTime.current;
    setStageQueue((stageQueue) => [...stageQueue, lastStageKey]);
    queueLastEntryTime.current = now;

    // Remove the first element from the queue after it is shown for 1 second
    // Notice we calculate the time to wait based on the last entry time, so that we can chain the timeouts
    const delay = setTimeout(() => {
      setStageQueue((stageQueue) => stageQueue.slice(1));
    }, Math.max(lastEntryTime - now + 2 * 1000, 1000));

    // Clear the timeout on unmount
    return () => clearTimeout(delay);
  }, [lastStageKey]);

  return (
    <ButtonWithPopover
      label={
        <div
          className="flexColumnContainer flexFill flexAlignCenter stageBtn"
          onClick={() => doTrack('AIAssistant', 'expand stages dropdown')}>
          {isLast && isRunning && stageQueue.length > 0 && (
            <div className="ai-text">{t(getTranslationKey(stageQueue[0]))}</div>
          )}
          <Icon icon="fa-info-circle ai-button" extraClassNames={`ml5 stageBtn`} type="text" />
        </div>
      }
      popoverConfig={{
        id: 'stagesPopover',
        placement: 'bottom',
        wrapperClassNames: `min-width-400 ai-size-${aiAssistantFontSize}`,
      }}
      closeOnClick={false}>
      <div className="flexRowContainer">
        {_.isEmpty(stageKeys) && (
          <div className="flexRowContainer flexAlignCenter m20 ai-text">
            {t('AI_ASSISTANT.STREAMING_STAGES.NO_REFERENCES')}
          </div>
        )}
        {stages &&
          _.map(stageKeys, (stageKey) => {
            return (
              <div className="flexRowContainer mb8 ai-text" key={stageKey}>
                <div className="optionHeader ml10"> {t(`${getTranslationKey(stageKey)}_DONE`)}</div>
                <div className="ml5">{renderFullStageMessage(stages[stageKey])}</div>
              </div>
            );
          })}
      </div>
    </ButtonWithPopover>
  );
};
