import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkCold, coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Icon } from '@seeqdev/qomponents';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqInvestigateStore, sqWorkbenchStore } from '@/core/core.stores';
import { Link } from 'react-router-dom';
import utilities from '@/core/utilities';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { successToast } from '@/utilities/toast.utilities';
import { useTranslation } from 'react-i18next';

interface MarkdownSnippetProps {
  id: string;
  markdown: string;
  insertFormulaSnippet: (snippet: string) => void;
}

const MD_ICON_REGEX = /:(fa|fc)-([\w-]+):/;

export const MarkdownSnippet: React.FunctionComponent<MarkdownSnippetProps> = ({
  id,
  markdown,
  insertFormulaSnippet,
}) => {
  const { t } = useTranslation();
  const darkMode = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.darkMode);

  const coldarkStyle = darkMode ? coldarkDark['pre[class*="language-"]'] : coldarkCold['pre[class*="language-"]'];
  coldarkStyle['margin'] = 0;
  coldarkStyle['borderBottomRightRadius'] = '6px';
  coldarkStyle['borderBottomLeftRadius'] = '6px';

  return (
    <div id={id} className="ai-text">
      <ReactMarkdown
        components={{
          p: ({ children }) => {
            return (
              <>
                {React.Children.map(children, (child) => {
                  if (typeof child === 'string') {
                    const parts = child.split(MD_ICON_REGEX);

                    return parts
                      .map((part, index) => {
                        if (index % 3 === 1) {
                          const className = `${part} ${part}-${parts[index + 1]}`;
                          return <i key={index} className={className}></i>;
                        }
                        if (index % 3 === 2) return null;

                        return part;
                      })
                      .filter(Boolean);
                  }
                  return child;
                })}
              </>
            );
          },
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            let displayLanguage = match ? match[1] : '';
            let parsingLanguage = match ? match[1] : 'text';
            if (match && match[1] === 'seeq') {
              displayLanguage = 'seeq formula';
              parsingLanguage = 'php';
            }

            return !inline && match ? (
              <>
                <div className="flexColumnContainer codeHeader p8">
                  <span className="flexFill">{displayLanguage}</span>
                  <span
                    onMouseDown={(e) => {
                      if (sqInvestigateStore.activeTool === TREND_TOOLS.FORMULA) {
                        insertFormulaSnippet(String(children));
                      } else {
                        utilities.copyTextToClipboard(String(children));
                        successToast({ messageKey: 'AI_ASSISTANT.COPY_SUCCESS' });
                      }
                    }}>
                    <Icon
                      icon="fc-copy"
                      tooltip={t('COPY')}
                      extraClassNames="cursorPointer pt1 ai-button"
                      testId="exampleCopyBtn"
                    />
                  </span>
                </div>
                <div className="flexColumnContainer mb15">
                  <SyntaxHighlighter
                    {...props}
                    style={darkMode ? coldarkDark : coldarkCold}
                    PreTag="div"
                    wrapLongLines={true}
                    language={parsingLanguage}
                    customStyle={{ minWidth: '100%' }}>
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                </div>
              </>
            ) : (
              <code {...props} className={className}>
                {children}
              </code>
            );
          },
          a: (props) => {
            const origin = window.origin;
            if (props.href && props.href.startsWith(window.origin)) {
              const url = props.href.substring(origin.length);
              return <Link to={url}>{props.children}</Link>;
            } else {
              return (
                <a href={props.href} target="_blank" rel="noopener">
                  {props.children}
                </a>
              ); // All other links
            }
          },
        }}
        remarkPlugins={[remarkGfm]}>
        {markdown}
      </ReactMarkdown>
    </div>
  );
};
