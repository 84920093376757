// @ts-strict-ignore
import React from 'react';
import { Ace } from '@/accessControl/itemAclModal.utilities';
import { IdentityPopover } from '@/accessControl/IdentityPopover.molecule';

interface AcePopoverProps {
  ace: Ace;
}

export const AcePopover: React.FunctionComponent<AcePopoverProps> = ({ ace }) => {
  return <IdentityPopover identity={ace.identity} />;
};
