// @ts-strict-ignore
import _ from 'lodash';
import { AGGREGATION_MODES, CAPSULE_MODES, Y_VALUE_BIN_MODES } from '@/tools/histogram/histogram.constants';
import { toNumber } from '@/utilities/numberHelper.utilities';
import { validateBinConfig } from '@/utilities/investigateHelper.utilities';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { StoredStatistic } from '@/tools/StatisticSelector.molecule';

const DEFAULT_AGGREGATION_CONFIG = {
  id: 1,
  mode: AGGREGATION_MODES.Y_VALUE,
  capsuleMode: CAPSULE_MODES[0].key,
  yValueBinMode: Y_VALUE_BIN_MODES.SIZE,
  valid: false,
};

export interface Item {
  id: string;
  name: string;
  dataStatus?: string;
  lastFetchRequest?: string;
}

interface AggregationConfigs {
  mode: string;
  capsuleMode?: string;
  conditionProperty: string;
  selectedProperty: string;
  item?: Item;
  id: number;
}

interface Config {
  mode: string;
  yValueBinMode: string;
  item: Item[];
  conditionProperty: string;
  timeBucket: { key: string; display: string; funct: string };
  statistic: string;
  timeUnits: string;
  stat: StoredStatistic;
  aggregationConfigs: Partial<AggregationConfigs>[];
}

export class AggregationBinStore extends BaseToolStore {
  type = TREND_TOOLS.AGGREGATION_BINS_TABLE;
  static readonly storeName = 'sqAggregationBinStore';
  parameterDefinitions = {
    signalToAggregate: { predicate: ['name', 'signalToAggregate'] },
  };

  initialize() {
    this.state = this.immutable(
      _.assign({}, this.parameterDefinitions, {
        mode: AGGREGATION_MODES.Y_VALUE,
        includeEmptyBuckets: true,
        yValueSignal: '',
        stat: { key: null, timeUnits: 's' },
        aggregationConfigs: [DEFAULT_AGGREGATION_CONFIG],
      }),
    );
  }

  get signalToAggregate() {
    return this.state.get('signalToAggregate');
  }

  get aggregationConfigs() {
    return this.state.get('aggregationConfigs');
  }

  get stat(): StoredStatistic {
    return this.state.get('stat');
  }

  get includeEmptyBuckets(): boolean {
    return this.state.get('includeEmptyBuckets');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the aggregation panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  /**
   * Validates the provided aggregation config. Based on the mode property different attributes must be provided for a
   * config to be valid.
   *
   * @param {Object} config - Object defining the config.
   * @returns {Boolean} true if the config is considered valid, false otherwise.
   */
  validateAggregationConfig(config: Config) {
    if (config.mode === AGGREGATION_MODES.Y_VALUE) {
      return validateBinConfig(config) === '' && !_.isUndefined(config.yValueBinMode) && !_.isEmpty(config.item);
    } else if (config.mode === AGGREGATION_MODES.CONDITION) {
      return !_.isEmpty(config.conditionProperty) && !_.isEmpty(config.item);
    } else {
      return !_.isEmpty(config.timeBucket);
    }
  }

  /**
   * Maps the parameters to the appropriate aggregation config. Also updates from old statistic key to the new ones
   * defined by SAMPLE_FROM_SCALARS.VALUE_METHODS. NOTE: percentGood used to be supported, but it no longer
   * supported so it will fall through and show an empty statistics selector when edited.
   *
   * Also defaults the capsuleMode for condition-based mode to overlaps.
   *
   * @param  {Object} config - The configuration from UIConfig
   * @param  {Object[]} parameters - The parameters of the formula
   * @return {Object} The modified config
   */
  migrateSavedConfig(config: Config, parameters) {
    if (!_.some(SAMPLE_FROM_SCALARS.VALUE_METHODS, ['key', config.statistic])) {
      config.statistic = config.statistic === 'StdDev' ? 'standardDeviation' : _.toLower(config.statistic);
    }

    _.forEach(config.aggregationConfigs, (aggregationConfig, i) => {
      if (
        config.aggregationConfigs[i].mode === AGGREGATION_MODES.CONDITION ||
        config.aggregationConfigs[i].mode === AGGREGATION_MODES.TIME
      ) {
        if (_.isUndefined(config.aggregationConfigs[i].capsuleMode)) {
          config.aggregationConfigs[i].capsuleMode = DEFAULT_AGGREGATION_CONFIG.capsuleMode;
        }
      }

      if (_.has(aggregationConfig, 'selectedProperty')) {
        config.aggregationConfigs[i].conditionProperty = aggregationConfig.selectedProperty;
        delete config.aggregationConfigs[i].selectedProperty;
      }

      _.chain(parameters)
        .find((parameter) => toNumber(parameter.name.replace(/.*?(\d+)$/, '$1')) === aggregationConfig.id)
        .get('item')
        .pick(this.TOOL_ITEM_PROPS)
        .thru((item) => {
          if (!_.isEmpty(item)) {
            config.aggregationConfigs[i].item = item as Item;
          }
        })
        .value();
    });
    if (_.isUndefined(config.stat)) {
      config.stat = {
        key: config.statistic,
        timeUnits: config.timeUnits,
      };
      delete config.statistic;
      delete config.timeUnits;
    }
    return config;
  }

  /**
   * Removes the item from aggregationConfigs since the source of truth is the formula parameters.
   *
   * @param {Object} config - The state that will be saved to UIConfig
   * @return {Object} The modified config
   */
  modifyConfigParams(config: Config) {
    config.aggregationConfigs = _.map(config.aggregationConfigs, (c) => _.omit(c, ['item']));
    return config;
  }

  protected readonly handlers = {
    ...this.baseHandlers,

    /**
     * Sets the statistic that is used to create the aggregation.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.stat - the statistic from the selector
     */
    AGGREGATION_SET_STAT: (payload: { stat: { key: string; timeUnits: string } }) => {
      this.state.set('stat', payload.stat);
    },

    /**
     * This function finds the aggregationConfig entry that is being modified and updates the parameters specified.
     * If no entry for the provided id is found a new one is added.
     * To enable form-validation it also validates the entry and sets the "valid" flag to true if the definition is
     * valid.
     *
     * @param {Object} payload -  Object container
     * @param {Number} payload.id -  id defining the aggregationConfig being modified.
     * @param {String} [payload.mode] -  one of AGGREGATION_MODES. Applies to all aggregationConfig entries.
     * @param {Number} [payload.yValueBinMin] -  minimum y-value to be used for creating bins by y-value. Only
     *   applies to AGGREGATION_MODES.Y_VALUE
     * @param {Number} [payload.yValueBinMax] -  maximum y-value to be used for creating bins by y-value. Only
     *   applies to AGGREGATION_MODES.Y_VALUE
     * @param {Number} [payload.binSize] - size of the bin. Only applies to AGGREGATION_MODES.Y_VALUE
     * @param {Number} [payload.numberOfBins] - number of bins. Only applies to AGGREGATION_MODES.Y_VALUE
     * @param {String} [payload.yValueBinMode] - whether to create bins based on size or a certain number. Only
     *   applies to AGGREGATION_MODES.Y_VALUE
     * @param {String} [payload.conditionProperty] - the condition property. Only applies to AGGREGATION_MODES.CONDITION
     * @param {Number} [payload.capsuleMode] - Boundary selection of capsules. Only applies to
     *   AGGREGATION_MODES.CONDITION or AGGREGATION_MODES.TIME
     * @param {Object} [payload.item] - the item used as part of the aggregation. Does not apply to
     *   AGGREGATION_MODES.TIME.
     * @param {Object} [payload.timeBucket] - the time bucket definition. Only applies to AGGREGATION_MODES.TIME
     */
    AGGREGATION_SET_VALUE_PARAMS: (payload: { id: string; item: Item }) => {
      const index = _.findIndex(this.state.get('aggregationConfigs'), {
        id: payload.id,
      });
      if (index > -1) {
        const originalConfig = this.state.get(['aggregationConfigs', index]);
        const config = _.assign({}, originalConfig, _.omit(payload, ['item']));
        if (_.has(payload, 'item')) {
          config.item = _.pick(payload.item, this.TOOL_ITEM_PROPS);
        } else if (originalConfig.mode !== config.mode) {
          delete config.item;
        }

        config.valid = this.validateAggregationConfig(config);
        this.state.set(['aggregationConfigs', index], config);
      }
    },

    /**
     * Set the emptyBucketsAllowed flag.
     *
     * @param {Object} payload - Object container
     * @param {Boolean} payload.emptyBucketsAllowed - true if empty buckets should be included in the result
     */
    AGGREGATION_SET_ALLOW_EMPTY_BUCKETS: (payload: { emptyBucketsAllowed: boolean }) => {
      this.state.set('includeEmptyBuckets', payload.emptyBucketsAllowed);
    },

    /**
     * Adds an entry to the aggregationConfigs array. This function also assigns a unique id to the entry that can
     * then be used to update it.
     */
    AGGREGATION_ADD_AGGREGATION: () => {
      const id = (_.max(_.map(this.state.get('aggregationConfigs'), 'id')) as number) + 1;
      this.state.push('aggregationConfigs', _.assign({}, DEFAULT_AGGREGATION_CONFIG, { id }));
      // Empty buckets cannot be included once there is more than one grouping
      this.state.set('includeEmptyBuckets', false);
    },

    /**
     * Removes an entry from the aggregationConfigs.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.id - id of the aggregation to remove.
     */
    AGGREGATION_REMOVE: (payload: { id: string }) => {
      const index = _.findIndex(this.state.get('aggregationConfigs'), {
        id: payload.id,
      });

      if (index > -1) {
        this.state.splice('aggregationConfigs', [index, 1]);
      }

      if (this.state.get('aggregationConfigs').length === 1) {
        this.state.set('includeEmptyBuckets', true);
      }
    },
  };
}
