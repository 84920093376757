// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Modal } from '@seeqdev/qomponents';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { MAX_NAME_LENGTH } from '@/main/app.constants';
import { warnToast } from '@/utilities/toast.utilities';
import { renameDisplay } from '@/displays/displays.actions';
import { doTrack } from '@/track/track.service';

interface RenameDisplayModalProps {
  onClose: () => void;
  display: { id: string; name: string; type: string };
  displaysCount: number;
}

export const RenameDisplayModal: React.FunctionComponent<RenameDisplayModalProps> = ({
  onClose,
  display,
  displaysCount,
}) => {
  const { t } = useTranslation();

  const [displayName, setDisplayName] = useState(display.name);

  const formDefinition: FormElement[] = [
    {
      component: 'LabelFormComponent',
      name: 'renameDisplayLabel',
      value: 'DISPLAYS.MODAL.RENAME.BODY_DISPLAY',
      valueCustomParams: { ITEM_NAME: display.name },
    },
    {
      component: 'LabelFormComponent',
      name: 'renameDisplayWarning',
      value: 'DISPLAYS.MODAL.MULTIPLE_DISPLAY_WARNING',
      valueCustomParams: { SCALE_COUNT: displaysCount - 1 },
      extraClassNames: 'text-italic',
      includeIf: displaysCount > 1,
    },
    {
      component: 'FormControlFormComponent',
      name: 'renameDisplayName',
      value: displayName,
      extraClassNames: 'mtb10',
      size: 'lg',
      placeholder: 'DISPLAYS.MODAL.RENAME.NAME_PLACEHOLDER',
      maxLength: MAX_NAME_LENGTH.TOOL,
      onChange: (name: string) => setDisplayName(name),
      testId: 'renameDisplayNameInput',
      required: true,
    },
  ];

  /**
   * Renames the selected display
   *
   * @returns {Promise} that resolves when the update is complete
   */
  function trackAndRenameDisplay() {
    if (_.isEmpty(_.trim(displayName))) {
      warnToast({ messageKey: 'DISPLAYS.MODAL.RENAME.NAME_REQUIRED' });
      return Promise.resolve();
    }

    doTrack('Search results', 'Rename Display', `rename display item from ${display.name} to ${displayName}`);
    return renameDisplay(display, displayName).then(() => {
      onClose();
    });
  }

  return (
    <Modal
      open={true}
      onClose={onClose}
      testId="renameDisplayModal"
      title={t('DISPLAYS.MODAL.RENAME.TITLE')}
      hideFooterButtons={true}>
      <div data-testid="renameDisplayModalBody">
        <SimpleSaveFormBuilder
          testId="renameSimpleSaveFormBuilder"
          formDefinition={formDefinition}
          submitFn={trackAndRenameDisplay}
          closeFn={onClose}
          submitBtnLabel="RENAME"
        />
      </div>
    </Modal>
  );
};
