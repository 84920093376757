// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ReactI18NextChild, useTranslation } from 'react-i18next';
import { getSupportedUnits } from '@/services/systemConfiguration.utilities';
import { Select } from '@seeqdev/qomponents';

/**
 * Select input to choose from a list of units of measure
 */
interface SelectUnitProps {
  onChange: (unit: string) => void;
  value?: string;
  height?: string;
  menuIsOpen?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  extraClassNames?: string;
  menuPortalTarget?: null | HTMLElement;
}

export const SelectUnit: React.FunctionComponent<SelectUnitProps> = (props) => {
  const { t } = useTranslation();
  const {
    onChange,
    value,
    menuIsOpen,
    isDisabled = false,
    isClearable = true,
    placeholder,
    extraClassNames,
    menuPortalTarget,
  } = props;

  const [options, setOptions] = useState([]);

  const formatCategory = (category) => _.chain(category).upperCase().replace(new RegExp(' ', 'g'), '_').value();

  useEffect(() => {
    getSupportedUnits().then((units) => {
      setOptions(
        _.reduce(
          units,
          (result, unitsForCategory, category) =>
            result.concat({
              label: t(`UNIT_CATEGORIES.${formatCategory(category)}`),
              options: _.map(unitsForCategory, (unit) => ({
                searchString: `${t(`UNITS_BY_CATEGORY.${formatCategory(category)}.${unit}`)} ${t(
                  `UNITS_BY_CATEGORY.${category}.${unit}`,
                )} ${unit}`,
                value: unit,
                label: (
                  // be sure to also update the search string if you make changes here!
                  <>
                    <strong className="pr5" data-testid="optionText">
                      {unit as ReactI18NextChild}
                    </strong>
                    <small>{t(`UNITS_BY_CATEGORY.${formatCategory(category)}.${unit}`)}</small>
                  </>
                ),
              })),
            }),
          [],
        ),
      );
    });
  }, []);

  const selectedValue = _.chain(options).flatMap('options').find({ value }).value();

  const filterConfig: any = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: false,
    matchFrom: 'any',
    stringify: (option) => option.data.searchString,
  };

  return (
    <div data-testid="selectUnit">
      <Select
        placeholder={placeholder ? t(placeholder) : undefined}
        isClearable={isClearable}
        options={options}
        isSearchable={true}
        onChange={(data: { value: string }) => onChange(data?.value)}
        value={selectedValue}
        menuIsOpen={menuIsOpen}
        menuPortalTarget={menuPortalTarget}
        isDisabled={isDisabled}
        filterConfig={filterConfig}
        extraClassNames={extraClassNames}
      />
    </div>
  );
};

export default SelectUnit;
