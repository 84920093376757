// @ts-strict-ignore
import _ from 'lodash';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';

/**
 * Extracts the ancestors information from the provided response and adds a property to each item with the
 * assets, including the formatted name that includes the ancestors.
 *
 * @param {Object} data - Response from an endpoint that includes the ancestors property
 * @returns {Object} Transformed data with items containing a new assets property that is the list of assets the
 * item belongs to.
 */
export function addAssetsProperty(data) {
  _.forEach(_.get(data, 'items'), (item, k) => {
    data.items[k].assets = [getAssetFromAncestors(item.ancestors)];
  });
  return data;
}

/**
 * Returns an asset object with id, name, and formattedName based on its ancestors.
 *
 * @param {Object[]} ancestors - The array of ancestors
 * @returns {Object} Transformed object ancestors new properties.
 *                   {String} .id - ID of asset dependency
 *                   {String} .name - Name of asset dependency
 *                   {String} .formattedName - Name that includes the full asset path
 */
export function getAssetFromAncestors(ancestors) {
  return _.assign(
    {},
    _.pick(_.last(ancestors), ['id', 'name']),
    { formattedName: _.map(ancestors, 'name').join(ASSET_PATH_SEPARATOR) },
    { pathComponentIds: _.map(ancestors, 'id') },
  );
}

/**
 * Helper function to take an object and format all of its key/value pairs into a single query parameter string
 *
 * @param {Object} params - Object containing the key/value pairs to use as query parameters
 * @returns {string} containing all owned properties
 */
export function formatAsQueryString(params) {
  const searchParams = new URLSearchParams();
  _.forEach(params, (value, key) => {
    if (value !== undefined) searchParams.append(key, value);
  });
  return searchParams.toString();
}
