// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { Capsule } from '@/utilities/datetime.constants';
import { sqConditionsApi } from '@/sdk/api/ConditionsApi';
import { CapsuleGroupCapsule } from '@/tools/manualCondition/manualCondition.constants';
import { formatApiError } from '@/utilities/utilities';
import {
  conditionFormula,
  makeCapsulesUnique,
  removeInvalidCapsules,
} from '@/tools/manualCondition/conditionFormula.service';
import {
  sqCapsuleGroupStore,
  sqInvestigateStore,
  sqManualConditionStore,
  sqTrendCapsuleStore,
  sqTrendStore,
} from '@/core/core.stores';
import {
  cancelPreviewCapsules,
  displayEmptyPreviewCapsuleLane,
  generatePreviewCapsules,
  removePreviewCapsules,
  removeTrendSelectedRegion,
  setEditModeForCapsuleSet,
} from '@/trendData/trend.actions';
import { flux } from '@/core/flux.module';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { asyncDebounce } from '@/utilities/asyncDebounce';
import { PUSH_IGNORE } from '@/core/flux.service';

export function setIsExecuting(isExecuting: boolean) {
  flux.dispatch('MANUAL_CONDITION_SET_IS_EXECUTING', { isExecuting }, PUSH_IGNORE);
}

/**
 * Requests capsule properties for the capsules in the manual condition store that have a propertiesSource
 * property. A call is made for each capsule set that encompases the range of all the capsules. The returned
 * capsules are then matched and the properties are copied
 *
 * @return {Promise} a promise that resolves with the formula
 */
export function fetchPropertiesAndBuildFormula() {
  const groupCapsules: CapsuleGroupCapsule[] = sqCapsuleGroupStore.capsules;

  return Promise.resolve()
    .then(() =>
      _.chain(groupCapsules)
        .filter((c) => !_.isNil(c.propertiesSource))
        .groupBy((c) => c.propertiesSource.capsuleSetId)
        .mapValues((capsules) => ({
          capsules,
          startTime: _.minBy(capsules, (c) => c.propertiesSource.startTime).propertiesSource.startTime,
          endTime: _.maxBy(capsules, (c) => c.propertiesSource.endTime).propertiesSource.endTime,
        }))
        .map(({ capsules, startTime, endTime }, capsuleSetId) =>
          sqConditionsApi
            .getCapsules({
              id: capsuleSetId,
              start: moment(startTime).toISOString(),
              end: moment(endTime).toISOString(),
            })
            .catch((error) => Promise.reject(formatApiError(error)))
            .then(({ data }) =>
              _.map(capsules, ({ id, startTime, endTime, propertiesSource }) => {
                const properties = _.get(
                  _.find(
                    data.capsules as any[],
                    (responseCapsule) => responseCapsule.id === propertiesSource.capsuleId,
                  ),
                  'properties',
                  [],
                );

                return { id, startTime, endTime, properties } as Capsule;
              }),
            ),
        )
        .thru((p) => Promise.all(p))
        .value(),
    )
    .then((r) =>
      _.chain(r)
        .flatten()
        .concat(_.filter(groupCapsules, (c) => _.isNil(c.propertiesSource)) as Capsule[])
        .value(),
    )
    .then((c) => _.tap(c, exposedForTesting.setCapsules))
    .then(removeInvalidCapsules)
    .then(makeCapsulesUnique)
    .then(conditionFormula);
}

export function setColor(color: string): void {
  flux.dispatch('MANUAL_CONDITION_SET_COLOR', { color });
  triggerManualConditionPreviewDebounced();
}

export const triggerManualConditionPreviewDebounced = asyncDebounce(triggerManualConditionPreview, DEBOUNCE.PREVIEW);

export async function triggerManualConditionPreview() {
  if (sqManualConditionStore.isExecuting) {
    return;
  }

  await cancelPreviewCapsules();
  if (sqInvestigateStore.activeTool === TREND_TOOLS.MANUAL_CONDITION) {
    if (_.isNil(sqTrendCapsuleStore.editingId)) {
      setEditModeForCapsuleSet(sqManualConditionStore.id);
    }

    await generatePreviewCondition();
  } else {
    removePreviewCapsules();
  }
}

const generatePreviewCondition = () => {
  const capsules = sqCapsuleGroupStore.capsules;
  if (capsules.length === 0) {
    displayEmptyPreviewCapsuleLane(); // Note: the empty lane isn't shown until it succeeds once

    return Promise.resolve();
  }

  return Promise.resolve(capsules)
    .then((capsules) =>
      _.map(capsules, ({ startTime, endTime }) => ({
        startTime,
        endTime,
        properties: [],
      })),
    )
    .then(removeInvalidCapsules)
    .then(makeCapsulesUnique)
    .then(conditionFormula)
    .then((formula) =>
      generatePreviewCapsules(
        formula,
        {},
        sqManualConditionStore.id,
        sqManualConditionStore.color,
        true, // usePost - Formulas can become large enough to exceed node's max http header size
      ),
    );
};

/**
 * Sets the capsules in the capsule group store. If the Manual Condition tool is open, this also kicks off the
 * preview for manual condition.
 *
 * @param {Object} capsules - array of capsule objects
 */
export function setCapsules(capsules: CapsuleGroupCapsule[]) {
  flux.dispatch('CAPSULE_GROUP_SET_CAPSULES', { capsules });
  triggerManualConditionPreviewDebounced();
}

/**
 * Adds the capsule represented by the selection to the capsule group store and removes the selection. If the Manual
 * Condition tool is open, this also kicks off the preview for manual condition.
 */
export function pickSelectedRegion({ formCapsule }: { formCapsule?: CapsuleGroupCapsule }) {
  const capsule = formCapsule || {
    startTime: sqTrendStore.selectedRegion.min,
    endTime: sqTrendStore.selectedRegion.max,
    properties: [],
  };

  flux.dispatch('CAPSULE_GROUP_ADD_CAPSULE', { capsule });
  removeTrendSelectedRegion();
  triggerManualConditionPreviewDebounced();
}

export const exposedForTesting = {
  setCapsules,
};
