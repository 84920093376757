// @ts-strict-ignore
import _ from 'lodash';
import Highcharts, { ChartSelectionAxisContextObject } from 'highcharts';
import {
  BAR_CHART_ESSENTIALS,
  CAPSULE_TYPES,
  ChartGetters,
  ChartRegion,
  DISABLED_MARKER,
  ENABLED_MARKER,
  GRAY_LANE_COLOR,
  LINE_WIDTHS,
  PixelTranslationFunction,
  SeriesGroupedByAxis,
  UNCERTAIN_CAPSULE_TYPES,
  WHITE_LANE_COLOR,
  XYPixelRegion,
} from '@/chart/chart.constants';
import {
  CapsuleTimeColorMode,
  CUSTOMIZATION_MODES,
  DEFAULT_CAPSULE_LINE_WIDTH,
  ITEM_TYPES,
  LABEL_LOCATIONS,
  LabelDisplayConfiguration,
  PREVIEW_HIGHLIGHT_COLOR,
  PREVIEW_ID,
  SAMPLE_OPTIONS,
} from '@/trendData/trendData.constants';
import {
  anyLabelsOnLocation,
  fetchTickAttributes,
  formatStringYAxisLabel,
  formatYAxisTick,
  getAxisDisplayText,
  getCapsuleAxisId,
  getLabelWidth,
  getLaneDisplayText,
  getNumericTickPositions as getNumericTickPositionsSqLabel,
} from '@/utilities/label.utilities';
import {
  DEFAULT_AXIS_LABEL_COLOR,
  DEFAULT_AXIS_LINE_COLOR,
  LANE_LABEL_CONFIG,
  PLOT_BAND_AXIS_ID,
} from '@/trend/trendViewer/trendViewer.constants';
import { DEFAULT_EXTREMES, XYPlotRegion } from '@/scatterPlot/scatterPlot.constants';
import { ConditionValuesForLabels, TrendDataForChart } from '@/annotation/interactiveContent.types';
import {
  AdjustableConditions,
  ChartLaneOptions,
  ConditionAxisAndOffsets,
  ConditionLaneData,
} from '@/utilities/chart.types';
import { chartLanes } from '@/utilities/chartLanes';
import { getCertainId } from './utilities';
import { LANE_LABEL_HEIGHT } from '@/utilities/chartLanes.constants';
import { Extremes } from 'other_components/highcharts';
import { formatString } from '@/utilities/stringHelper.utilities';
import { formatNumber, FormatOptions } from '@/utilities/numberHelper.utilities';
import { StringEnum } from '@/trendData/trendData.types';

/**
 * detect incorrect regions on trend
 */
export function isInvalidPixel(capsuleRegion: XYPixelRegion) {
  const { xMinPixel, xMaxPixel } = capsuleRegion;

  return !_.isFinite(xMinPixel) || !_.isFinite(xMaxPixel) || xMinPixel >= xMaxPixel;
}

/**
 * translate the XYRegion to a pixel region (with an id and dateTime if it is a capsule)
 */
export function translateRegion(
  chart: Highcharts.Chart,
  xAxis: Highcharts.Axis,
  yAxis: Highcharts.Axis,
  translate: { x?: PixelTranslationFunction; y?: PixelTranslationFunction },
  chartRegion: ChartRegion,
): XYPixelRegion {
  const xMin = chartRegion.xMin;
  const xMax = chartRegion.xMax;
  const yMin = chartRegion.yMin;
  const yMax = chartRegion.yMax;
  const xPixels = translateAxis(xMin, xMax, xAxis, translate.x);
  const yPixels = translateAxis(yMin, yMax, yAxis, translate.y);

  function translateAxis(min: number, max: number, axis: Highcharts.Axis, translate: PixelTranslationFunction) {
    if (translate) {
      return translate(chart, min, max);
    }

    const extremes = axis.getExtremes();

    return {
      minPixel: axis.translate(Math.max(min, extremes.min)),
      maxPixel: axis.translate(Math.min(max, extremes.max)),
    };
  }

  return {
    xMinPixel: xPixels.minPixel,
    xMaxPixel: xPixels.maxPixel,
    yMinPixel: yPixels.minPixel,
    yMaxPixel: yPixels.maxPixel,
    id: chartRegion.id,
    dateTime: chartRegion.dateTime,
    yValue: chartRegion.yValue,
    lane: chartRegion.lane,
  };
}

/**
 * Checks to see if the mouse has left the plot area and resets the zoomInProgress flag to reset the selection
 * marker
 *
 * @param e - The mouse event
 * @param highChart - the current chart object
 */
export function mouseLeftActualChartArea(e: MouseEvent, highChart: Highcharts.Chart | null) {
  let plotBox;
  let downXPixels;
  let downYPixels;

  if (e && highChart && highChart.pointer) {
    downXPixels = highChart.pointer.normalize(e).chartX;
    downYPixels = highChart.pointer.normalize(e).chartY;
    plotBox = highChart.plotBox;
    return (
      downYPixels <= plotBox.y ||
      downYPixels > plotBox.y + plotBox.height ||
      downXPixels < plotBox.x ||
      downXPixels > plotBox.x + plotBox.width
    );
  } else {
    return true;
  }
}

/**
 * Find the chart series object that matches the id specified
 *
 * @param highChart - the current chart object
 * @param  id - ID value for which to search
 * @return Chart series object; undefined if not found
 */
export const findChartSeries = (highChart: Highcharts.Chart | null, id: string): Highcharts.Series => {
  return _.find(highChart?.series as Highcharts.Series[], { options: { id } });
};

/**
 * Transforms a pair of Highcharts X and Y selection objects with min and max into a matching XYRegion
 *
 * @param xAxis axis for min and max x points
 * @param yAxes axes for min and max y points
 * @return The selected region according to the axis objects
 */
export function getXYRegion(
  xAxis: ChartSelectionAxisContextObject,
  yAxes: ChartSelectionAxisContextObject[],
): XYPlotRegion {
  const x = {
    min: xAxis.min,
    max: xAxis.max,
  };
  const ys: Record<string, Extremes> = {};
  _.forEach(yAxes, (axis) => {
    ys[axis.axis.userOptions.signalId] = {
      min: axis.min,
      max: axis.max,
    };
  });
  return {
    x,
    ys,
  };
}

export function setScatterPlotExtremes(xAxis: Highcharts.Axis, yAxes: Highcharts.Axis[], region: XYPlotRegion) {
  xAxis.setExtremes(region.x.min, region.x.max, false);
  _.forEach(yAxes, (axis) => {
    const signalId = axis.userOptions.signalId;
    const y = region.ys[signalId] ?? DEFAULT_EXTREMES;
    axis.setExtremes(y.min, y.max, false);
  });
}

/**
 * Sets the label visibility and layout of a given axis. Does not redraw the chart.
 *
 * @param axis - The axis label to be updated
 * @param properties - Object containing the properties and their new values. Properties are merged
 *   with existing properties or the axis.
 */
export function setAxisProperties(axis: Highcharts.Axis, properties: any) {
  if (axis) {
    axis.update(properties, false);
  }
}

/**
 * Gets the axis associated with an item
 *
 * @param chart - The highcharts chart to look at
 * @param item - The item to find
 * @param  item.id - The id of the item to find
 *
 * @returns  The requested axis; or undefined if not found.
 */
export function getItemYAxis(
  chart: Highcharts.Chart,
  item: { id: string; capsuleSetId: string; itemType: string },
): any | undefined {
  const id = item?.itemType === ITEM_TYPES.CAPSULE ? getCapsuleAxisId(item.capsuleSetId) : `yAxis-${item?.id}`;

  return _.find(chart.yAxis, { userOptions: { id } }) as any;
}

//region Lane Helpers

/**
 * Helper function that returns an array of unique values for the provided property ordered in the same order of
 * the possible values provided. This is useful to get all the assigned lanes as well as axis assignments.
 *
 * @param items - list of items to check for properties
 * @param property - the property to retrieve the unique assignments for.
 * @param possibleValues - the possible values the property could be
 * @returns Array of the unique values for the provided property.
 */
export function getUniqueOrderedValuesByProperty(items: any[], property: string, possibleValues: any[]): any[] {
  return _.chain(items)
    .map(property)
    .compact()
    .uniq()
    .orderBy((value) => _.indexOf(possibleValues, value))
    .value();
}

/**
 * Clip signals to their lane so they can't be dragged into other lanes (CRAB-8895)
 *
 * This works by using the plotBand highlighting to create a clipRect for the lane and using it for all signals
 * within the lane. The clipRects are actually clipPaths[1] in the svg. All the signals can share the same
 * clipRect because highcharts uses the transform attribute[2] to move the svg path into position and the
 * clipping path is transformed with the data
 *
 * [1]: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/clipPath
 * [2]: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/transform
 */
export function clipSignalsToLanes(options: {
  laneClipRects: Highcharts.ClipRectElement[];
  items: any[];
  chart: Highcharts.Chart;
  capsuleLaneHeight: number;
  isCapsuleTime: boolean;
  laneWidth: number;
  labelDisplayConfiguration: LabelDisplayConfiguration;
}): Highcharts.ClipRectElement[] {
  const { chart, items, isCapsuleTime, capsuleLaneHeight, laneClipRects, laneWidth, labelDisplayConfiguration } =
    options;
  laneClipRects?.forEach((clipRect) => clipRect.destroy?.());

  const signalLaneHeight = chartLanes.getSignalLaneHeight({ chart, items, isCapsuleTime, capsuleLaneHeight });

  return chart.series.map((chartSeries) => {
    const lane = _.get(chartSeries, 'userOptions.lane', false);
    const labelHeight = getLabelHeight(labelDisplayConfiguration, lane);
    const newLaneClipRect = chart.renderer.clipRect(0, 0, laneWidth, signalLaneHeight - labelHeight);
    const isSignal = _.includes([ITEM_TYPES.SCALAR, ITEM_TYPES.SERIES], _.get(chartSeries, 'userOptions.itemType'));
    if (chartSeries.visible && lane && !_.isUndefined(chartSeries.group) && isSignal) {
      chartSeries.group.clip(newLaneClipRect);
      chartSeries.markerGroup.clip(newLaneClipRect);
    }

    return newLaneClipRect;
  });
}

/**
 * Returns an array of series organized by how their y-axes should be displayed.
 *
 * @return An array of series, organized by how they should be displayed
 *  .primarySeries - Primary series for this axis. The y-axis for this series is the one that is
 *    displayed for all items in .series.
 *  .series - All series that occupy the same axis area and should all be updated together
 *  .hide - If true, the axis shouldn't be displayed
 */
export function getSeriesForYAxisInteraction(options: { items: any[]; lane?: string }): SeriesGroupedByAxis[] {
  const { items, lane } = options;
  let series = _.filter(
    items,
    (item: any) =>
      item.itemType === ITEM_TYPES.SERIES ||
      item.itemType === ITEM_TYPES.SCALAR ||
      item.itemType === ITEM_TYPES.CAPSULE_SET,
  );

  if (!_.isUndefined(lane)) {
    series = _.filter(series, { lane });
  }

  if (series.length === 0) {
    return [
      {
        primarySeries: undefined,
        series,
      },
    ];
  }

  // for multiple series which have the same yAxisAlignment, ensure that we only show one axis
  const skippedSeriesIds = [];
  return _.transform(
    series,
    (accum, singleSeries: any, index) => {
      const remainingSeries = _.slice(series, index + 1);
      const matches = _.chain(remainingSeries)
        .filter((other) => shareSameAxis([singleSeries, other]) && shareSameLane([singleSeries, other]))
        .forEach((match: any) => skippedSeriesIds.push(match.id))
        .value();

      if (!_.includes(skippedSeriesIds, singleSeries.id)) {
        accum.push({
          primarySeries: singleSeries,
          series: _.concat([singleSeries], matches),
          hide: false,
        });
      }
    },
    [],
  );
}

/**
 * Helper function to determine if all the provided Series share the same lane.
 *
 * @param seriesList - Array of series items.
 * @returns true if the series share the same lane, false if not.
 */
function shareSameLane(seriesList: any[]): boolean {
  return _.chain(seriesList).map('lane').uniq().value().length === 1;
}

/**
 * Helper function to determine if all the provided Series share the same axis.
 *
 * @param seriesList - Array of series items.
 * @returns true if the series share the same axis, false if not.
 */
function shareSameAxis(seriesList: any[]): boolean {
  return _.chain(seriesList).map('axisAlign').uniq().value().length === 1;
}

function getLabelHeight(labelDisplayConfiguration: LabelDisplayConfiguration, lane: number): number {
  if (!anyLabelsOnLocation(labelDisplayConfiguration, LABEL_LOCATIONS.LANE)) {
    return 0;
  }

  const hasOnlyCustomLabelsInLane =
    labelDisplayConfiguration.custom === LABEL_LOCATIONS.LANE &&
    !anyLabelsOnLocation({ ...labelDisplayConfiguration, custom: LABEL_LOCATIONS.OFF }, LABEL_LOCATIONS.LANE);
  const hasCustomLabelInLane = labelDisplayConfiguration.customLabels.some((label) => label.target === lane);
  if (hasOnlyCustomLabelsInLane && !hasCustomLabelInLane) {
    return 0;
  }

  return LANE_LABEL_HEIGHT;
}

/**
 * Iterates through the y axes and pushes any necessary lane and formatting changes to the chart.
 *
 * @param options
 * @return the new plot band colors
 */
export function processYAxisChangesPerLane(options: {
  isCapsuleTime: boolean;
  isCompareViewRainbowColorMode?: boolean;
  items: any[];
  chart: Highcharts.Chart;
  capsuleLaneHeight: number;
  capsuleTimeColorMode: CapsuleTimeColorMode;
  labelDisplayConfiguration: LabelDisplayConfiguration;
  useDefaultColor?: boolean;
}): Record<number, string> {
  const {
    isCapsuleTime,
    isCompareViewRainbowColorMode,
    items,
    capsuleTimeColorMode,
    chart,
    labelDisplayConfiguration,
    useDefaultColor = false,
  } = options;
  let labelColor: string;
  const plotBandColors = {};
  const lanes = chartLanes.getDisplayedLanes(items);
  let opposite = false;
  const seriesByAxis = getSeriesForYAxisInteraction(options);

  _.forEach(lanes, (lane) => {
    const labelHeight = getLabelHeight(labelDisplayConfiguration, lane);
    const seriesInLane = _.filter(seriesByAxis, (s) => _.get(s.primarySeries, 'lane') === lane);

    const { offset, height: laneHeight } = chartLanes.computeLaneValues(lane, options);
    // If labels are enabled or a string signal is in the first lane make room for them by pushing the Y-axis down
    const axisHeight = laneHeight - labelHeight;
    const axisTop = offset + labelHeight;

    _.forEach(seriesInLane, (seriesGroup) => {
      const axis = getItemYAxis(chart, seriesGroup.primarySeries);
      let visible = seriesGroup.primarySeries.axisVisibility;

      // When updating a series, the addition of its preview series causes an update before the preview has an
      // axis
      if (_.isUndefined(axis)) {
        return;
      }

      if (seriesGroup.series.length > 1) {
        const visibleSeries = _.chain(seriesGroup.series).filter({ axisVisibility: true }).uniqBy('id').value();

        const allColorsSame =
          visibleSeries.length > 0 ? _.every(visibleSeries, ['color', visibleSeries[0]?.color]) : false;
        const hasColors =
          isCompareViewRainbowColorMode ||
          (isCapsuleTime &&
            _.includes([CapsuleTimeColorMode.Rainbow, CapsuleTimeColorMode.ConditionGradient], capsuleTimeColorMode));
        if (allColorsSame && !hasColors) {
          labelColor = visibleSeries[0].color;
        } else {
          labelColor = DEFAULT_AXIS_LABEL_COLOR;
        }

        const temp = _.omitBy(seriesGroup.series, (tempSeries) => tempSeries.id === seriesGroup.primarySeries.id);
        _.forEach(temp, (t) => {
          const tempAxis = getItemYAxis(chart, t);
          setAxisProperties(tempAxis, {
            visible: false,
            height: axisHeight,
            top: axisTop,
          });
        });
      } else {
        labelColor = seriesGroup.primarySeries.color;
        if (
          !_.isFinite(_.get(seriesGroup.primarySeries, 'yAxisConfig.min')) &&
          !_.isFinite(_.get(seriesGroup.primarySeries, 'yAxisConfig.max'))
        ) {
          visible = false;
        }
      }

      if (!_.isUndefined(_.find(seriesGroup.series as any[], (signal) => _.startsWith(signal.id, PREVIEW_ID)))) {
        plotBandColors[lane] = PREVIEW_HIGHLIGHT_COLOR;
      }

      opposite = seriesGroup.primarySeries.rightAxis;
      setAxisProperties(axis, {
        visible,
        lineColor: useDefaultColor ? DEFAULT_AXIS_LINE_COLOR : labelColor,
        lineWidth: 1,
        labels: {
          enabled: visible,
          align: opposite ? 'left' : 'right',
          x: opposite ? 5 : -5,
          style: { ...axis?.labels?.style, color: labelColor },
        },
        height: axisHeight,
        formatOptions: seriesGroup.primarySeries.formatOptions || {},
        top: axisTop,
        opposite,
        type: seriesGroup.primarySeries.yAxisType,
      });
    });
  });

  return plotBandColors;
}

/**
 * Updates the "lanes" background on the chart. The label object is defined so that the lane display can be
 * turned on/off based on the customizationMode in the trendStore.
 *
 * @param options.colors - Map of lane number to custom color for that lane
 */
export function updatePlotBands(options: {
  colors: any;
  items: any[];
  isCapsuleTime: boolean;
  capsuleLaneHeight: number;
  chart: Highcharts.Chart;
}): Map<number, string> {
  const { colors, items, chart, capsuleLaneHeight } = options;
  const lanes = chartLanes.getDisplayedLanes(items);
  const laneCount = lanes.length;
  const maxHeight = getChartSeriesDisplayHeight(options);

  const plotBandColors = new Map<number, string>();
  const plotBands: Highcharts.YAxisPlotBandsOptions[] = _.flatMap(lanes, (lane, idx) => {
    const itemId = _.find(items, { lane })?.id;
    const isPreviewItem =
      _.includes(itemId, PREVIEW_ID) || _.chain(items).filter({ lane }).some({ isPreviewItem: true }).value();
    const color = isPreviewItem
      ? PREVIEW_HIGHLIGHT_COLOR
      : colors[lane] ?? ((laneCount - idx) % 2 === 0 ? WHITE_LANE_COLOR : GRAY_LANE_COLOR);
    plotBandColors.set(lane, color);
    const { offset, height, buffer } = chartLanes.computeLaneValues(lane, options);

    return [
      {
        color,
        from: maxHeight - offset + capsuleLaneHeight,
        to: maxHeight - (offset + height) + capsuleLaneHeight,
        label: {
          ...LANE_LABEL_CONFIG,
          text: '', // Will be filled in by manageLaneLabelDisplay()
        },
      },
      {
        color: 'transparent',
        from: maxHeight - (offset + height) + capsuleLaneHeight,
        to: maxHeight - (offset + height + buffer) + capsuleLaneHeight,
      },
    ];
  });

  const axis = _.find(chart.yAxis, {
    userOptions: { id: PLOT_BAND_AXIS_ID },
  });

  axis.update(
    {
      plotBands,
      visible: true,
      min: 0,
      max: maxHeight,
      height: maxHeight,
      top: capsuleLaneHeight,
    },
    false,
  );

  return plotBandColors;
}

/**
 * Tiny helper to return the appropriate height for everything that should not overlap the capsule lane.
 *
 * @returns the height in pixel that is available
 */
export function getChartSeriesDisplayHeight(options: { chart: Highcharts.Chart; capsuleLaneHeight: number }): number {
  return options.chart.plotHeight - options.capsuleLaneHeight;
}

/**
 * This function manages the axis offsets and, if addAxisTitle is true, the display of axis labels.
 * Conceptually this function:
 *  - iterates over all the displayed lane
 *  - then iterates over all the series assigned to each lane
 *  - adjust the offset for each series axis so that the labels do not overlap
 *  - keeps track of the biggest offset of any given axis so that the overall chart margins can be set accordingly.
 */
export function manageAxisOffsets(options: {
  addAxisTitle: boolean;
  items: any[];
  isCapsuleTime: boolean;
  chart: Highcharts.Chart;
  capsuleLaneHeight: number;
  sqTrendStore: TrendDataForChart;
  skipAxisUpdate?: boolean;
}): { offsetLeft: number; offsetRight: number } {
  const { addAxisTitle, items, chart, skipAxisUpdate = false, sqTrendStore } = options;

  let largestOffsetLeft = 0;
  let largestOffsetRight = 0;

  const lanes = chartLanes.getDisplayedLanes(items);
  const laneCount = lanes.length;

  _.forEach(lanes, (lane) => {
    const { height: laneHeight } = chartLanes.computeLaneValues(lane, options);

    // Find all the items that are in the current lane.
    const itemsInLane = _.filter(items, (item: any) => {
      return (
        (item.itemType === ITEM_TYPES.SERIES ||
          item.itemType === ITEM_TYPES.SCALAR ||
          item.itemType === ITEM_TYPES.CAPSULE_SET) &&
        _.get(item, 'lane') === lane
      );
    });
    // Find all the unique axis that belong to this lane:
    const uniqueAxisAssignmentInLane = _.chain(itemsInLane).map('axisAlign').uniq().value();
    let offsetLeft = 0;
    let offsetRight = 0;
    const padding = addAxisTitle ? 30 : 10;

    _.forEach(_.sortBy(uniqueAxisAssignmentInLane), (assignment) => {
      const seriesSharingAnAxis = _.filter(itemsInLane, {
        axisAlign: assignment,
      });
      _.forEach(seriesSharingAnAxis, (series) => {
        const axis = getItemYAxis(chart, series);
        if (_.get(axis, 'visible', false)) {
          const tickPositions = getNumericTickPositionsSqLabel(
            axis.userMin,
            axis.userMax,
            series,
            laneHeight,
            laneCount,
          );

          let axisUpdates = {
            title: {
              useHTML: true,
              enabled: false,
              text: '',
              style: {},
            },
          };

          if (addAxisTitle) {
            const axisTitle = getAxisDisplayText(
              items,
              assignment,
              seriesSharingAnAxis,
              axis.height ? axis.height : laneHeight,
              sqTrendStore,
            );

            axisUpdates = {
              title: {
                useHTML: true,
                text: axisTitle,
                enabled: true,
                style: {
                  // This fixes a highcharts issue where the rotation styles are not added in headless render mode
                  // so the content in organizer was displaying with horizontal labels (CRAB-30063)
                  transform: `rotate(${axis.opposite ? 90 : 270}deg)`,
                },
              },
            };
          }

          const labelLength = getLabelWidth(tickPositions, axis, laneHeight, padding, series.isStringSeries, series);

          const opposite = axis.userOptions.opposite;
          if (!skipAxisUpdate) {
            setAxisProperties(
              axis,
              _.assign(axisUpdates, { offset: opposite ? offsetRight : offsetLeft }, { axisWidth: labelLength }),
            );
          }

          if (opposite) {
            offsetRight += labelLength;
            largestOffsetRight = _.max([offsetRight, largestOffsetRight]);
          } else {
            offsetLeft += labelLength;
            largestOffsetLeft = _.max([offsetLeft, largestOffsetLeft]);
          }
        }
      });
    });
  });

  return { offsetLeft: largestOffsetLeft, offsetRight: largestOffsetRight };
}

/**
 * This function renders the "Lane Label" for signals and conditions.
 */
export function manageLaneLabelDisplay(options: {
  chart: Highcharts.Chart;
  items: any[];
  sqTrendStore: TrendDataForChart;
  conditionValues: ConditionValuesForLabels;
}) {
  const { chart, items, sqTrendStore, conditionValues } = options;
  const lanes = chartLanes.getDisplayedLanes(items);
  const labelAxis = _.find(chart.yAxis, {
    userOptions: { id: PLOT_BAND_AXIS_ID },
  });

  _.forEach(lanes, (lane, idx) => {
    // Set the lane display labels, odd plotLinesAndBands are spacers so multiply by 2
    const options = _.get(labelAxis, 'userOptions.plotBands', null);
    if (options && options[idx * 2] && options[idx * 2].label) {
      const labelText = getLaneDisplayText(items, lane, chart.plotWidth - 25, sqTrendStore, conditionValues);
      const userOptions = labelAxis.userOptions;
      userOptions.plotBands[idx * 2].label.text = labelText;
      labelAxis.update(userOptions, false);
    }
  });
}

/**
 * Updates the capsule time y-axis tick positions. It does not redraw the chart.
 */
export function updateYAxisAlignment(options: {
  chart: Highcharts.Chart;
  isCapsuleTime: boolean;
  items: { id: string }[];
}) {
  const { chart, isCapsuleTime, items } = options;
  if (isCapsuleTime) {
    // Iterate through and update all y axes
    _.forEach(chart.yAxis, (axis: any) => {
      // We don't want to update the plot band here
      if (axis.options.id === PLOT_BAND_AXIS_ID) {
        return;
      }

      const item = getAxisItem(items, axis);
      if (!item) {
        return;
      }

      axis.update(
        {
          tickPositions: item.isStringSeries ? _.map(item.stringEnum, 'key').sort() : undefined,
          labels: { enabled: true },
          startOnTick: false,
          endOnTick: false,
        },
        false,
      );
    });
  }
}

//endregion

export function getYAxisIdFromItem(item: { id: string }) {
  return `yAxis-${item.id}`;
}

/**
 * Gets the item associated with an axis
 *
 * @param items - The items to search through
 * @param axis - The axis
 * @returns The requested item; or undefined if not found.
 */
export function getAxisItem(items: { id: string }[], axis: Highcharts.Axis): any {
  return _.find(items, (item: any) => axis.series && axis.series[0] && axis.series[0].userOptions.id === item.id);
}

/**
 * Updates the y-axis extremes of the corresponding series. Does not redraw the chart.
 */
export function updateSeriesYExtremes(options: { items: readonly any[]; chart: Highcharts.Chart }) {
  const { items, chart } = options;
  _.chain(items)
    .filter('yAxisConfig')
    .forEach((item: any) => {
      const axis = getItemYAxis(chart, item);
      if (!axis) {
        return;
      }
      if (axis.logarithmic) {
        axis.setExtremes(Number(item.yAxisMin), Number(item.yAxisMax), false, false);
      } else {
        axis.setExtremes(item.yAxisConfig.min, item.yAxisConfig.max, false, false);
      }
    })
    .value();
}

/**
 * Get the y-axis ticks for an axis based on the items min/max to ensure y-axis labels
 * are only shown within the axis' range
 *
 * @returns an Array of tick positions.
 */
export function getNumericTickPositions(options: ChartLaneOptions & ChartGetters): () => number[] {
  return function () {
    options.chart = options.getChart();
    options.items = options.getItems();
    const item = getAxisItem(options.items, this);
    if (!options.chart || !item) {
      return;
    }

    const laneCount = _.get(chartLanes.getDisplayedLanes(options.items), 'length', 1);
    const { height: laneHeight } = chartLanes.computeLaneValues(item.lane, options);

    const min = item?.yAxisConfig?.min ?? this.min;
    const max = item?.yAxisConfig?.max ?? this.max;

    return getNumericTickPositionsSqLabel(min, max, item, laneHeight, laneCount);
  };
}

/**
 * Formats the y-axis labels.
 */
export function yAxisFormatter(options: ChartLaneOptions & ChartGetters): () => string {
  return function () {
    options.chart = options.getChart();
    options.items = options.getItems();
    if (!options.chart) {
      return;
    }

    const laneHeight = chartLanes.getSignalLaneHeight(options);

    return formatYAxisTick(
      this.value,
      fetchTickAttributes(this.axis.min, this.axis.max, this.axis.userOptions.formatOptions, laneHeight),
    );
  };
}

/**
 * Formats the y-axis string labels.
 */
export function yAxisStringFormatter(options: { getItems: () => any[] }): () => string {
  return function () {
    // this.axis.series[0].options.stringEnum does not get updated when the series updates, therefore get it
    // from items
    const series = _.find(options.getItems(), (item) => item.id === this.axis.series[0]?.options.id) as any;
    if (!series) {
      return;
    }
    return formatStringYAxisLabel(this.value, series, this.axis.userOptions.formatOptions?.stringFormat);
  };
}

export function updateYAxisPropertiesAndLabels(
  options: ChartLaneOptions & {
    sqTrendStore: TrendDataForChart;
    conditionValues: ConditionValuesForLabels;
    capsuleTimeColorMode: CapsuleTimeColorMode;
    axisTitlePresent: boolean;
    labelDisplayConfiguration: LabelDisplayConfiguration;
  },
) {
  const colors = processYAxisChangesPerLane({
    ...options,
    useDefaultColor: true,
  });
  updatePlotBands({ ...options, colors });
  manageAxisOffsets({
    ...options,
    addAxisTitle: options.axisTitlePresent,
  });
  manageLaneLabelDisplay(options);
}

/**
 * Toggles between line and point display.
 *
 * @param options.items - An Array of store items that have changed.
 */
export function setPointsOnly(options: { items: any[]; chart: Highcharts.Chart; isCapsuleTime: boolean }) {
  const { items, isCapsuleTime, chart } = options;
  const chartSeries = chart.series;
  _.forEach(items, (item: any) => {
    const seriesArray = _.filter(chartSeries, (series: any) => {
      return isCapsuleTime ? series.userOptions.isChildOf === item.isChildOf : series.userOptions.id === item.id;
    }) as any[];

    _.forEach(seriesArray, (series) => {
      if (item.sampleDisplayOption === SAMPLE_OPTIONS.BAR) {
        _.assign(series.options, BAR_CHART_ESSENTIALS, {
          pointWidth: item.lineWidth,
        });
      } else if (_.includes(CAPSULE_TYPES, item.itemType)) {
        return; // do not set options for capsule series
      } else {
        series.options.type = 'line';
        if (item.sampleDisplayOption !== SAMPLE_OPTIONS.LINE) {
          if (item.sampleDisplayOption === SAMPLE_OPTIONS.LINE_AND_SAMPLE) {
            series.options.lineWidth = 0.5;
          } else {
            series.options.lineWidth = 0;
          }

          const radius = item.lineWidth + 1;
          series.options.marker = { ...ENABLED_MARKER, radius };
        } else {
          series.options.lineWidth = item.lineWidth || LINE_WIDTHS[item.itemType];
          series.options.marker = DISABLED_MARKER;
        }
      }
      series.update(series.options, false);
    });
  });
}

/**
 * Formats the capsule labels
 */
export function formatCapsuleLabel(): string {
  const zeroLength = this.series.userOptions?.sampleDisplayOption === SAMPLE_OPTIONS.SAMPLES;

  // zeroLength capsules have 2 overlapping data points. We need to make sure we get the "correct" aka "first"
  // point to ensure the following logic works as expected.
  const point = !zeroLength ? this.point : _.first(_.filter(this.series.data, { clientX: this.point.clientX }));

  if (!point.dataLabelString) {
    return null;
  }
  let label = point.dataLabelString;
  let width = null;
  // zeroLength capsules are placed on a separate series to ensure the marker property doesn't cause unwanted
  // artifacts (https://seeq.atlassian.net/browse/CRAB-37825), but because of that the "next" point is often not the
  // actual next point, so instead of taking the next data point we use the nextPoint property (that is set in the
  // trendCapsule.store) to determine the max width of the label.
  if (zeroLength) {
    if (point.nextPointStart) {
      const axis = this.series.chart.xAxis[0];
      width = Math.floor(axis.toPixels(point.nextPointStart) - axis.toPixels(point.x));
    }
  } else {
    // Constrain the width to ensure long labels don't run outside the capsule
    const nextPoint = _.find<Highcharts.Point>(this.series.data, {
      index: point.index + 1,
    });

    width = Math.floor(nextPoint?.plotX - point.plotX);
  }
  const widthStyle = _.isFinite(width) ? `width: ${width}px` : '';
  const colorStyle = zeroLength ? `color: ${point.color}` : `color: ${point.contrastColor}`;
  return `<span style="${colorStyle}; ${widthStyle}">${label}</span>`;
}

/**
 * Formats the label text for a point on a signal
 *
 * @param yValue - The Y-value of the point
 * @param signal - The signal from which the point comes
 * @param includeUnitOfMeasure - Whether to append the unit of measure to numeric values
 * @param useStringFormatter - Whether to format string values with the formatter
 */
export function formatPointLabel(
  yValue: number | string,
  signal: {
    isStringSeries?: boolean;
    stringEnum?: StringEnum[];
    formatOptions?: FormatOptions & { stringFormat?: string };
    valueUnitOfMeasure?: string;
    sourceValueUnitOfMeasure?: string;
  },
  includeUnitOfMeasure = true,
  useStringFormatter = false,
): string {
  if (signal.isStringSeries) {
    const stringValue = signal.stringEnum.find(({ key }) => key === yValue)?.stringValue ?? '';
    return useStringFormatter
      ? formatString(stringValue, {
          format: signal.formatOptions?.stringFormat,
        })
      : stringValue;
  }

  let value = _.isFinite(yValue) ? formatNumber(yValue, signal.formatOptions) : yValue.toString();
  if (includeUnitOfMeasure && (signal.valueUnitOfMeasure || signal.sourceValueUnitOfMeasure)) {
    value += ` ${signal.valueUnitOfMeasure ?? `<em>${signal.sourceValueUnitOfMeasure}</em>`}`;
  }
  return value;
}

/**
 * Returns an offset for conditions in the same lane if capsules overlap
 */
export function getCapsuleRowOffset(lane: number, capsuleRows: any[], allLaneData: ConditionLaneData): number {
  const capsules = _.flatMap(capsuleRows, 'capsules');

  if (!allLaneData.has(lane)) {
    lane && allLaneData.set(lane, { nextOffset: 0, capsulesMap: new Map([[0, capsules]]) });

    return 0;
  }

  const laneData = allLaneData.get(lane);

  const offsets = [...laneData.capsulesMap.keys()];
  let offsetIndex = 0;
  let offset = offsets[offsetIndex] ?? -1;
  for (let i = 0; i < capsules.length; i++) {
    const capsule = capsules[i];

    while (offsetIndex < offsets.length) {
      offset = offsets[offsetIndex];
      const offsetCapsules = laneData.capsulesMap.get(offset);
      const isOverlapping = _.some(
        offsetCapsules,
        (c) => capsule.startTime < c.endTime && capsule.endTime > c.startTime,
      );
      if (!isOverlapping) {
        break;
      }

      offsetIndex++;
    }

    if (offsetIndex === offsets.length) {
      offset = laneData.nextOffset;
      break;
    }
  }

  laneData.capsulesMap.set(offset, _.concat(capsules, laneData.capsulesMap.get(offset) ?? []));

  return offset;
}

function findUncertainCapsules(items: any[], capsuleSetId: string): any[] {
  return _.chain(items)
    .filter(
      ({ capsuleSetId: id, itemType }) =>
        getCertainId(id) === capsuleSetId && _.includes(UNCERTAIN_CAPSULE_TYPES, itemType),
    )
    .value();
}

/**
 * Each capsule series has it's own y-axis.
 *
 * If lane labels are shown the buffer for each axis is increased to ensure the label can be displayed without
 * interfering with the capsule display.
 *
 * @return The new capsule lane height
 */
export function updateCapsuleAxis(options: {
  showCapsuleLaneLabels: boolean;
  capsuleLaneHeight: number;
  items: any[];
  chart: Highcharts.Chart;
  sqTrendStoreData: TrendDataForChart;
  lanes: number[];
  isCapsuleTime: boolean;
  colors: any;
  startingTop?: number;
  labelDisplayConfiguration: LabelDisplayConfiguration;
}): number {
  const { showCapsuleLaneLabels, capsuleLaneHeight, items, chart, sqTrendStoreData, startingTop = 0 } = options;

  let nextTop = startingTop;
  const hasTopBuffer = showCapsuleLaneLabels || sqTrendStoreData.customizationMode !== CUSTOMIZATION_MODES.OFF;
  const extraTopBuffers = new Map([[-1, hasTopBuffer ? LANE_LABEL_HEIGHT : 0]]);

  const allLaneData: ConditionLaneData = new Map();
  const conditionAxisAndOffsets: ConditionAxisAndOffsets = new Map();
  const conditionsInSignalLane: AdjustableConditions = new Map();
  const conditionsToExpand: AdjustableConditions = new Map();
  const extraHeight = chartLanes.computeConditionLaneExtraHeight(options);
  const shouldExpandCapsules = sqTrendStoreData.canCapsulesExpand && extraHeight > 0;

  _.chain(items)
    .filter({ itemType: ITEM_TYPES.CAPSULE })
    .groupBy('capsuleSetId')
    .forEach((capsuleRows, capsuleSetId) => {
      const axisForCapsuleRow = _.find(chart.yAxis, {
        userOptions: { id: getCapsuleAxisId(capsuleSetId) },
      });

      if (axisForCapsuleRow) {
        const maxYValue = _.chain(capsuleRows).map('yValue').max().value();
        const minYValue = _.chain(capsuleRows).map('yValue').min().value();
        const capsuleSetLane = _.find(items, { id: capsuleRows[0]?.id })?.lane;
        if (!shouldExpandCapsules && chartLanes.hasSignalsInLane(capsuleSetLane, items)) {
          conditionsInSignalLane.set(capsuleSetId, {
            maxYValue,
            minYValue,
            lane: capsuleSetLane,
            axis: axisForCapsuleRow,
            rowOffset: 0,
          });

          return;
        }

        const lineWidth = shouldExpandCapsules
          ? DEFAULT_CAPSULE_LINE_WIDTH
          : _.chain(capsuleRows).map('lineWidth').max().value();
        const buffer = lineWidth / 2;

        const numOffsets = allLaneData.get(capsuleSetLane)?.capsulesMap.size ?? 0;
        const rowOffset = getCapsuleRowOffset(capsuleSetLane, capsuleRows, allLaneData);
        const min = minYValue - buffer;
        const max = maxYValue + buffer;
        const height = max - min;

        const hasCustomLabelInLane = options.labelDisplayConfiguration.customLabels.some(
          (label) => label.location === 'lane' && label.target === capsuleSetLane,
        );
        const laneTopBuffer = hasCustomLabelInLane ? LANE_LABEL_HEIGHT : extraTopBuffers.get(-1);
        extraTopBuffers.set(capsuleSetLane, laneTopBuffer);
        const extraTopOffset = chartLanes.extraTopOffset(rowOffset, laneTopBuffer);
        const laneData = allLaneData.get(capsuleSetLane);
        if (laneData) {
          if (laneData.capsulesMap.size > numOffsets) {
            laneData.nextOffset += height + extraTopOffset;
          } else {
            laneData.nextOffset = Math.max(laneData.nextOffset, height + extraTopOffset);
          }
        }

        if (!capsuleLaneHeight) {
          conditionAxisAndOffsets.set(capsuleSetId, { rowOffset, axis: axisForCapsuleRow, lane: capsuleSetLane });
        }

        if (shouldExpandCapsules) {
          conditionsToExpand.set(capsuleSetId, {
            maxYValue,
            minYValue,
            lane: capsuleSetLane,
            axis: axisForCapsuleRow,
            lineWidth,
            numCapsuleRows: _.size(_.uniq(_.map(capsuleRows, 'yValue'))),
            rowOffset,
          });

          return;
        }

        const adjustedConditions = chartLanes.getAdjustedConditions(capsuleSetId);
        if (adjustedConditions) {
          const uncertainCapsules = findUncertainCapsules(items, capsuleSetId);
          _.forEach(_.concat(capsuleRows, uncertainCapsules), (c) => {
            const series = _.find(chart.series, { userOptions: { id: c.id } });
            series?.update(c, false);
          });
          adjustedConditions.delete(capsuleSetId);
        }

        axisForCapsuleRow.update(
          {
            height,
            top: capsuleLaneHeight > 0 ? nextTop : undefined,
            min,
            max,
          },
          false,
        );

        if (!_.isFinite(height)) {
          throw new Error(`Capsule lane height of ${height} is not a finite number`);
        }

        nextTop += height;
      }
    })
    .value();

  chartLanes.updateConditionLaneHeights(allLaneData);

  let adjustableConditions = conditionsToExpand;
  if (!shouldExpandCapsules) {
    chartLanes.resetExtraConditionLaneHeight();
    adjustableConditions = conditionsInSignalLane;
  }
  chartLanes.adjustCapsulesLineWidth(adjustableConditions, options, extraHeight);
  chartLanes.updateConditionOffsets(conditionAxisAndOffsets, options, extraTopBuffers);

  updatePlotBands(options);

  return nextTop;
}

/**
 * Gets the y-axis range - [max, min] - for each item
 *
 * @returns an object containing the item ids as keys and the y-axis ranges as values
 */
export function getItemRanges(items: any[]): Record<string, [number, number]> {
  return _.chain(items)
    .uniqBy((item) => (item.isStringSeries ? item.id : item.interestId ?? item.id))
    .reduce((ranges, item) => {
      const itemId = item.isStringSeries ? item.id : item.interestId ?? item.id;
      ranges[itemId] = [item.yAxisMax, item.yAxisMin];

      return ranges;
    }, {})
    .value();
}
