// @ts-strict-ignore
import _ from 'lodash';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { sqUsersApi } from '@/sdk/api/UsersApi';
import { errorToast } from '@/utilities/toast.utilities';
import { EVERYONE_USERGROUP } from '@/administration/administration.constants';

/**
 * Retrieves the ACL for a given item.
 *
 * @param {String} id - the id of the item
 * @returns {Promise} that resolves with the ACL for the item.
 */
export function getItemACL(id) {
  return sqItemsApi
    .getAccessControl({ id })
    .then(({ data: { entries, permissionsInheritanceDisabled, permissionsManagedByDatasource } }) => {
      return {
        entries,
        permissionsInheritanceDisabled,
        permissionsManagedByDatasource,
      };
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
      return Promise.reject(error);
    });
}

/**
 * Retrieves the ACL Details for a given item. The ACL Details include information on  were permissions are inherited
 * from.
 *
 * @param {String} id - the id of the item
 * @param {boolean} forceInheritance - if true, the api call will be made as if the item has acl inheritance enabled
 * @returns {Promise} that resolves with the ACL Details for the item.
 */
export function getItemACLDetails(id, forceAclInheritance = false) {
  return sqItemsApi
    .getAccessControlDetails({ id, forceAclInheritance })
    .then(({ data: { entries } }) => ({ entries }))
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
      return Promise.reject(error);
    });
}

/**
 * Gets the name of the item.
 *
 * @param {string} id - the item id for which the name should be retrieved
 * @returns {Promise} - a promise that resolves with the item name
 */
export function getItemName(id) {
  return sqItemsApi
    .getProperty({ id, propertyName: 'Name' })
    .then((result) => _.get(result, 'data.value'))
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

/**
 * Determines if the user has permission to see the Everyone group.
 * @returns {Promise} - a promise that resolves with true if the Everyone group is enabled and the user has
 * permission to see it, false otherwise.
 */
export function isEveryoneAvailable() {
  return sqUsersApi
    .autocompleteUsersAndGroups({ query: EVERYONE_USERGROUP })
    .then((result) => {
      return _.chain(result)
        .get('data.items')
        .some((item) => item.name === EVERYONE_USERGROUP && item.type === 'UserGroup')
        .value();
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

/**
 * Determines if the item we are getting the ACL for is a swapped item, in which case we read/write from the source
 * item.
 *
 * @returns {Promise} - a promise that resolves with the id of the swap source if the item id is from a swap, or
 * the id unchanged if the id was not from a swap.
 */
export function getSwapSourceIdIfSwap(id) {
  return sqItemsApi
    .getProperty({ id, propertyName: 'Swap Source ID' })
    .then((result) => _.get(result, 'data.value'))
    .catch(() => id);
}
