import { ITEM_TYPES } from '@/trendData/trendData.constants';
import _ from 'lodash';
import { CAPSULE_TIME_LANE_BUFFER, LANE_BUFFER } from '@/trend/trendViewer/trendViewer.constants';
import {
  AdjustableConditions,
  ChartLaneOptions,
  ConditionAxisAndOffsets,
  ConditionLaneData,
} from '@/utilities/chart.types';
import { CAPSULE_TYPES } from '@/chart/chart.constants';
import tinycolor from 'tinycolor2';
import { getCertainId } from '@/utilities/utilities';

interface LaneValues {
  offset: number;
  height: number;
  buffer: number;
}

const EXPAND_THRESHOLD = 7;

/**
 * A class to manage most lane operations
 */
class ChartLanes {
  conditionLaneHeights = new Map<number, { height: number }>();
  totalConditionLaneHeight = 0;
  private _extraConditionLaneHeight = 0;
  private allLaneData: ConditionLaneData = new Map();

  /** Conditions with capsule lineWidths adjusted to fill signal lane */
  conditionsInSignalLane: AdjustableConditions = new Map();
  /** Conditions with capsule lineWidths adjusted to fill chart */
  expandedConditions: AdjustableConditions = new Map();

  get extraConditionLaneHeight() {
    return this._extraConditionLaneHeight;
  }

  updateConditionLaneHeights = (allLaneData: ConditionLaneData): void => {
    this.totalConditionLaneHeight = 0;
    this.conditionLaneHeights = new Map();
    this.allLaneData = allLaneData;
    allLaneData.forEach((laneData, lane) => {
      this.conditionLaneHeights.set(lane, { height: laneData.nextOffset });
      this.totalConditionLaneHeight += laneData.nextOffset;
    });
  };

  /**
   * Get fixed lane height for signals
   */
  getSignalLaneHeight = ({ items, chart, capsuleLaneHeight, isCapsuleTime }: ChartLaneOptions): number => {
    const lanes = this.getDisplayedLanes(items);
    const numberOfSignalLanes = this.getDisplayedLanes(items, true).length;
    const displayHeight = this.getChartSeriesDisplayHeight({ chart, capsuleLaneHeight });

    if (numberOfSignalLanes > 0) {
      const extraBuffer = this.hasSignalsInLane(lanes[lanes.length - 1], items) ? 1 : 0;
      const laneBuffersHeight = (numberOfSignalLanes - extraBuffer) * this.getLaneBuffer(isCapsuleTime);

      return (displayHeight - laneBuffersHeight - this.totalConditionLaneHeight) / numberOfSignalLanes;
    }

    return displayHeight - this.totalConditionLaneHeight;
  };

  /**
   * Calculates the y-offset and height for each lane
   */
  computeLaneValues = (lane: number, options: ChartLaneOptions): LaneValues => {
    const { items, isCapsuleTime, capsuleLaneHeight } = options;
    const buffer = this.getLaneBuffer(isCapsuleTime);

    let offset = 0;
    for (let i = 1; i < lane; i++) {
      const conditionLaneHeight = this.conditionLaneHeights.get(i)?.height;
      if (conditionLaneHeight) {
        offset += conditionLaneHeight + this._extraConditionLaneHeight;
      }
    }

    const signalLaneHeight = this.getSignalLaneHeight(options);
    const signalLanes = this.getDisplayedLanes(items, true);
    _.some(signalLanes, (currentLane) => {
      if (currentLane >= lane) {
        return true;
      }

      offset += signalLaneHeight + buffer;
    });

    offset += capsuleLaneHeight;
    const conditionLane = this.conditionLaneHeights.get(lane);
    const conditionLaneHeight = conditionLane ? conditionLane.height + this._extraConditionLaneHeight : null;

    return { offset, height: conditionLaneHeight ?? signalLaneHeight, buffer };
  };

  extraTopOffset = (rowOffset: number, extraTopBuffer: number) => (rowOffset > 0 ? 0 : extraTopBuffer);

  extraRowOffset = (lane: number, rowOffset: number) => {
    const laneData = this.allLaneData.get(lane);
    if (laneData) {
      return (laneData.rowExtraHeight ?? 0) * [...laneData.capsulesMap.keys()].indexOf(rowOffset);
    }

    return 0;
  };

  updateConditionOffsets = (
    conditionAxisAndOffsets: ConditionAxisAndOffsets,
    options: ChartLaneOptions,
    extraTopBuffers: Map<number, number>,
  ): void => {
    conditionAxisAndOffsets.forEach(({ axis, rowOffset, lane }) => {
      const { offset } = chartLanes.computeLaneValues(lane, options);
      const top =
        offset +
        rowOffset +
        this.extraTopOffset(rowOffset, extraTopBuffers.get(lane)!) +
        this.extraRowOffset(lane, rowOffset);

      axis.update({ top }, false);
    });
  };

  hasNoSignal = (items: any[]): boolean =>
    !_.chain(items)
      .some(({ itemType }) => itemType === ITEM_TYPES.SERIES || itemType === ITEM_TYPES.SCALAR)
      .value();

  computeConditionLaneExtraHeight = (options: ChartLaneOptions) => {
    const { items, chart } = options;
    if (this.hasNoSignal(items)) {
      const heightDelta = chart.plotHeight - this.totalConditionLaneHeight;
      if (heightDelta < EXPAND_THRESHOLD) {
        return 0;
      }

      return heightDelta / this.getDisplayedLanes(items).length;
    }

    return 0;
  };

  private computeExtraCapsuleHeight = (lane: number, extraHeight: number, numCapsuleRows: number): number => {
    let numOffsets = 0;
    this.allLaneData.get(lane)?.capsulesMap.forEach((capsules) => {
      numOffsets += _.uniqBy(capsules, 'yValue').length;
    });

    return extraHeight / Math.max(numOffsets, numCapsuleRows);
  };

  updateAdjustableConditions = (conditions: AdjustableConditions, shouldExpandCapsules: boolean): void => {
    if (shouldExpandCapsules) {
      this.conditionsInSignalLane = conditions;
    } else {
      this.expandedConditions = conditions;
    }
  };

  /**
   * Returns extra condition lane height such that the total condition lane height
   * is below half of the chart height
   */
  restrictExtraConditionLaneHeight = (
    extraHeight: number,
    conditions: AdjustableConditions,
    options: ChartLaneOptions,
  ): number => {
    if (conditions.size > 1 || !extraHeight) {
      return extraHeight;
    }

    const [firstCondition] = conditions;
    const lane = firstCondition[1].lane;
    const halfChartHeight = this.getChartSeriesDisplayHeight(options) / 2;
    const currentLaneHeight = this.conditionLaneHeights.get(lane)?.height ?? 0;
    if (currentLaneHeight >= halfChartHeight) {
      return 0;
    }

    const newLaneHeight = currentLaneHeight + extraHeight;
    if (newLaneHeight <= halfChartHeight) {
      return extraHeight;
    }

    return newLaneHeight - halfChartHeight;
  };

  adjustSeriesData = (series: Highcharts.Series, yAdjustmentFunction: (yValue: number) => number) =>
    _.map(_.get(series, 'userOptions.data') ?? [], (d) =>
      _.has(d, 'x') && _.has(d, 'y') ? { ...d, x: d.x, y: yAdjustmentFunction(d.y) } : d,
    );

  adjustCapsulesLineWidth = (conditions: AdjustableConditions, options: ChartLaneOptions, extraHeight = 0): void => {
    if (conditions.size === 0) {
      return;
    }

    const { chart } = options;
    const restrictedExtraHeight = this.restrictExtraConditionLaneHeight(extraHeight, conditions, options);
    const shouldExpandCapsules = restrictedExtraHeight > 0;

    this.updateAdjustableConditions(conditions, shouldExpandCapsules);

    let capsuleLineWidth = shouldExpandCapsules ? 0 : this.getSignalLaneHeight(options);
    conditions.forEach(
      ({ maxYValue, minYValue, lane, axis, rowOffset, lineWidth = 1, numCapsuleRows = 1 }, capsuleSetId) => {
        let extraCapsuleHeight = 0;
        if (shouldExpandCapsules) {
          extraCapsuleHeight = this.computeExtraCapsuleHeight(lane, restrictedExtraHeight, numCapsuleRows);
          capsuleLineWidth = lineWidth + extraCapsuleHeight;
          const laneData = this.allLaneData.get(lane);
          if (laneData) {
            laneData.rowExtraHeight = extraCapsuleHeight;
          }
          this._extraConditionLaneHeight = restrictedExtraHeight;
        }
        const allSeries = _.filter(
          chart.series,
          (s) => getCertainId(_.get(s, 'userOptions.capsuleSetId')) === capsuleSetId,
        );
        let extraRangeAdjustment = 0;
        const laneCapsules = Array.from(this.allLaneData.get(lane)?.capsulesMap ?? []);
        for (let i = 0; i < laneCapsules.length; i++) {
          const [offset, capsules] = laneCapsules[i];
          if (offset === rowOffset) {
            break;
          }

          extraRangeAdjustment += extraCapsuleHeight * (_.uniqBy(capsules, 'yValue').length - 1);
        }
        const buffer = capsuleLineWidth / 2;
        const min = minYValue - buffer - extraRangeAdjustment;
        const max = maxYValue + buffer + extraRangeAdjustment + extraCapsuleHeight * (numCapsuleRows - 1);
        const height = max - min;
        const { offset } = this.computeLaneValues(lane, options);

        axis.update(
          {
            height,
            top: offset,
            min,
            max,
          },
          false,
        );

        _.chain(allSeries)
          .groupBy('yData[0]')
          .values()
          .sortBy('[0].yData[0]')
          .forEach((seriesArray, index) => {
            _.forEach(seriesArray, (series) => {
              const seriesOptions = {
                lineWidth:
                  series.userOptions?.itemType === ITEM_TYPES.CAPSULE ? capsuleLineWidth : 0.9 * capsuleLineWidth,
              } as Highcharts.SeriesOptionsType;

              if (extraCapsuleHeight) {
                if (allSeries.length > 1) {
                  const newData = this.adjustSeriesData(series, (yValue) =>
                    yValue > 1 ? +index * capsuleLineWidth : yValue,
                  );
                  series.setData(newData, false, false, false);
                }
              } else if (!shouldExpandCapsules) {
                seriesOptions.color = tinycolor(_.get(series, 'color')).setAlpha(0.3).toString();

                if (allSeries.length > 1) {
                  const newData = this.adjustSeriesData(series, (yValue) => (!yValue ? yValue : 1));
                  series.setData(newData, false, false, false);
                }
              } else if (_.get(series, 'userOptions.dim')) {
                seriesOptions.color = tinycolor(_.get(series, 'color')).setAlpha(0.2).toString();
              }

              series.update(seriesOptions, false);
            });
          })
          .value();
      },
    );
  };

  getAdjustedConditions = (capsuleSetId: string): AdjustableConditions | undefined => {
    if (this.conditionsInSignalLane.has(capsuleSetId)) {
      return this.conditionsInSignalLane;
    }

    if (this.expandedConditions.has(capsuleSetId)) {
      return this.expandedConditions;
    }
  };

  getLaneFromYPos = (yPos: number, options: ChartLaneOptions): number => {
    const { items, isCapsuleTime } = options;
    let currentPos = 0;
    const lanes = this.getDisplayedLanes(items);
    const signalLaneHeight = this.getSignalLaneHeight(options);
    const laneBuffer = this.getLaneBuffer(isCapsuleTime);
    for (let i = 0; i < lanes.length; i++) {
      const lane = lanes[i];
      const conditionLaneHeight = this.conditionLaneHeights.get(lane)?.height;
      currentPos += conditionLaneHeight ? conditionLaneHeight + this.extraConditionLaneHeight : signalLaneHeight;
      if (currentPos > yPos) {
        return lane;
      }

      currentPos += this.conditionLaneHeights.has(lane) ? 0 : laneBuffer;
    }

    return lanes[0];
  };

  resetExtraConditionLaneHeight = () => {
    this._extraConditionLaneHeight = 0;
    this.allLaneData.forEach((laneData) => {
      if (laneData.rowExtraHeight) {
        laneData.rowExtraHeight = 0;
      }
    });
  };

  /**
   * Tiny helper function to determine the correct lane buffer to use
   *
   * @param {Boolean} isCapsuleTime - Flag indicating if capsule time is displayed
   */
  getLaneBuffer = (isCapsuleTime: boolean) => (isCapsuleTime ? CAPSULE_TIME_LANE_BUFFER : LANE_BUFFER);

  hasSignalsInLane = (lane: number | undefined, items: any[]): boolean => {
    if (!lane) {
      return false;
    }

    return _.chain(items)
      .filter({ lane })
      .some(({ itemType }) => itemType === ITEM_TYPES.SERIES || itemType === ITEM_TYPES.SCALAR)
      .value();
  };

  getDisplayedLanes = (items: any[], excludeCapsules = false): number[] =>
    _.chain(items)
      .reject(({ itemType }) => excludeCapsules && _.includes([...CAPSULE_TYPES, ITEM_TYPES.CAPSULE_SET], itemType))
      .map('lane')
      .compact()
      .uniq()
      .sortBy()
      .value();

  getNumberOfDisplayedLanes = (items: any[]) => this.getDisplayedLanes(items).length;

  /**
   * Tiny helper to return the appropriate height for everything that should not overlap the capsule lane.
   *
   * @returns the height in pixel that is available
   */
  getChartSeriesDisplayHeight = (options: Pick<ChartLaneOptions, 'chart' | 'capsuleLaneHeight'>): number =>
    options.chart.plotHeight - options.capsuleLaneHeight;
}

export const chartLanes = new ChartLanes();
