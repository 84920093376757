// @ts-strict-ignore
import _ from 'lodash';
import Highcharts from 'other_components/highcharts';
import { Axis } from 'highcharts';
import { KEY_CODES } from '@/main/app.constants';
import { pointInRectangle } from '@/utilities/utilities';

export interface AxisControlConfig {
  x?: {
    getExtremes?: (axis) => AxisExtremes;
    updateExtremes: (extremesChanges: AxisExtremeChange[]) => void;
    getAxesUnderCursor?: (chartX: number, chartY: number) => Axis[];
  };
  y?: {
    getExtremes?: (axis) => AxisExtremes;
    updateExtremes: (extremesChanges: AxisExtremeChange[]) => void;
    getAxesUnderCursor?: (chartX: number, chartY: number) => Axis[];
  };
}

export interface AxisExtremes {
  min: number;
  max: number;
  axisAlign?: string; // this is used by the trend view to track a y-axis' horizontal position
}

export type AxisExtremeChange = {
  axis;
  oldExtremes: AxisExtremes;
  changeInLow: number;
  changeInHigh: number;
};

export type Rectangle = {
  x: number;
  y: number;
  width: number;
  height: number;
};

/**
 * A class is used in order to easily manage the states of x and y axes values.
 *
 * Manages the drag and zoom operations for all axes on a chart.
 */
class AxisControl {
  xAxesController: AxisGroup;
  yAxesController: AxisGroup;

  constructor(config: AxisControlConfig) {
    config = _.defaultsDeep(config, {
      x: {
        getExtremes: (axis) => axis.getExtremes(),
        getAxesUnderCursor: this.getAxesUnderCursor(this.computeXAxisScaleRectangle, () => this.chart.xAxis),
      },
      y: {
        getExtremes: (axis) => axis.getExtremes(),
        getAxesUnderCursor: this.getAxesUnderCursor(this.computeYAxisScaleRectangle, () => this.chart.yAxis),
      },
    });

    this.xAxesController = new AxisGroup(
      0.2,
      config.x.getExtremes,
      config.x.updateExtremes,
      (e) => e.chartX,
      config.x.getAxesUnderCursor,
    );

    this.yAxesController = new AxisGroup(
      0.1,
      config.y.getExtremes,
      config.y.updateExtremes,
      (e) => e.chartY,
      config.y.getAxesUnderCursor,
    );
  }

  // chart dom elements, stored on load
  chart;
  getChartElement: () => any;
  chartContainer;

  // currentlyDragging denotes which axis the action was started from
  currentlyDragging: AxisGroup;

  throttledMouseMove = _.throttle((e) => this.mouseMove(e), 100);
  throttledMouseWheel = _.throttle((e) => this.mouseWheel(e), 75);

  /**
   * Default axis lookup for axis groups that have all axes occupying the same screen space (i.e. no lanes)
   * @param computeAxisRectangle - Retrieves the current pixel location of the axes' bounding rectangle
   * @param getAllAxes - Retrieves all axes associated with this bounding rectangle
   */
  getAxesUnderCursor = (computeAxisRectangle: () => Rectangle, getAllAxes: () => Axis[]) => {
    return (x, y) => {
      const rect = computeAxisRectangle();

      return pointInRectangle(x, y, rect.x, rect.y, rect.x + rect.width, rect.y + rect.height) ? getAllAxes() : [];
    };
  };

  /**
   * Flags the drag operation as started and records the mouse position and chart value at that position.
   * If axes are selected, startDrag is only initiated on the selected axes.
   *
   * @param {Object} e - An event object
   */
  startDrag = (e) => {
    // only start the drag operation if the drag happens on one of the axis
    if (this.xAxesController.isActive()) {
      this.currentlyDragging = this.xAxesController;
      this.xAxesController.mouseDown(this.chart.pointer.normalize(e));
    }

    if (this.yAxesController.isActive()) {
      this.currentlyDragging = this.yAxesController;
      this.yAxesController.mouseDown(this.chart.pointer.normalize(e));
    }
  };

  /**
   * Force the application cursor to the NorthSouth cursor
   */
  forceCursorNorthSouth = () => {
    document.querySelector('body').classList.add('globalCursorNorthSouth');
  };

  /**
   * Force the application cursor to the EastWest cursor
   */
  forceCursorEastWest = () => {
    document.querySelector('body').classList.add('globalCursorEastWest');
  };

  /**
   * Clear the forced NorthSouth application cursor
   */
  clearCursorNorthSouth = () => {
    document.querySelector('body').classList.remove('globalCursorNorthSouth');
  };

  /**
   * Clear the forced EastWest application cursor
   */
  clearCursorEastWest = () => {
    document.querySelector('body').classList.remove('globalCursorEastWest');
  };

  /**
   * Computes the height of the x-axis scale region
   *
   * @returns {Number} height of the x-axis scale region
   */
  getXAxisScaleRectangleHeight = () => {
    let xAxisLabels;
    const X_AXIS_PADDING_TOP = 20;
    if (this.chart && this.chart.xAxis && this.chart.xAxis.length && this.chart.xAxis[0].labelGroup) {
      xAxisLabels = this.chart.xAxis[0].labelGroup.element.children;

      // Ensure that we have xAxisLabels (we don't in unit tests) and ensure that they have an offset height
      // (they don't have one in Firefox)
      if (xAxisLabels && xAxisLabels.length > 0 && xAxisLabels[0].getBoundingClientRect().height) {
        return xAxisLabels[0].getBoundingClientRect().height + X_AXIS_PADDING_TOP;
      }
    }
  };

  /**
   * Computes the position and size of the x axis scale region that allows scroll and zoom.
   *
   * @return {Object} Returns an object with x, y, width and height properties.
   */
  computeXAxisScaleRectangle = () => {
    let elementBasedHeight;
    const rect = {
      x: 0,
      y: 0,
      width: 0,
      height: 40,
    };

    elementBasedHeight = this.getXAxisScaleRectangleHeight();
    rect.height = elementBasedHeight ? elementBasedHeight : rect.height;
    rect.width = this.chart.plotWidth;
    rect.x = this.chart.plotLeft;
    rect.y = this.chart.chartHeight - this.chart.marginBottom;

    return rect;
  };

  /**
   * Computes the position and size of the y axis scale region that allows scroll and zoom.
   *
   * @return {Object} Returns an object with x, y, width and height properties.
   */
  computeYAxisScaleRectangle = () => {
    const labelOffset = this.chart.yAxis[0].labelOffset;
    const widthMultiplier = this.chart.yAxis.length > 1 ? 4 : 3;
    const width = labelOffset * this.chart.yAxis.length * widthMultiplier;
    return {
      x: this.chart.yAxis[0].axisTitleMargin - labelOffset,
      y: 0,
      width,
      height: this.chart.plotHeight,
    };
  };

  /**
   * Updates the cursor to use either the standard, north-south, or east-west cursor, depending on which axes we're
   * currently hovering over (if any), or if we're dragging (cursor type is fixed during a drag until you let go).
   */
  updateCursors = () => {
    if (this.xAxesController.isActive() && this.currentlyDragging !== this.yAxesController) {
      this.forceCursorEastWest();
    }

    if (!(this.xAxesController.isActive() || this.currentlyDragging === this.xAxesController)) {
      this.clearCursorEastWest();
    }

    if (this.yAxesController.isActive() && this.currentlyDragging !== this.xAxesController) {
      this.forceCursorNorthSouth();
    }

    if (!(this.yAxesController.isActive() || this.currentlyDragging === this.yAxesController)) {
      this.clearCursorNorthSouth();
    }
  };

  /**
   * Updates the axis groups with the current cursor position, updates the cursor display, and performs a drag
   * if the mouse button is held down.
   * @param e
   */
  mouseMove = (e) => {
    const pointer = this.chart.pointer.normalize(e);
    if (!this.isDragInProgress()) {
      this.xAxesController.updatePointer(pointer);
      this.yAxesController.updatePointer(pointer);
    }

    this.updateCursors();

    if (!this.isDragInProgress()) {
      return;
    }

    this.xAxesController.tryDragAxes(pointer);
    this.yAxesController.tryDragAxes(pointer);
  };

  /**
   * Starts a drag operation.
   * Shifts the mouseMove listener from the chart to the entire page (so you can move off the chart while dragging).
   * @param e - mouseDown event
   */
  mouseDown = (e) => {
    // Listen for drag events on the window between mouse down and mouse up so that the mouse does not have to
    // stay over the axis while scrolling
    // Turn off chart listener while listening globally
    this.chartContainer.removeEventListener('mousemove', this.throttledMouseMove);
    document.documentElement.addEventListener('mousemove', this.throttledMouseMove);
    document.documentElement.addEventListener('mouseup', this.mouseUp);
    this.startDrag(e);
  };

  /**
   * Stops any drag operation in progress. Resets the mouse listener changes made for the drag. Updates the
   * cursors, because we may still be showing a north-south or east-west cursor but not be over an axis any more.
   * @param e - mouseUp event
   */
  mouseUp = (e) => {
    this.throttledMouseMove.cancel();
    const pointer = this.chart.pointer.normalize(e);
    this.xAxesController.updatePointer(pointer);
    this.yAxesController.updatePointer(pointer);

    this.currentlyDragging = undefined;

    // Turn off the listeners so that we don't incur an unnecessary performance hit
    // Turn on chart listener when no longer listening globally
    this.chartContainer.addEventListener('mousemove', this.throttledMouseMove);
    document.documentElement.removeEventListener('mousemove', this.throttledMouseMove);
    document.documentElement.removeEventListener('mouseup', this.mouseUp);

    this.updateCursors();
  };

  /**
   * If we're not dragging, clear the axis groups' "active axes" and update the cursor.
   */
  mouseLeave = () => {
    if (!this.isDragInProgress()) {
      this.xAxesController.clearAxes();
      this.yAxesController.clearAxes();

      // Cancel any pending mousemove events which could re-enable the cursor arrows since they are delayed and
      // use a mouse-position based off their event argument
      this.throttledMouseMove.cancel();
      this.throttledMouseWheel.cancel();
    }

    this.updateCursors();
  };

  /**
   * Zooms the axes in or out if we're hovering over them.
   * @param e - mouseWheel event
   */
  mouseWheel = (e) => {
    const pointer = this.chart.pointer.normalize(e);
    const wheelDelta = e.deltaY;
    const zoomApplied =
      this.yAxesController.tryZoomAxes(pointer, wheelDelta) || this.xAxesController.tryZoomAxes(pointer, wheelDelta);

    // If zoom was applied, then the mouse was over either the x axis or y axis
    // when the mouse wheel event occurred, so we don't want the default scroll action.
    if (zoomApplied) {
      e.preventDefault();
    }
  };

  onContextMenu = () => {
    // trigger mouseUp to prevent selection initiation:
    this.triggerMouseUp();
  };

  keyDown = (e) => {
    if (e.keyCode === KEY_CODES.ESCAPE) {
      this.triggerMouseUp();
    }
  };

  /**
   * Triggers a mouseUp Event on the Chart.
   */
  triggerMouseUp = () => {
    const chartElement = this.getChartElement();
    const chartDiv = chartElement !== null && chartElement.length === 1 ? chartElement[0] : null;

    if (chartDiv !== null) {
      (Highcharts as any).fireEvent(chartDiv, 'mouseup');
    }
  };

  /**
   * Activate scroll and zoom functionality on the trend.
   * If axes are selected, only the selected axes are activated.
   *
   * @param {Object} chart - A reference to the trend's Highcharts chart.
   * @return {Function} deactivateScrollZoom - Call the returned function prior to destroying the
   *   chart to ensure DOM events are unregistered.
   */
  activateScrollZoom = (chart, chartElement) => {
    // Store DOM elements
    this.chart = chart;
    this.getChartElement = () => chartElement;
    this.chartContainer = chart.container;

    // Bind to required DOM events on the chart
    this.chartContainer.addEventListener('mousedown', this.mouseDown);
    this.chartContainer.addEventListener('mouseup', this.mouseUp);

    // Throttle mouse move events to reduce pressure on application when dragging
    // the axes. This is especially important for Internet Explorer, which does not
    // perform as well and other browsers.
    this.chartContainer.addEventListener('mousemove', this.throttledMouseMove);
    this.chartContainer.addEventListener('mouseleave', this.mouseLeave);
    this.chartContainer.addEventListener('wheel', this.throttledMouseWheel);
    this.chartContainer.addEventListener('contextmenu', this.onContextMenu);

    // attach keydown Event to body or it won't fire
    document.querySelector('body').addEventListener('keydown', this.keyDown);

    // Return a function that can be used to unbind from chart DOM events
    return () => {
      this.chartContainer.removeEventListener('mousedown', this.mouseDown);
      this.chartContainer.removeEventListener('mouseup', this.mouseUp);
      this.chartContainer.removeEventListener('mousemove', this.throttledMouseMove);
      this.chartContainer.removeEventListener('mouseleave', this.mouseLeave);
      this.chartContainer.removeEventListener('wheel', this.throttledMouseWheel);
      this.chartContainer.removeEventListener('contextmenu', this.onContextMenu);
      document.querySelector('body').removeEventListener('keydown', this.keyDown);
    };
  };

  /**
   * Returns whether an axis is currently being dragged
   */
  isDragInProgress = () => {
    return !_.isUndefined(this.currentlyDragging);
  };
}

/**
 * Handles zoom and drag operations for a set of axes that run in the same direction (i.e. all x-axes or all y-axes)
 */
class AxisGroup {
  private onAxes = [];

  isActive = () => this.onAxes.length > 0;
  clearAxes = () => {
    this.onAxes = [];
  };

  /**
   * Builds a controller for a group of axes that all extend in the same direction.
   * @param {number} scrollZoomFactor - How much the axes should be scrolled in or out each time the mouse wheel is
   * moved. A scrollZoomFactor of 0.2 means that after a single zoom in or zoom out the axis range will be 20%
   * smaller or larger.
   * @param getExtremes - Lookup for the AxisExtremes of a given axis in this group.
   * @param updateExtremes - Callback to update the extremes of a set of axes, called after a zoom or drag action.
   * @param getPointerEventCoordinate - Gets the coordinate (chartX or chartY) from a Highcharts PointerEvent that
   * should be used for axes in this group. This is the only thing necessary to distinguish between x and y axes.
   * @param getAxesUnderCursor Lookup for the axes in this group that lie beneath a given (X,Y) coordinate.
   */
  constructor(
    private scrollZoomFactor: number,
    private getExtremes: (axis) => AxisExtremes,
    private updateExtremes: (newExtremes: AxisExtremeChange[]) => void,
    private getPointerEventCoordinate: (pointerEvent) => number,
    private getAxesUnderCursor: (x: number, y: number) => Axis[],
  ) {}

  /**
   * Tries dragging all axes currently part of a drag operation to the current cursor position. Does nothing if a
   * drag has not already been started by calling mouseDown() while the cursor is hovering over one or more axes.
   * @param pointer
   */
  tryDragAxes = (pointer: { chartY: number; chartX: number }) => {
    if (this.isActive()) {
      const dragPixels = this.getPointerEventCoordinate(pointer);
      const newExtremes = _.map(this.onAxes, (axis) => {
        const dragValue = axis.toValue(dragPixels);
        const changeInValue = dragValue - axis.userOptions.mouseDownValue;
        return {
          axis,
          oldExtremes: this.getExtremes(axis),
          changeInLow: -changeInValue, // Dragging up makes the new min and max lower
          changeInHigh: -changeInValue,
        };
      });
      this.updateExtremes(newExtremes);
    }
  };

  /**
   * If hovering over one or more axes, zooms them one step in or out.
   * @param {PointerEvent} pointer - Current pointer position, used to decide where on the axis to focus the zoom.
   * @param {number} wheelDelta - The direction and distance the wheel was spun. Used to decide whether to zoom in
   * or out. The actual magnitude is ignored.
   * @return true if a zoom was performed, false otherwise
   */
  tryZoomAxes = (pointer: PointerEvent, wheelDelta: number) => {
    if (!this.isActive()) {
      return false;
    }
    const mousePixels = this.getPointerEventCoordinate(pointer);
    // Determines if we're zooming in or out
    const unitDelta = wheelDelta === 0 ? 0 : Math.abs(wheelDelta) / wheelDelta;

    const newExtremes = _.chain(this.onAxes)
      .map((axis) => {
        // The current range of trend
        const oldExtremes = this.getExtremes(axis);
        const min = oldExtremes.min;
        const max = oldExtremes.max;

        // Compute scale factor based on where the mouse is located so the zoom centers on the mouse position
        const mouseValue = axis.toValue(mousePixels);
        const lowFactor = min - mouseValue;
        const highFactor = max - mouseValue;

        const changeInLow = this.scrollZoomFactor * lowFactor * unitDelta;
        const changeInHigh = this.scrollZoomFactor * highFactor * unitDelta;

        return {
          axis,
          oldExtremes,
          changeInLow,
          changeInHigh,
        };
      })
      .value();

    this.updateExtremes(newExtremes);
    return true;
  };

  /**
   * Checks which axes (if any) are under the pointer's current position. This should only be called when the
   * "active" axes should actually be updated (i.e. not in the middle of a drag operation).
   * @param pointer
   */
  updatePointer = (pointer: { chartY: number; chartX: number }) => {
    this.onAxes = pointer ? this.getAxesUnderCursor(pointer.chartX, pointer.chartY) : [];
  };

  /**
   * Notes the position on each axis where the mouse button was pressed down, so that when we drag we know what
   * the starting point was.
   * @param pointer
   */
  mouseDown = (pointer: { chartY: number; chartX: number }) => {
    const downPixels = this.getPointerEventCoordinate(pointer);
    _.forEach(this.onAxes, (axis) => axis.update({ mouseDownValue: axis.toValue(downPixels) }));
  };
}

export const createAxisControl = (config: AxisControlConfig = {}) => {
  return new AxisControl(config);
};
