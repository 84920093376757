import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { SEARCH } from '@/tools/formula/formulaTool.constants';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqFormulaToolStore } from '@/core/core.stores';
import { useTranslation } from 'react-i18next';

interface FormulaDocumentationHeaderProps {
  backEnabled: boolean;
  functionFilter?: string;
  clearHelpText: (searchText: string) => void;
  goBack: () => void;
  goHome: () => void;
  loadFullList: () => void;
}

export const FormulaDocumentationHeader: React.FunctionComponent<FormulaDocumentationHeaderProps> = ({
  backEnabled,
  functionFilter,
  clearHelpText,
  goBack,
  goHome,
  loadFullList,
}) => {
  const { t } = useTranslation();
  const [canGoBack, setCanGoBack] = useState(backEnabled);
  const navigationStack = useFluxPath(sqFormulaToolStore, () => sqFormulaToolStore.navigationStack);

  useEffect(() => {
    setCanGoBack(
      backEnabled &&
        !(navigationStack.length === 1 && _.first(navigationStack) === SEARCH) &&
        navigationStack.length > 0,
    );
  }, [backEnabled, navigationStack]);

  return (
    <div className="flexColumnContainer flexSpaceBetween flexNoGrowNoShrink flexCenter" data-testid="formulaDocsHeader">
      <Icon
        icon="fa-chevron-left"
        tooltip="Previous help"
        tooltipPlacement="top"
        type={canGoBack ? 'theme' : 'gray'}
        large={true}
        extraClassNames="pr10"
        onClick={canGoBack ? goBack : _.noop}
        testId="goBackIcon"
      />

      <Icon
        icon="fa-house-chimney"
        tooltip="Return to the introduction page"
        tooltipPlacement="top"
        large={true}
        extraClassNames="pr10 cursorPointer sq-text-primary"
        onClick={goHome}
        testId="goHomeIcon"
      />
      <div>
        <Icon
          icon="fa-list"
          tooltip="View a list of all functions"
          tooltipPlacement="top"
          large={true}
          extraClassNames="pr10 cursorPointer sq-text-primary"
          onClick={loadFullList}
          testId="fullListIcon"
        />
      </div>

      <FormControl
        autoComplete="off"
        type="text"
        placeholder="Search documentation"
        value={functionFilter ?? ''}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
        onChange={(e) => clearHelpText(e.target.value)}
      />
    </div>
  );
};
