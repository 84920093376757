import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { batchAddAssets } from '@/assetGroupEditor/assetGroup.actions';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { useTranslation } from 'react-i18next';
import { useDidUpdate } from 'rooks';
import { useTogglingSelectAll } from '@/search/hooks/useTogglingSelectAll.hook';
import { ToggleSelectAll } from '@/search/multiSelect/ToggleSelectAll.molecule';
import { SelectAndAdd } from './SelectAndAdd.molecule';
import { isAsset } from '@/utilities/utilities';

import { sqAssetGroupStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { SearchPanes } from '@/search/search.constants';
import { getStoreForPane, toggleSelectAllItems } from '@/search/search.actions';

interface AssetGroupMultiSelectProps {
  pane: SearchPanes;
  items: any[];
}

/**
 * Manages multi-select for asset-group
 */
export const AssetGroupMultiSelect: React.FunctionComponent<AssetGroupMultiSelectProps> = ({ pane, items }) => {
  const { t } = useTranslation();

  const store = getStoreForPane(pane);
  const selectAllChecked = useFluxPath(store, () => store.selectAllChecked);
  const selectedItemIds = useFluxPath(store, () => store.selectedItemIds);
  const assets = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.assets);

  const [isAssetAndItemSelected, isOnlyAssetsSelected] = useMemo(() => {
    if (!items.length || !selectedItemIds.length) return [false, null];

    const firstSelectedItem = items?.find((item) => item.id === selectedItemIds[0]);
    const curr = isAsset(firstSelectedItem) || firstSelectedItem.hasChildren ? 1 : 0;
    for (let i = 0; i < selectedItemIds.length; i++) {
      const item = items?.find((item) => item.id === selectedItemIds[i]);
      const newCurr = isAsset(item) || item?.hasChildren ? 1 : 0;
      if (curr !== newCurr) {
        return [true, null];
      }
    }

    return [false, curr === 1];
  }, [selectedItemIds, items]);

  const [selectActionOptions, setSelectActionOptions] = useState([
    { value: 'none', label: t('MULTI_SELECT.ASSET_GROUP.NONE') },
  ]);

  const { togglingSelectAll, setTogglingSelectAll } = useTogglingSelectAll({
    items,
  });
  const [selectedValue, setSelectedValue] = useState(selectActionOptions[0]);

  useEffect(() => {
    switch (isOnlyAssetsSelected) {
      case true: {
        const options = [{ value: 'addAll', label: t('MULTI_SELECT.ASSET_GROUP.ADD_ALL') }];
        if (assets?.length > 0) {
          options.push({
            value: 'addMatching',
            label: t('MULTI_SELECT.ASSET_GROUP.ADD_MATCHING'),
          });
        }
        setSelectActionOptions(options);
        break;
      }

      case false:
        setSelectActionOptions([
          {
            value: 'addAll',
            label: t('MULTI_SELECT.ASSET_GROUP.ADD_ALL_ITEMS'),
          },
        ]);
        break;

      default:
        setSelectActionOptions([{ value: 'none', label: t('MULTI_SELECT.ASSET_GROUP.NONE') }]);
        break;
    }
  }, [assets?.length, isOnlyAssetsSelected]);

  useDidUpdate(() => {
    const isSelectedValueAnOption =
      selectActionOptions.findIndex((option) => option.value === selectedValue.value) !== -1;
    if (!isSelectedValueAnOption) {
      setSelectedValue(selectActionOptions[0]);
    }
  }, [selectActionOptions]);

  return (
    <div className="flexColumnContainer flexAlignCenter" data-testid="selectActions">
      <ToggleSelectAll
        togglingSelectAll={togglingSelectAll}
        isChecked={selectAllChecked}
        onClick={() => {
          setTogglingSelectAll(true);
          toggleSelectAllItems(items);
        }}
      />
      <SelectAndAdd
        selectedItemIds={selectedItemIds}
        options={selectActionOptions}
        onChange={setSelectedValue}
        selectedValue={selectedValue}
        onAddClick={() => {
          if (_.get(selectedValue, 'value', '') === 'addAll') {
            batchAddAssets({ items, assetIds: selectedItemIds });

            doTrack('Multi-Select', 'Add all items or all items under assets');
          } else {
            batchAddAssets({
              items,
              assetIds: selectedItemIds,
              matchingOnly: true,
            });
            doTrack('Multi-Select', 'Add only matching items');
          }
        }}
        isAssetAndItemSelected={isAssetAndItemSelected}
      />
    </div>
  );
};
