import { Modal, TextField } from '@seeqdev/qomponents';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdentityOption, SelectIdentity } from '@/core/SelectIdentity.molecule';

interface AiAssistantShareProps {
  selectedChatName: string;
  onClose: () => void;
  shareChat: (name: string, userIds: string[]) => void;
}

export const AiAssistantShare: React.FunctionComponent<AiAssistantShareProps> = ({
  selectedChatName,
  onClose,
  shareChat,
}) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<IdentityOption[]>([]);
  const [chatName, setChatName] = useState(selectedChatName);

  useEffect(() => {
    setChatName(selectedChatName);
  }, [selectedChatName]);

  const handleClose = () => {
    onClose();
    setSelectedUsers([]);
  };

  return (
    <Modal
      onClose={handleClose}
      onSubmit={() => {
        shareChat(
          chatName,
          selectedUsers.map((selectedUser) => selectedUser.id),
        );
        handleClose();
      }}
      disableSubmitButton={!chatName || selectedUsers.length === 0}
      open={true}
      size="lg"
      title={t('AI_ASSISTANT.SHARE.TITLE')}
      titleIcon="fa-share-nodes"
      subtitle={t('AI_ASSISTANT.SHARE.SUBTITLE')}
      submitButtonLabel={t('AI_ASSISTANT.SHARE.BUTTON')}
      cancelButtonLabel={t('CANCEL')}>
      <div>
        <div className="form-group">
          <label htmlFor="user">{t('AI_ASSISTANT.SHARE.NAME.LABEL')}</label>
          <TextField
            extraClassNames="width-maximum"
            placeholder={t('AI_ASSISTANT.SHARE.NAME.PLACEHOLDER')}
            value={chatName}
            onChange={({ target: { value } }) => setChatName(value)}
          />
        </div>
        <div className="form-group mt15" style={{ height: '220px' }}>
          <label htmlFor="user">{t('AI_ASSISTANT.SHARE.USER.LABEL')}</label>
          <div className="multiSelectLarge">
            <SelectIdentity
              idForLabel="user"
              identity={selectedUsers}
              setIdentity={setSelectedUsers}
              allowGroups={false}
              startEditable={true}
              placeholder="AI_ASSISTANT.SHARE.USER.PLACEHOLDER"
              multiple={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
