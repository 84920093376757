// @ts-strict-ignore
import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Rnd } from 'react-rnd';
import { Icon } from '@seeqdev/qomponents';
import { MIN_FORMULA_PANEL_HEIGHT, MIN_FORMULA_PANEL_WIDTH } from '@/tools/formula/formulaTool.constants';
import { useResizeWatcher } from '@/core/hooks/useResizeWatcher.hook';

export interface ResizableToolPanelProps {
  resizeEnabled?: boolean;
  extraClassNames?: string;
  useDefaultClassNames?: boolean;
  /** initial height of the panel */
  height: number;
  /** initial width of the panel */
  width: number;
  /** callback function on height change */
  setHeight?: (height: number) => void;
  /** callback function on width change */
  setWidth?: (height: number) => void;
  minimumWidth?: number;
  minimumHeight?: number;
  /** Ref to element that contains the ResizablePanel */
  refLink: React.RefObject<HTMLInputElement>;
  children?: ReactNode;
}

const RightHandle = () => (
  <div className="height-maximum flexRowContainer width-12 flexCenter handle-right">
    <Icon icon="fa-angle-right" type="gray" />
  </div>
);

const BottomHandle = () => (
  <div className="height-12 flexRowContainer width-maximum flexCenter handle-bottom ">
    <Icon icon="fa-angle-down" type="gray" />
  </div>
);

/** User-Resizable ToolPanel as seen in Formula **/
export const ResizableToolPanel: React.FunctionComponent<ResizableToolPanelProps> = ({
  resizeEnabled = true,
  extraClassNames = '',
  useDefaultClassNames = true,
  children,
  height: initialHeight = 500,
  width: initialWidth = 500,
  setHeight: setHeightInStore,
  setWidth: setWidthInStore,
  minimumWidth,
  minimumHeight,
  refLink,
}) => {
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const resizePanel = () => {
    const maxAvailableHeight = document.body.getBoundingClientRect().height - 130;
    const maxAvailableWidth =
      document.body.getBoundingClientRect().width - refLink.current.getBoundingClientRect().left;

    // Only adjust size of panel if the size of the browser is smaller than the size of the window and the size of
    // the window is bigger than the minimum

    if (maxAvailableHeight < height && maxAvailableHeight >= MIN_FORMULA_PANEL_HEIGHT) {
      setHeightInStore(maxAvailableHeight);
    }

    if (maxAvailableWidth < width && maxAvailableWidth >= MIN_FORMULA_PANEL_WIDTH) {
      setWidthInStore(maxAvailableWidth);
    }
  };

  useResizeWatcher({
    element: document.body,
    callback: resizePanel,
    callOnLoad: false,
  });

  useEffect(() => {
    if (initialHeight !== height) {
      setHeight(initialHeight);
    }
    if (initialWidth !== width) {
      setWidth(initialWidth);
    }
  }, [initialWidth, initialHeight]);

  return (
    <Rnd
      bounds="window"
      size={{ width, height }}
      minWidth={minimumWidth}
      minHeight={minimumHeight}
      resizeHandleClasses={{ right: 'mr7', bottom: 'mb7' }}
      resizeHandleComponent={{
        right: <RightHandle />,
        bottom: <BottomHandle />,
      }}
      className={classNames(
        useDefaultClassNames ? 'card card-primary pt15 pr25 pb15 resizablePanel' : '',
        extraClassNames,
      )}
      style={{ display: 'flex', position: 'relative' }}
      enableResizing={{
        top: false,
        left: false,
        right: resizeEnabled,
        bottom: resizeEnabled,
        bottomRight: resizeEnabled,
      }}
      disableDragging={true}
      onResizeStop={(e, direction, ref, d) => {
        // this duplication of setting it in state and store is necessary to avoid unsightly flickering
        setWidth(width + d.width);
        setHeight(height + d.height);
        _.isFunction(setWidthInStore) && setWidthInStore(width + d.width);
        _.isFunction(setHeightInStore) && setHeightInStore(height + d.height);
      }}>
      {children}
    </Rnd>
  );
};
