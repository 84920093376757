import { ALL_CUSTOMIZATIONS_PROPERTIES, ITEM_DATA_STATUS, ITEM_TYPES } from '@/trendData/trendData.constants';
import { ITEM_ICONS } from '@/main/app.constants';

export const iconClasses = {
  [ITEM_TYPES.SERIES]: ITEM_ICONS.SERIES,
  [ITEM_TYPES.CAPSULE]: ITEM_ICONS.CAPSULE,
  [ITEM_TYPES.ANNOTATION]: ITEM_ICONS.ANNOTATION,
  [ITEM_TYPES.CAPSULE_SET]: ITEM_ICONS.CAPSULE_SET,
  [ITEM_TYPES.SCALAR]: ITEM_ICONS.SCALAR,
  [ITEM_TYPES.TABLE]: ITEM_ICONS.TABLE,
  [ITEM_TYPES.METRIC]: ITEM_ICONS.METRIC,
};

export const translateKeys = {
  [ITEM_TYPES.SERIES]: 'SERIES',
  [ITEM_TYPES.CAPSULE]: 'CAPSULE',
  [ITEM_TYPES.CAPSULE_SET]: 'CAPSULE_SERIES',
  [ITEM_TYPES.ANNOTATION]: 'ANNOTATION',
  [ITEM_TYPES.SCALAR]: 'SCALAR',
  [ITEM_TYPES.TABLE]: 'TABLE',
  [ITEM_TYPES.METRIC]: 'METRIC',
};

export const COMMON_PROPS = {
  items: [],
  dataStatus: ITEM_DATA_STATUS.PRESENT,
  warningCount: 0,
  warningLogs: [],
  timingInformation: '',
  meterInformation: '',
};

/**
 * Properties from createItem() that should always be dehydrated.
 */
export const PROPS_TO_DEHYDRATE = ALL_CUSTOMIZATIONS_PROPERTIES.concat([
  'childType',
  'id',
  'name',
  'selected',
  'autoDisabled',
  'color',
]);
