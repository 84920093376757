import { AnswerType, AssistantOrigin, LevelOfSatisfaction, SatisfactionEntry } from '@/aiAssistant/aiAssistant.types';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Button, Icon, TextArea } from '@seeqdev/qomponents';
import _ from 'lodash';
import { doTrack } from '@/track/track.service';
import { useTranslation } from 'react-i18next';

interface FeedbackDialogProps {
  onSubmitPrompt: (prompt: string, origin: AssistantOrigin, context?: Record<string, unknown>) => void;
  origin: AssistantOrigin;
  setSatisfaction: Dispatch<SetStateAction<LevelOfSatisfaction | undefined>>;
  scrollToBottom: () => void;
  trackingContext?: any;
}

export const FeedbackDialog: React.FunctionComponent<FeedbackDialogProps & SatisfactionEntry> = ({
  onSubmitPrompt,
  origin,
  setSatisfaction,
  icon,
  satisfaction,
  title,
  text,
  options,
  scrollToBottom,
  trackingContext,
}) => {
  const { t } = useTranslation();
  const getAnswersWithLinks = (): AnswerType[] => {
    return _.chain(problems)
      .map((problem) => {
        const answer = options?.find((option) => option.problemKey === problem);
        if (answer?.correctionPrompt) {
          return answer;
        }
        return null;
      })
      .compact()
      .value();
  };

  const sendDataToMixpanel = () => {
    doTrack('AiFeedback', satisfaction, {
      problems: problems.join(', '),
      feedback,
      context: trackingContext,
    });
  };
  const [feedback, setFeedback] = React.useState<string>('');
  const [problems, setProblems] = React.useState<string[]>([]);
  const [answersWithLinks, setAnswersWithLinks] = React.useState<AnswerType[]>();

  const onSubmitOrClose = () => {
    setSatisfaction(undefined);
    sendDataToMixpanel();
  };

  const manageProblems = (problem: string) => {
    if (problems.indexOf(problem) > -1) {
      setProblems(problems.filter((p) => p !== problem));
    } else {
      setProblems([...problems, problem]);
    }
  };

  useEffect(() => {
    setAnswersWithLinks(getAnswersWithLinks());
    requestAnimationFrame(scrollToBottom);
  }, [problems]);

  return (
    <div className="flexRowContainer flexCenter flexFill aiFeedbackSectionContainer width-maximum">
      <div className="positionAbsolute removeAiFeedbackContainer">
        <Icon icon="fa-remove" extraClassNames="cursorPointer ai-button" onClick={onSubmitOrClose} />
      </div>
      <Icon icon={icon} extraClassNames="fa-2xl largeIcon mb20 mt40" />
      <div className="flexColumnContainer flexFill">
        <h1>{t(title)}</h1>
      </div>
      <div className="ai-text">{text && t(text)}</div>
      <div className="flexColumnContainer p10">
        {options?.map((option) => (
          <span
            key={option.problemKey}
            className={`aiFeedbackBullet flexCenter flexRowContainer text-centera ai-text ${
              problems.indexOf(option.problemKey) > -1 ? 'aiFeedbackBulletSelected' : ''
            }`}
            onClick={() => manageProblems(option.problemKey)}>
            {t(option.displayKey)}
          </span>
        ))}
      </div>

      {!_.isEmpty(answersWithLinks) && (
        <div className="flexRowContainer flexCenter mb20">
          <div className="flexColumnContainer flexFill mt20 mb10">
            <div className="sq-text-primary promptTileTitle">{t('AI_ASSISTANT.FEEDBACK.QUICK_LINKS')}</div>
          </div>
          {answersWithLinks?.map((answer) => {
            if (answer?.correctionPrompt) {
              return (
                <div
                  key={answer.problemKey}
                  className="underline ai-text"
                  onClick={() => {
                    doTrack('AIFeedback', 'suggestion click', { fix: answer.problemKey });
                    onSubmitPrompt(t(answer.correctionPrompt!), origin);
                  }}>
                  {t(answer.correctionSuggestion!)}
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
      {problems.indexOf('other') > -1 && (
        <div className="flexRowContainer width-maximum mt10 pl10 pr10">
          <div className="flexRowContainer flexFill flexCenter">
            <TextArea
              extraClassNames={'width-maximum  ai-text'}
              value={feedback}
              placeholder={t('AI_ASSISTANT.FEEDBACK.TELL_US_MORE')}
              onChange={(e) => {
                setFeedback(e.target.value);
              }}
            />
          </div>
        </div>
      )}
      {_.size(problems) > 0 && (
        <div className="mt10 mb15">
          <Button
            onClick={onSubmitOrClose}
            label={t('AI_ASSISTANT.FEEDBACK.SUBMIT')}
            extraClassNames="ai-button"></Button>
        </div>
      )}
    </div>
  );
};
