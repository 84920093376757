// @ts-strict-ignore
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { Cookies } from 'react-cookie';
import _ from 'lodash';

const cookies = new Cookies();

/**
 * Determines if a CSRF token has been set, if the csrf token has been set it is very likely but not guaranteed
 * that the user is logged in. The session might have been expired, so the only way to truly know if the browser
 * is authenticated is to make a REST call.
 */
export function isCsrfSet() {
  const token = getCsrfToken();

  return !!token && token !== 'null';
}

/**
 * Gets the locally-stored Cross Site Request Forgery token from local storage or from a cookie. Local storage is
 * how it is stored when then token comes back from the login request as a header, cookie is how the screenshot
 * system sets it for each render.
 *
 * @return {String} A CSRF token or undefined if a token doesn't exist
 */
export function getCsrfToken() {
  return cookies.get(SeeqNames.API.Cookies.Csrf);
}

/**
 * Adds the CSRF token to the header. Needed for all requests that require authentication.
 *
 * @param {Object} headers - a key value pair of header names to header values. It will be mutated with an auth
 *                           header if necessary
 */
export function addCsrfHeader(headers) {
  const token = getCsrfToken();
  if (!_.isEmpty(token)) {
    headers[SeeqNames.API.Headers.Csrf] = token;
  }
  return headers;
}

/**
 * Removes the CSRF token from the cookie jar. Will result in future requests being invalid.
 */
export function removeCsrfToken() {
  return cookies.remove(SeeqNames.API.Cookies.Csrf, { path: '/' });
}

/**
 * Returns whether the selected domain is compatible with username and password authentication.
 */
export const isCompatibleWithUsernameAndPassword = (domain) =>
  _.find(domain.additionalProperties, ['name', SeeqNames.Properties.UsernamePasswordCompatible])?.value !== false;
