import React from 'react';

interface ColumnMenuTileProps {
  leading: React.ReactNode;
  children: React.ReactNode;
}

export const ColumnMenuTile: React.FunctionComponent<ColumnMenuTileProps> = ({ leading, children }) => {
  return (
    <>
      <span className="inlineBlock width-20">{leading}</span>
      {children}
    </>
  );
};
