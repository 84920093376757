import React from 'react';
import { Tabs } from '@seeqdev/qomponents';
import { FormulaAiAssistant } from '@/aiAssistant/FormulaAiAssistant.organism';
import _ from 'lodash';
import { FormulaDocumentation } from '@/formula/FormulaDocumentation.organism';
import { ASSISTANT, DOCUMENTATION } from '@/tools/formula/formulaTool.constants';
import { useTranslation } from 'react-i18next';
import { FORMULA_HELP_WIDTH } from '@/tools/formula/FormulaTool.organism';

interface FormulaHelpProps {
  helpView?: string;
  height?: number;
  setHelpView: (view: string) => void;
  insertFormulaSnippet: (snippet: string) => void;
  formulaHelpToggleFunction: () => void;
  operators: any;
}

export const FormulaHelp: React.FunctionComponent<FormulaHelpProps> = ({
  helpView = DOCUMENTATION,
  setHelpView,
  insertFormulaSnippet,
  formulaHelpToggleFunction,
  operators,
  height,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`flexRowContainer max-width-${FORMULA_HELP_WIDTH} min-width-${FORMULA_HELP_WIDTH}`}>
        <div className="panelTabs flexColumnContainer flexSpaceBetween help-container" data-testid="panelTabs">
          <Tabs
            transition={false}
            activeTab={helpView}
            tabs={[
              {
                id: DOCUMENTATION,
                label: t('FORMULA.DOCUMENTATION.TAB_NAME'),
                tabExtraClassNames: 'documentationTab',
                tabContentExtraClassNames: 'tab-content-container',
                content: (
                  <div className="flexFill formulaHelp mt10 pl8 pr8" data-testid="formulaHelp">
                    <FormulaDocumentation
                      height={height}
                      insertFormulaSnippet={insertFormulaSnippet}
                      operators={operators}
                      functions={_.filter(operators, (operator) => operator.name.indexOf('()') !== -1)}
                    />
                  </div>
                ),
              },
              {
                id: ASSISTANT,
                label: t('FORMULA.ASSISTANT.TAB_NAME'),
                tabExtraClassNames: 'assistantTabSet',
                content: (
                  <div
                    className="flexColumnContainer flexFill aiAssistant max-width-400 height-maximum"
                    data-testid="aiAssistant">
                    <FormulaAiAssistant insertFormulaSnippet={insertFormulaSnippet} />
                  </div>
                ),
              },
            ]}
            onTabSelect={(tabIndex: string) => setHelpView(tabIndex || DOCUMENTATION)}
          />

          {_.isFunction(formulaHelpToggleFunction) && (
            <div
              className="text-underline mt7 mr7 cursorPointer sq-text-primary nowrap help-button"
              onClick={formulaHelpToggleFunction}>
              {t('FORMULA.HIDE_HELP')}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
