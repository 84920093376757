export const PLUGIN_CATEGORY = {
  DISPLAY_PANE: 'DisplayPane',
  TOOL_PANE: 'ToolPane',
  HOME_SCREEN: 'HomeScreen',
};

export type PluginId = string; // e.g. some GUID

export type PluginIdentifier = string; // e.g. "com.seeq.plugin.parallel-coordinates"

export type OrganizerContentId = string | undefined; // The content ID associated with this plugin if in organizer

export interface PluginHostConfig {
  hideDisplayRangeSelector: boolean;
  hideInvestigateRangeSelector: boolean;
  hideBottomPanels: boolean;
  hideCapsulesPanel: boolean;
}

export interface PluginConfig {
  identifier: string;
  entryPoint: string;
  host: PluginHostConfig;
}
