import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { formatDuration } from '@/datetime/dateTime.utilities';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import { NumericCapsulePropertyColorsConfig } from '@/scatterPlot/scatterPlot.constants';

interface ScatterPlotLegendGradientProps {
  displayName: string;
  colorsConfig: NumericCapsulePropertyColorsConfig | undefined;
}

/** Displays scatter plot color information when coloring by signal or numeric capsule property */
export const ScatterPlotLegendGradientItems: React.FunctionComponent<ScatterPlotLegendGradientProps> = (props) => {
  const { displayName, colorsConfig } = props;

  const { t } = useTranslation();

  if (!displayName || !colorsConfig || colorsConfig.isStringProperty) {
    return null;
  }

  const formatNumberUtil =
    displayName === SeeqNames.CapsuleProperties.Duration
      ? (value: number) => formatDuration(value, { simplify: true })
      : formatNumber;

  return (
    <span className="mr10 specColorSignalLegend ">
      <strong>
        <span className="mr5">{t('SCATTER.LEGEND_GRADIENT')}:</span>
      </strong>
      <span className="mr5">[</span>
      <Icon
        icon="fa-circle"
        iconPrefix="fa-solid"
        type="color"
        color={colorsConfig.minColor}
        extraClassNames="mr5 mt2"
      />
      {formatNumberUtil(colorsConfig.minValue)}
      <span className="mr5">...</span>
      <Icon
        icon="fa-circle"
        iconPrefix="fa-solid"
        type="color"
        color={colorsConfig.maxColor}
        extraClassNames="mr5 mt2"
      />
      {formatNumberUtil(colorsConfig.maxValue)}
      <span className="mr5">]</span>
      <span>{displayName}</span>
    </span>
  );
};
