import { AgentType, AssistantOrigin, AssistantOriginDetails, ChatMessage } from '@/aiAssistant/aiAssistant.types';
import { APPSERVER_API_CONTENT_TYPE } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { doTrack } from '@/track/track.service';
import { getCsrfToken } from '@/utilities/auth.utilities';
import { generateRequestId, getOriginLabel, getOriginUrl } from '@/utilities/http.utilities';
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useRef, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { v1 as uuid } from 'uuid';
import { setTriggerConfetti } from '@/workbench/workbench.actions';
import { errorToast, successToast } from '@/utilities/toast.utilities';

type Result = {
  submitPrompt: (
    prompt: string,
    origin: AssistantOrigin,
    context?: Record<string, unknown>,
    messageId?: string,
    voicePrompt?: boolean,
  ) => void;
  isRunning: boolean;
  abortRequest: () => void;
  updateAgent: (newAgentType: AgentType) => void;
  scrollToBottom: () => void;
  shareChat: (name: string, userIds: string[]) => void;
};

const getAPIResponseStream = (
  agentType: string,
  input: string,
  chatId: string,
  origin: AssistantOrigin,
  context?: Record<string, unknown>,
  messageId?: string,
) => {
  const body = {
    prompt: input,
    context,
    chat_id: chatId,
    stream: true,
    streamStages: true,
    agentType,
    messageId,
  };
  const controller = new AbortController();
  const originDetails: AssistantOriginDetails = { label: getOriginLabel(), url: getOriginUrl() };
  const headers = {
    'content-type': APPSERVER_API_CONTENT_TYPE,
    [SeeqNames.API.Headers.Csrf]: getCsrfToken(),
    [SeeqNames.API.Headers.RequestOrigin]: origin,
    [SeeqNames.API.Headers.RequestOriginURL]: originDetails.url,
    [SeeqNames.API.Headers.RequestOriginLabel]: originDetails.label,
    [SeeqNames.API.Headers.RequestId]: generateRequestId(),
  };
  const { signal } = controller;
  const response = fetch('/genai/llm/chat', {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
    signal,
  });

  return { response, controller, originDetails };
};

async function* decodeStreamToText(data: ReadableStream<Uint8Array> | null): AsyncIterableIterator<string> {
  if (!data) return;

  const reader = data.getReader();
  const decoder = new TextDecoder();

  while (true) {
    const { value, done } = await reader.read();
    if (done) break;

    if (value) {
      try {
        yield decoder.decode(value);
      } catch (error) {
        console.error(error);
      }
    }
  }
}

/**
 * Custom hook to handle streaming API responses.
 * @param agentType The agent type (formula, all, etc.)
 * @param chatId The chat ID to use for the chat.
 * @param setMessages The function to update the messages in the chat.
 * @param display The ref to the chat display.
 * @returns An object containing the messages, input, and handlers for the chat.
 */
export const useChatStream = (
  agentType: AgentType,
  chatId: MutableRefObject<string>,
  setMessages: Dispatch<SetStateAction<ChatMessage[]>>,
  display: RefObject<HTMLDivElement>,
): Result => {
  const [isRunning, setIsRunning] = useState(false);
  const { t } = useTranslation();
  const BOT_ERROR_MESSAGE = t('Something went wrong fetching AI response.');
  const abortController = useRef<AbortController>();

  const addMessage = (newMessage: ChatMessage) => {
    setMessages((previousValue) => {
      return [...previousValue, { ...newMessage, agentType }];
    });
  };

  const addTrackedUserMessageToChat = (input: string, voicePrompt = false) => {
    const messageId = uuid();
    addMessage({
      role: 'user',
      dialog: input,
      id: messageId,
      chatId: chatId.current,
    });

    doTrack('AiAssistant', 'search', {
      prompt: input,
      message_id: messageId,
      chat_id: chatId.current,
      agent_type: agentType,
      voice_prompt: voicePrompt,
    });

    requestAnimationFrame(scrollToBottom);
  };

  const addBotMessageToChat = (message: string) => {
    addMessage({
      role: 'bot',
      dialog: message,
      id: uuid(),
      chatId: chatId.current,
    });
  };

  const updateBotMessageText = (message: string) => {
    setMessages((previousValue) => {
      previousValue[previousValue.length - 1].dialog += message;
      return [...previousValue];
    });

    requestAnimationFrame(scrollToBottom);
  };

  const updateBotMessageStagesTag = (name: string, value: string | string[]) => {
    setMessages((previousValue) => {
      const tags = previousValue[previousValue.length - 1].stages || {};
      tags[name] = value;
      previousValue[previousValue.length - 1].stages = tags;
      return [...previousValue];
    });
  };

  const updateBotMessageComponentsTag = (value: string[]) => {
    setMessages((previousValue) => {
      const tags = previousValue[previousValue.length - 1].components || [];
      previousValue[previousValue.length - 1].components = [...tags, ...value];
      return [...previousValue];
    });
  };

  const updateBotMessageOriginDetails = (originDetails: AssistantOriginDetails) => {
    setMessages((previousValue) => {
      previousValue[previousValue.length - 1].originDetails = originDetails;
      return [...previousValue];
    });
  };

  const updateUserMessageId = (messageId: string) => {
    setMessages((previousValue) => {
      previousValue[previousValue.length - 2].id = messageId;
      return [...previousValue];
    });
  };

  const updateBotMessageId = (messageId: string) => {
    setMessages((previousValue) => {
      previousValue[previousValue.length - 1].id = messageId;
      return [...previousValue];
    });
  };

  const fetchAndUpdateAIResponse = async (
    input: string,
    origin: AssistantOrigin,
    context?: Record<string, unknown>,
    messageId?: string,
  ) => {
    addBotMessageToChat('');
    setIsRunning(true);
    const { response, controller, originDetails } = getAPIResponseStream(
      agentType,
      input,
      chatId.current,
      origin,
      context,
      messageId,
    );
    abortController.current = controller;
    updateBotMessageOriginDetails(originDetails);

    const r = await response;

    const stream = r.body;
    if (!stream) throw new Error();

    const userMessageId = r.headers.get('x-user-message-id');
    const botMessageId = r.headers.get('x-bot-message-id');

    if (userMessageId) updateUserMessageId(userMessageId);
    if (botMessageId) updateBotMessageId(botMessageId);

    for await (const streamMessage of decodeStreamToText(stream)) {
      let message = streamMessage;

      const processMessage = (type: string, processData: (data: any) => void) => {
        let index = message.indexOf(`{"type": "${type}"`);
        while (index >= 0) {
          const beforeText = message.substring(0, index);
          if (beforeText.length > 0) {
            updateBotMessageText(beforeText);
          }

          const cleansed = message.substring(index);
          const lineBreakIndex = cleansed.indexOf('\n');
          const jsonMessage = lineBreakIndex > 0 ? cleansed.substring(0, lineBreakIndex) : cleansed;
          const data = JSON.parse(jsonMessage);
          processData(data);

          message = lineBreakIndex > 0 ? cleansed.substring(lineBreakIndex + 1) : '';
          index = message.indexOf(`{"type": "${type}"`);
        }
      };

      // Process messages that contain stage data
      processMessage('stage', (data) => updateBotMessageStagesTag(`${data['type']}_${data['name']}`, data['value']));

      // Process messages that contain ui_component data
      processMessage('ui_component', (data) => updateBotMessageComponentsTag(data['value']));

      // Process messages that contain the displayable part of the streaming response
      if (message.length > 0) {
        updateBotMessageText(message);
      }
    }

    setIsRunning(false);
  };

  const abortRequest = () => {
    abortController.current?.abort();
  };

  const submitPrompt = async (
    prompt: string,
    origin: AssistantOrigin,
    context?: Record<string, unknown>,
    messageId?: string,
    voicePrompt = false,
  ) => {
    const cleansedPrompt = prompt.trim();
    if (!cleansedPrompt || isRunning) return;

    const easyComparePrompt = prompt.toLowerCase().trim();
    if (
      _.includes(easyComparePrompt, 'andres') ||
      _.includes(easyComparePrompt, 'birgit') ||
      _.includes(easyComparePrompt, 'emilio') ||
      _.includes(easyComparePrompt, 'higgie') ||
      _.includes(easyComparePrompt, 'ship it') ||
      _.includes(easyComparePrompt, 'seeq rocks') ||
      _.includes(easyComparePrompt, 'seeq is awesome') ||
      _.includes(easyComparePrompt, 'sweet') ||
      _.includes(easyComparePrompt, 'love seeq') ||
      _.includes(easyComparePrompt, 'confetti')
    ) {
      setTriggerConfetti(true, '');
      setIsRunning(false);
      return;
    }

    addTrackedUserMessageToChat(prompt, voicePrompt);

    try {
      await fetchAndUpdateAIResponse(prompt, origin, context, messageId);
    } catch {
      if (abortController.current?.signal.aborted) {
        // User stopped the generation of messages, no need to show error message
      } else {
        addBotMessageToChat(BOT_ERROR_MESSAGE);
      }

      setIsRunning(false);
    }
  };

  const scrollToBottom = () => {
    display.current?.scrollTo({
      top: display.current.scrollHeight,
    });
  };

  const updateAgent = (newAgentType: AgentType) => {
    agentType = newAgentType;
  };

  const shareChat = async (name: string, userIds: string[]) => {
    setIsRunning(true);
    const originDetails: AssistantOriginDetails = { label: getOriginLabel(), url: getOriginUrl() };
    const headers = {
      'content-type': APPSERVER_API_CONTENT_TYPE,
      [SeeqNames.API.Headers.Csrf]: getCsrfToken(),
      [SeeqNames.API.Headers.RequestOrigin]: origin,
      [SeeqNames.API.Headers.RequestOriginURL]: originDetails.url,
      [SeeqNames.API.Headers.RequestOriginLabel]: originDetails.label,
      [SeeqNames.API.Headers.RequestId]: generateRequestId(),
    };
    await fetch(`/genai/chats/${chatId.current}/share`, {
      method: 'POST',
      body: JSON.stringify({ name, user_ids: userIds }),
      headers,
    })
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      })
      .then(() => successToast({ messageKey: 'AI_ASSISTANT.SHARE.MESSAGE.SUCCESS' }))
      .catch(() => errorToast({ messageKey: 'AI_ASSISTANT.SHARE.MESSAGE.ERROR' }))
      .finally(() => setIsRunning(false));
  };

  return { submitPrompt, abortRequest, isRunning, updateAgent, scrollToBottom, shareChat };
};
