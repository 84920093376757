// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormControlFormComponent } from '@/formbuilder/FormControlFormComponent.atom';
import { PasswordCheckFormComponent } from '@/formbuilder/PasswordCheckFormComponent.atom';
import { PASSWORD_MIN_LENGTH } from '@/main/app.constants';
import { LabelFormComponent } from '@/formbuilder/LabelFormComponent.atom';

export interface PasswordGroupIF extends ValidatingFormComponent<string | number> {
  component: 'PasswordGroupFormComponent';
  name: string;
  includeIf?: boolean;
}

// PasswordGroupFormComponent can only be used if “skipStore” is true. This setting will not call the onChange handler.
export const PasswordGroupFormComponent: React.FunctionComponent<PasswordGroupIF> = ({ testId = 'passwordGroup' }) => {
  const validateMinLength = (value) => _.isEmpty(value) || _.size(value) < PASSWORD_MIN_LENGTH;

  return (
    <div className="flexRowContainer" data-testid={testId}>
      <div className="mb8">
        <FormControlFormComponent
          component="FormControlFormComponent"
          name="newPassword"
          label="USER.PASSWORD"
          value=""
          onChange={_.noop}
          placeholder="USER.PASSWORD_PROMPT"
          validation={validateMinLength}
          customErrorText="USER.PASSWORD_MIN_LENGTH"
          customErrorParams={{ min: PASSWORD_MIN_LENGTH }}
          size="md"
          type="password"
          testId="newPassword"
        />
        <LabelFormComponent
          component="LabelFormComponent"
          name="confirmPasswordLabel"
          value="USER.PASSWORD_CHECK"
          extraClassNames="mt8 text-bolder"
        />
        <PasswordCheckFormComponent
          component="PasswordCheckFormComponent"
          name="confirmPassword"
          value=""
          onChange={_.noop}
          placeholder="USER.PASSWORD_CHECK_PROMPT"
          validation={validateMinLength}
          customErrorText="USER.PASSWORD_MIN_LENGTH"
          customErrorParams={{ min: PASSWORD_MIN_LENGTH }}
          extraClassNames="mb10"
          size="md"
          type="password"
          testId="confirmPassword"
        />
      </div>
    </div>
  );
};
