// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { sqDisplaysApi } from '@/sdk/api/DisplaysApi';
import { errorToast } from '@/utilities/toast.utilities';
import { DeleteDisplayModal } from '@/displays/DeleteDisplayModal.molecule';
import { MoveDisplayModal } from '@/displays/MoveDisplayModal.molecule';
import { OverwriteDisplayModal } from '@/displays/OverwriteDisplayModal.molecule';
import { RenameDisplayModal } from '@/displays/RenameDisplayModal.molecule';
import { useAllTrendStoreItems } from '@/core/hooks/useAllTrendStoreItems.hook';
import { ButtonWithPopover } from '@/core/ButtonWithPopover.molecule';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { EventCapture } from '@/core/EventCapture.atom';
import { DISPLAY_DROPDOWN_ACTION_CUSTOM_ID } from '@/displays/displays.constants';
import { canOverwriteScaledDisplay, getDisplayCount } from '@/displays/displays.utilities';
import { sqSearchStore } from '@/core/core.stores';

export interface DisplayDropdownProps {
  item: any;
  isPinning: boolean;
  isPinned: boolean;
  togglePin: () => void;
  restrictExploration?: boolean;
}

export const DisplayDropdown: React.FunctionComponent<DisplayDropdownProps> = ({
  item,
  isPinning,
  isPinned,
  togglePin,
  restrictExploration = false,
}) => {
  enum MODAL {
    NONE = 'none',
    OVERWRITE = 'overwrite',
    RENAME = 'rename',
    MOVE = 'move',
    DELETE = 'delete',
  }

  const { t } = useTranslation();

  const [displayDropdown, setDisplayDropdown] = useState([]);
  const [isScaled, setIsScaled] = useState(false);
  const [displaysCount, setDisplaysCount] = useState(1);
  const [overwriteDisabled, setOverwriteDisabled] = useState(false);
  const [modalShown, setModalShown] = useState<MODAL>(MODAL.NONE);

  const displayLoadingId = useFluxPath(sqSearchStore, () => sqSearchStore.displayLoadingId);
  const displayLoading = displayLoadingId === item.id;

  const withDisplayCountSet = (action: () => void) => {
    getDisplayCount(item)
      .then((displayCount) => {
        setDisplaysCount(displayCount);
        action();
      })
      .catch((e) => {
        errorToast({ httpResponseOrError: e });
      });
  };

  const renderDropdownEntry = ({
    translationKey,
    iconClass,
    spinning = false,
    action = _.noop,
    doRender = true,
    disabled = false,
    tooltip = undefined,
  }) => {
    return (
      doRender && (
        <HoverTooltip text={tooltip} delay={500} placement="right" key={`dropdown_wrapper_${translationKey}`}>
          <div onClick={(e) => disabled && e.stopPropagation()}>
            <Dropdown.Item
              disabled={disabled}
              data-testid={`anchor_${translationKey}`}
              data-customid={DISPLAY_DROPDOWN_ACTION_CUSTOM_ID}
              onClick={() => {
                action();
              }}>
              <IconWithSpinner
                icon={`${iconClass}`}
                type="inherit"
                extraClassNames="pr10 fa fa-fw"
                testId={`icon_${translationKey}`}
                customId={DISPLAY_DROPDOWN_ACTION_CUSTOM_ID}
                spinning={spinning}
                correctSpin={false}
              />
              <span
                className="fs13 pl5"
                data-testid={`label_${translationKey}`}
                data-customid={DISPLAY_DROPDOWN_ACTION_CUSTOM_ID}>
                {t(translationKey)}
              </span>
            </Dropdown.Item>
          </div>
        </HoverTooltip>
      )
    );
  };

  useEffect(() => {
    const actions = [
      {
        translationKey: 'SEARCH_DATA.DISPLAYS.OVERWRITE',
        iconClass: 'fc-overwrite',
        action: () => withDisplayCountSet(() => setModalShown(MODAL.OVERWRITE)),
        disabled: overwriteDisabled,
        tooltip: overwriteDisabled
          ? t('SEARCH_DATA.DISPLAYS.TOOLTIPS.OVERWRITE_DISABLED', {
              ITEM_PARENT_NAME: _.chain(item.ancestors).last().get('name').value(),
            })
          : undefined,
      },
      {
        translationKey: 'SEARCH_DATA.DISPLAYS.RENAME',
        iconClass: 'fc-rename',
        action: () => withDisplayCountSet(() => setModalShown(MODAL.RENAME)),
      },
      {
        translationKey: 'SEARCH_DATA.DISPLAYS.MOVE',
        iconClass: 'fa-arrows',
        action: () => setModalShown(MODAL.MOVE),
        disabled: isScaled,
        tooltip: isScaled ? 'SEARCH_DATA.DISPLAYS.TOOLTIPS.MOVE_DISABLED' : undefined,
      },
      {
        translationKey: isPinned ? 'SEARCH_DATA.DISPLAYS.UNPIN' : 'SEARCH_DATA.DISPLAYS.PIN',
        iconClass: isPinned ? 'fc-pin' : 'fc-pin-o',
        spinning: isPinning,
        disabled: isPinning,
        action: () => !isPinning && togglePin(),
      },
      {
        translationKey: 'SEARCH_DATA.DISPLAYS.DELETE',
        iconClass: 'fc-trash',
        action: () => withDisplayCountSet(() => setModalShown(MODAL.DELETE)),
      },
    ];
    setDisplayDropdown(_.map(actions, renderDropdownEntry));
  }, [overwriteDisabled, isScaled, isPinned, isPinning]);

  useEffect(() => {
    sqDisplaysApi
      .getDisplay({ id: item.id })
      .then(
        ({
          data: {
            template: { swapSourceAssetId },
          },
        }) => {
          const isScaledDisplay = !!swapSourceAssetId;
          setIsScaled(isScaledDisplay);
        },
      )
      .catch((error) => errorToast({ httpResponseOrError: error }));
  }, []);

  const trendStoreItems = useAllTrendStoreItems();
  useEffect(() => {
    const displayParentAssetId = _.last(
      item.ancestors as {
        id: string;
      }[],
    )?.id;
    const disabled = isScaled && !canOverwriteScaledDisplay(displayParentAssetId, trendStoreItems);
    setOverwriteDisabled(disabled);
  }, [item, isScaled, trendStoreItems]);

  return (
    <>
      <ButtonWithPopover
        label={
          <Icon
            icon={displayLoading ? 'fa-spinner fa-spin-pulse' : 'fc fc-more'}
            iconPrefix={displayLoading ? 'fa-solid' : ''}
            type="inherit"
            extraClassNames="searchBtn"
            testId="displayActionsIcon"
            customId={DISPLAY_DROPDOWN_ACTION_CUSTOM_ID}
            tooltipDelay={500}
            tooltip={t('SEARCH_DATA.DISPLAYS.TOOLTIPS.SHOW_ACTIONS')}
            tooltipPlacement="top"
          />
        }
        disabled={displayLoading}
        closeOnClick={true}
        closeWhenMoved={true}
        popoverConfig={{
          id: `display-actions-${item.id}`,
          placement: 'bottom',
        }}>
        {displayDropdown}
      </ButtonWithPopover>

      {modalShown === MODAL.OVERWRITE && (
        <EventCapture>
          <OverwriteDisplayModal
            onClose={() => setModalShown(MODAL.NONE)}
            display={item}
            displaysCount={displaysCount}
          />
        </EventCapture>
      )}

      {modalShown === MODAL.RENAME && (
        <EventCapture>
          <RenameDisplayModal onClose={() => setModalShown(MODAL.NONE)} display={item} displaysCount={displaysCount} />
        </EventCapture>
      )}

      {modalShown === MODAL.MOVE && (
        <EventCapture>
          <MoveDisplayModal onClose={() => setModalShown(MODAL.NONE)} display={item} />
        </EventCapture>
      )}

      {modalShown === MODAL.DELETE && (
        <EventCapture>
          <DeleteDisplayModal
            isPinned={isPinned}
            onClose={() => setModalShown(MODAL.NONE)}
            display={item}
            displaysCount={displaysCount}
            restrictExploration={restrictExploration}
          />
        </EventCapture>
      )}
    </>
  );
};
