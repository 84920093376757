// @ts-strict-ignore
import _ from 'lodash';
import { SwapOutputV1 } from '@/sdk/model/SwapOutputV1';
import { sqDisplaysApi } from '@/sdk/api/DisplaysApi';
import { sqDisplayTemplatesApi } from '@/sdk/api/DisplayTemplatesApi';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { sqTreesApi } from '@/sdk/api/TreesApi';
import { errorToast, successToast, warnToast } from '@/utilities/toast.utilities';
import { findChildrenIn, getAllItems, getTrendStores } from '@/trend/trendDataHelper.utilities';
import { flux } from '@/core/flux.module';
import { DISPLAYS_CATEGORY, STORES_REHYDRATED_BY_DISPLAY } from '@/displays/displays.constants';
import { API_TYPES, SEARCH_TYPES } from '@/main/app.constants';
import { SearchPanes } from '@/search/search.constants';
import { sqStateSynchronizer, sqWorkbenchStore } from '@/core/core.stores';
import { getCurrentWorkstepId } from '@/worksteps/worksteps.utilities';
import { getDisplayTemplate } from '@/displays/displays.utilities';
import { fetchPinnedItems } from '@/workbook/workbook.actions';
import { doTrack } from '@/track/track.service';
import { deleteItem, exploreAssetSearchActions, refresh, setHighlightItem } from '@/search/search.actions';
import { fetchAllItems, fetchPropsForAllItems, getSwaps, removeChildren } from '@/trendData/trend.actions';

export function loadDisplay(displayId: string) {
  let swap: () => Promise<any> = () => Promise.resolve();
  const { workbookId, worksheetId } = sqWorkbenchStore.stateParams;

  return sqDisplaysApi
    .getDisplay({ id: displayId })
    .then(({ data }) => {
      if (data.swap) {
        swap = () => fetchPropsForAllItems(true).then(() => performSwap(data.swap));
      }
      return sqStateSynchronizer.loadWorkstepStores(
        data.template.sourceWorkstepId,
        STORES_REHYDRATED_BY_DISPLAY,
        workbookId,
        worksheetId,
        'Error when parsing workstep data for display:',
        fetchAllItems,
        swap,
      );
    })
    .then(() => sqStateSynchronizer.push())
    .catch(() => {
      errorToast({ messageKey: 'DISPLAYS.FETCH_ERROR' });
    });
}

function performSwap(swap: SwapOutputV1) {
  const allItems = getAllItems({});
  const swapOutItemIds = _.chain(allItems)
    .uniqBy('id')
    .filter((item) => _.some(item?.assets, (asset) => _.includes(asset?.pathComponentIds, swap.swapOut)))
    .map('id')
    .value();
  return sqItemsApi
    .getSwapOptions({ id: swap.swapIn, swapOutItemIds })
    .then(({ data: { swapOptions } }) => {
      const swapOption = swapOptions.find(({ swapRootCandidate: { id } }) => id === swap.swapOut);
      if (swapOption) {
        const swapPairs = _.mapValues(_.keyBy(swapOption.itemsWithSwapPairs, 'item.id'), 'swapPairs');
        return getSwaps(swapPairs, allItems, swapOutItemIds, true);
      } else {
        return {};
      }
    })
    .then((swaps) => {
      const itemsToRemove = _.chain(swapOutItemIds)
        .difference(_.keys(swaps))
        .flatMap((id) => _.concat(findChildrenIn(getTrendStores(), id), { id }))
        .value();

      flux.dispatch('TREND_REMOVE_ITEMS', { items: itemsToRemove });
      _.forEach(swaps, (swappedId, id) => {
        removeChildren(id);
        // Remove other signals from the chart so that old data and new data is not mixed while loading
        flux.dispatch('TREND_SERIES_CLEAR_DATA', { id });
      });
      flux.dispatch('TREND_SWAP_ITEMS', { swaps });

      if (!_.isEmpty(itemsToRemove)) {
        const removedItemNames = _.chain(itemsToRemove)
          .map((item) => _.find(allItems, ['id', item.id])?.name)
          .compact()
          .value()
          .join(', ');
        warnToast({
          messageKey: 'DISPLAYS.ITEMS_MISSING',
          messageParams: { EXCLUDED_ITEM_NAMES: removedItemNames },
        });
      }
    });
}

/**
 * Delete a display and its scaled copies
 *
 * @param display - The scaled display item to delete
 * @param displaysCount - The number of displays using the display's template
 * @param [isPinned] - Is this a pinned item (default false)
 * @param [restrictExploration] - If exploration should be restricted (default false)
 */
export function deleteDisplays(
  display: { id: string; name: string; type: string },
  displaysCount: number,
  isPinned = false,
  restrictExploration = false,
) {
  return getDisplayTemplate(display)
    .then((template) => {
      const templateItem = {
        id: template.id,
        name: template.name,
        type: API_TYPES.DISPLAY_TEMPLATE,
      };
      return deleteItem(
        templateItem,
        'DISPLAYS.MODAL.DELETE.DISPLAY_TEMPLATE.DELETED',
        'DISPLAYS.MODAL.DELETE.DISPLAY_TEMPLATE.ERROR_DELETING',
        { ITEM_NAME: display.name, SCALE_COUNT: displaysCount - 1 },
        {},
        isPinned,
        true,
        restrictExploration,
      );
    })
    .catch(() => {
      errorToast({
        messageKey: 'DISPLAYS.MODAL.DELETE.DISPLAY_TEMPLATE.ERROR_DELETING',
      });
    });
}

/**
 * Moves a display to a new parent
 *
 * @param displayId - the ID of the display to be moved
 * @param parentId - the ID of the new parent
 */
export function moveDisplay(displayId: string, parentId: string) {
  const EXCLUDE_GLOBALLY_SCOPED = true;
  doTrack(DISPLAYS_CATEGORY, 'move', 'move');
  const body = { items: [displayId] };
  return sqTreesApi
    .moveNodesToParent(body, { parentId })
    .then(() =>
      exploreAssetSearchActions(
        'main',
        parentId,
        SEARCH_TYPES,
        false,
        sqWorkbenchStore.stateParams.workbookId,
        EXCLUDE_GLOBALLY_SCOPED,
      ),
    )
    .then(() => setHighlightItem('main', displayId))
    .then(() => fetchPinnedItems(sqWorkbenchStore.stateParams.workbookId))
    .catch((error) => errorToast({ httpResponseOrError: error }));
}

/**
 * Sets the workstep of the display and all scaled displays to the current workstep
 *
 * @param display The display item
 * @return {Promise}
 */
export function overwriteDisplay(display: { id: string; name: string; type: string; ancestors: { id: string }[] }) {
  return getDisplayTemplate(display)
    .then((template) => {
      return getCurrentWorkstepId(
        sqWorkbenchStore.stateParams.workbookId,
        sqWorkbenchStore.stateParams.worksheetId,
      ).then((workstep) => {
        let swapAssetId;
        const parentAsset = _.last(display.ancestors);
        if (template.displayCount > 1 && parentAsset) {
          swapAssetId = parentAsset.id;
        }
        return sqDisplayTemplatesApi
          .updateTemplate(
            {
              name: template.name,
              scopedTo: template.scopedTo,
              sourceWorkstepId: workstep,
              swapSourceAssetId: swapAssetId,
            },
            { id: template.id },
          )
          .then(() => {
            successToast({
              messageKey: 'DISPLAYS.MODAL.OVERWRITE.SUCCESS',
              messageParams: { ITEM_NAME: display.name },
            });
          });
      });
    })
    .catch(() => {
      errorToast({
        messageKey: 'DISPLAYS.MODAL.OVERWRITE.ERROR',
      });
    });
}

/**
 * Renames the current display and all scaled displays
 *
 * @param display The display item
 * @param newDisplayName The new name for the display item
 * @param [restrictExploration] - If exploration should be restricted
 * @param [pane] - The name of the search pane, one of SearchPanes (default SearchPanes.MAIN)
 * @param [searchTypes] - The types to search (default SEARCH_TYPES)
 * @param [scopeIds] - Ids of scoped assets, default is workbookId
 * @return {Promise}
 */
export function renameDisplay(
  display: { id: string; name: string; type: string },
  newDisplayName: string,
  restrictExploration = false,
  pane: SearchPanes = 'main',
  searchTypes: string[] = SEARCH_TYPES,
  scopeIds: string[] = sqWorkbenchStore.stateParams.workbookId,
): Promise<any> {
  return getDisplayTemplate(display)
    .then((template) => {
      return sqDisplayTemplatesApi
        .updateTemplate(
          {
            name: newDisplayName,
            scopedTo: template.scopedTo,
            sourceWorkstepId: template.sourceWorkstepId,
          },
          { id: template.id },
        )
        .then(() => {
          // Since renaming a display also renames all scaled displays, it is not enough to check if current
          // display item is pinned. Any scaled display that is pinned needs to be fetched.
          return Promise.all([
            fetchPinnedItems(sqWorkbenchStore.stateParams.workbookId),
            refresh(pane, searchTypes, restrictExploration, scopeIds),
          ]).then(() => {
            successToast({
              messageKey: 'DISPLAYS.MODAL.RENAME.SUCCESS',
              messageParams: {
                ITEM_NAME: display.name,
                ITEM_NAME_NEW: newDisplayName,
              },
            });
          });
        });
    })
    .catch(() => {
      errorToast({
        messageKey: 'DISPLAYS.MODAL.RENAME.ERROR',
      });
    });
}
