// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { readableIdentifier } from '@/utilities/utilities';
import { Identity } from '@/accessControl/itemAclModal.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';

interface IdentityPopoverProps {
  identity: Identity;
}

export const IdentityPopover: React.FunctionComponent<IdentityPopoverProps> = ({ identity }) => {
  const { t } = useTranslation();

  const redactedMessage = <div className="flexRowContainer">{t('ITEM_ACL.TOOLTIP_REDACTED_IDENTITY')}</div>;

  const disabledMessage = <div className="flexRowContainer">{t('ITEM_ACL.TOOLTIP_DISABLED_IDENTITY')}</div>;

  const username = () => (
    <div className="aggressiveWordBreak">
      <span className="text-bolder">{t('ITEM_ACL.USERNAME')}</span>
      <span className="ml5">{readableIdentifier(identity)}</span>
    </div>
  );

  const aceDetails = () => (
    <div className="flexRowContainer">
      {identity.type === SeeqNames.Types.User && readableIdentifier(identity) && username()}
      <div className="textAlignLeft">
        <span className="text-bolder">{t('ITEM_ACL.DATASOURCE')}</span>
        <span className="ml5">{identity.datasource.name}</span>
      </div>
    </div>
  );

  return (
    <div className="p5">
      {identity.isRedacted && redactedMessage}
      {!identity.isRedacted && !identity.isEnabled && disabledMessage}
      {!identity.isRedacted && identity.isEnabled && aceDetails()}
    </div>
  );
};
