// @ts-strict-ignore
import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { EditableText } from '@/core/EditableText.atom';
import { TableBuilderTextHeaderMenu } from '@/tableBuilder/tableComponents/TableBuilderTextHeaderMenu.atom';
import { TableFilterIcon } from '@/core/tableUtilities/TableFilterIcon.organism';
import { TableBuilderSortIcon } from '@/tableBuilder/tableComponents/TableBuilderSortIcon.atom';
import { computeCellStyle, getMostReadableIconType } from '@/utilities/tableBuilderHelper.utilities';
import { FILTER_SORT_MENU_ACTIONS, TextHeaderMenuAction } from '@/tableBuilder/tableBuilder.constants';
import { TableBuilderTextHeaderProps } from '@/tableBuilder/tableBuilder.types';
import { useDidMount } from 'rooks';
import { useTranslation } from 'react-i18next';
import { getAggregationPrefix } from '@/tableBuilder/tableBuilderAgGrid.utilities';

const DATA_CANCELLATION_GROUP = 'tableBuilder';

export const TableBuilderTextHeader: React.FunctionComponent<TableBuilderTextHeaderProps> = ({
  canEdit,
  columnKey,
  columnBackgroundColor,
  columnTextAlign,
  columnTextColor,
  columnTextStyle,
  headerBackgroundColor,
  headerTextAlign,
  headerTextColor,
  headerTextStyle,
  isInput,
  isStatic = false,
  isTransposed,
  sort,
  textFormatter,
  textValue,
  menuActions = _.values(TextHeaderMenuAction),
  showMove = true,
  moveColumn,
  removeColumn,
  onTextChange,
  setColumnFilter,
  fetchStringColumnValues = _.noop,
  columnFilter = undefined,
  isFilterDisabled = false,
  filterHelpText = undefined,
  isStringColumn = false,
  isDurationColumn = false,
  distinctStringValues = undefined,
  thresholds = undefined,
  darkMode = false,
  registerRowDragger,
  autoSizeColumn,
  autoSizeAllColumns,
  isMetric,
  groupByColumn = undefined,
  isInteractiveContent = false,
  hideInteractiveContentActions,
  aggregationFunction = undefined,
  setAggregationOnColumn = undefined,
}) => {
  const { t } = useTranslation();
  const showDragAndDrop = showMove && !_.isNil(moveColumn);

  const [forceEdit, setForceEdit] = useState(false);

  // ref is used:
  // - for drag and drop
  // - to open the text formatter menu relative to this component
  const ref = useRef<HTMLTableDataCellElement>(null);
  const agGridDragRef = useRef<HTMLSpanElement>(null);

  useDidMount(() => {
    registerRowDragger?.(agGridDragRef.current);
  });

  // use any to avoid TS2322: Type 'string' is not assignable to type 'TextAlignProperty'.
  const style: any = computeCellStyle(
    headerBackgroundColor,
    headerTextColor,
    headerTextStyle,
    headerTextAlign,
    undefined,
    undefined,
    darkMode,
  );

  const filteredMenuActions = isInteractiveContent
    ? menuActions.filter((action) => !_.includes(FILTER_SORT_MENU_ACTIONS, action))
    : menuActions;

  const renderMenu = () => {
    return (
      !_.isEmpty(menuActions) && (
        <TableBuilderTextHeaderMenu
          canRenameHeader={!isStatic}
          columnKey={columnKey}
          columnBackgroundColor={columnBackgroundColor}
          columnTextAlign={columnTextAlign}
          columnTextColor={columnTextColor}
          columnTextStyle={columnTextStyle}
          headerBackgroundColor={headerBackgroundColor}
          headerTextAlign={headerTextAlign}
          headerTextColor={headerTextColor}
          headerTextStyle={headerTextStyle}
          isTransposed={isTransposed}
          target={ref}
          textFormatter={textFormatter}
          editHeaderValue={() => setForceEdit(true)}
          removeColumn={removeColumn}
          canSort={sort?.canSort}
          canSortDisabledTooltip={sort?.canSortDisabledTooltip}
          sortByColumn={sort?.sortByColumn}
          sortDirection={sort?.sortDirection}
          setColumnFilter={setColumnFilter}
          columnFilter={columnFilter}
          actions={filteredMenuActions}
          isFilterDisabled={isFilterDisabled}
          filterHelpText={filterHelpText}
          isStringColumn={isStringColumn}
          isDurationColumn={isDurationColumn}
          fetchStringColumnValues={fetchStringColumnValues}
          distinctStringValues={distinctStringValues}
          thresholds={thresholds}
          autoSizeColumn={autoSizeColumn}
          autoSizeAllColumns={autoSizeAllColumns}
          groupByColumn={groupByColumn}
          isRemoveSortDisabled={!sort?.sortDirection}
          isInteractiveContent={isInteractiveContent}
          aggregationFunction={aggregationFunction}
          setAggregationFunction={setAggregationOnColumn}
        />
      )
    );
  };

  const renderDragAndDrop = () => {
    return (
      showDragAndDrop && (
        <span className="sq-icon-hover" style={{ cursor: 'grab' }} ref={agGridDragRef}>
          <Icon icon="fa-arrows" extraClassNames="fa-fw sq-icon-hover" type="gray" />
        </span>
      )
    );
  };

  const renderFilter = () => {
    return (
      <TableFilterIcon
        columnKey={columnKey}
        setColumnFilter={setColumnFilter}
        iconType={getMostReadableIconType(headerBackgroundColor)}
        columnFilter={columnFilter}
        isFilterDisabled={isFilterDisabled}
        isStringColumn={isStringColumn}
        isDurationColumn={isDurationColumn}
        distinctStringValues={distinctStringValues}
        helpText={filterHelpText}
        onIconClick={() => fetchStringColumnValues(columnKey, isStringColumn, DATA_CANCELLATION_GROUP)}
        thresholds={thresholds}
        isInteractiveContent={isInteractiveContent}
      />
    );
  };

  const renderSort = () => {
    return (
      <TableBuilderSortIcon
        active={sort?.canSort}
        direction={sort?.sortDirection}
        columnKey={columnKey}
        headerBackgroundColor={headerBackgroundColor}
        level={sort?.sortLevel}
        setDirection={sort?.sortByColumn}
        showLevel={sort?.maxSortLevel > 1}
        isInteractiveContent={isInteractiveContent}
      />
    );
  };

  if (canEdit && (isInput || isStatic)) {
    const innerContent = (
      <>
        <div className="ml5 mr5 flexFillOverflow">
          {isInput && !isMetric ? (
            <EditableText
              allowEditing={true}
              allowEmptyValue={true}
              forceEdit={forceEdit}
              inputClasses="flexFill"
              textClasses="mb0 break-word"
              testId="columnName"
              value={textValue}
              onUpdate={(value) => {
                setForceEdit(false);
                onTextChange(value);
              }}
              textPrefix={getAggregationPrefix(aggregationFunction)}
            />
          ) : (
            <>
              {getAggregationPrefix(aggregationFunction)}
              {textValue}
            </>
          )}
        </div>

        <div className="flexColumnContainer flexAlignCenter">
          {renderFilter()}
          {renderSort()}
          {renderDragAndDrop()}
          {renderMenu()}
        </div>
      </>
    );

    const className = 'forceVerticalAlignMiddle';
    const dataTestId = 'tableBuilderTextHeader';

    return (
      <div style={style} ref={ref} className={className} data-testid={dataTestId}>
        <div className="flexColumnContainer">{innerContent}</div>
      </div>
    );
  }

  const showFilter =
    !isInteractiveContent || (!hideInteractiveContentActions && _.includes(menuActions, TextHeaderMenuAction.Filter));
  const showSort =
    !isInteractiveContent || (!hideInteractiveContentActions && _.includes(menuActions, TextHeaderMenuAction.Sort));

  const innerContent = (
    <div className="flexColumnContainer">
      <div className="ml5 mr5 flexFillOverflow">
        {getAggregationPrefix(aggregationFunction)}
        {textValue}
      </div>
      <div className="flexColumnContainer flexAlignCenter">
        {showFilter ? renderFilter() : null}
        {showSort ? renderSort() : null}
        {!isInteractiveContent ? renderMenu() : null}
        {hideInteractiveContentActions && (
          <Icon icon="fa-exclamation-triangle" type="gray" tooltip="Refresh content to filter and/or sort" />
        )}
      </div>
    </div>
  );
  const className = 'forceVerticalAlignMiddle';
  const dataTestId = 'tableBuilderTextHeader';

  return (
    <div className={className} data-testid={dataTestId} style={style}>
      {innerContent}
    </div>
  );
};
