import _ from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import { SearchWidget } from '@/search/SearchWidget.organism';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { isAsset } from '@/utilities/utilities';
import { onEventPreventPropagation } from '@/core/onEnterKeypress.util';
import { AnyProperty } from '@/utilities.types';
import { ItemPreviewV1 } from '@/sdk/model/ItemPreviewV1';

export interface SelectItemSearchWidgetProps {
  onSelect: (item: SelectedItem) => void;
  iconPartialTooltipKey: string;
  itemId?: string;
  validateItem: (item: SelectedItem) => Promise<boolean>;
  scopeIds: string[];
  excludeGloballyScoped?: boolean;
  modalId?: string;
  searchTypes: string[];
}

export type SelectedItem = {
  id: string;
  name: string;
  ancestors?: ItemPreviewV1[];
};

export const SelectItemSearchWidget: React.FunctionComponent<SelectItemSearchWidgetProps> = ({
  onSelect,
  iconPartialTooltipKey,
  itemId,
  validateItem,
  scopeIds,
  excludeGloballyScoped = false,
  modalId,
  searchTypes,
}) => {
  const { t } = useTranslation();

  const isSelectingAssets =
    searchTypes.includes(SeeqNames.Types.Asset) || searchTypes.includes(SeeqNames.Types.AssetSelection);

  const searchResultIcons = useCallback(
    (item: AnyProperty): Promise<React.ReactNode> => {
      const onlySearchingAssets = isSelectingAssets && searchTypes.length === 2;
      if (!isAsset(item) && onlySearchingAssets) {
        return Promise.resolve(null);
      }

      return validateItem(item as SelectedItem).then((isValid) => {
        const isSelected = itemId ? item.id === itemId : false;
        return isValid ? (
          <span onClick={onEventPreventPropagation(() => onSelect(item as SelectedItem))}>
            <Icon
              testId="itemSelect"
              extraClassNames="searchBtn"
              tooltip={t(`${iconPartialTooltipKey}.ASSET_TOOLTIP`)}
              large={true}
              icon={isSelected ? 'fa-check-circle' : 'fa-circle'}
            />
          </span>
        ) : (
          <span onClick={onEventPreventPropagation(_.noop)}>
            <Icon
              testId="itemSelectInvalid"
              extraClassNames="disabled mr3 p3"
              type="color"
              color="grey"
              tooltip={t(`${iconPartialTooltipKey}.ASSET_TOOLTIP_INVALID`)}
              large={true}
              icon="fa-circle"
            />
          </span>
        );
      });
    },
    [itemId, validateItem, iconPartialTooltipKey, onSelect],
  );

  return (
    <SearchWidget
      isSelectingAsset={isSelectingAssets}
      iconClasses="sq-fairly-dark-gray"
      pane="modal"
      searchTypes={searchTypes}
      searchResultIcons={searchResultIcons}
      allowAssetReplacement={false}
      showOnlyResults={!(searchTypes.includes(SeeqNames.Types.Asset) && searchTypes.length > 2)}
      scopeIds={scopeIds}
      excludeGloballyScoped={excludeGloballyScoped}
      modalId={modalId}
      predefinedSearchTypes={searchTypes}
      onSelectItem={onSelect}
    />
  );
};
