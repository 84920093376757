// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import { FakeLink } from '@/core/FakeLink';

interface FormulaFamilyMember {
  documentationHref: string;
  title: string;
}

interface FormulaChild extends FormulaFamilyMember {
  children: FormulaFamilyMember[];
  shortDescription: any;
}

export interface FormulaFamilyProps {
  parents: FormulaFamilyMember[];
  formulaChildren: FormulaChild[];
  requestDocumentation: (href: string) => any;
}

export const FormulaFamily: React.FunctionComponent<FormulaFamilyProps> = ({
  parents,
  formulaChildren,
  requestDocumentation,
}) => {
  const { t } = useTranslation();

  const isLastParent = (index) => _.toNumber(index) === parents.length - 1;
  const isLastChild = (index) => _.toNumber(index) === formulaChildren.length - 1;
  const isLastGrandchild = (index, grandchildren) => _.toNumber(index) === grandchildren.length - 1;

  return (
    <>
      {/* Parents */}
      {parents?.length > 0 && (
        <>
          {parents.length === 1 && <h2>{t('FORMULA.DOCUMENTATION.OVERVIEW_PAGE')}</h2>}
          {parents.length > 1 && <h2>{t('FORMULA.DOCUMENTATION.OVERVIEW_PAGES')}</h2>}
          <ul className="pl20">
            {_.map(parents, (parent, index) => (
              <li key={index} className={isLastParent(index) ? 'mb10' : 'mb5'}>
                <FakeLink onClick={() => requestDocumentation(parent.documentationHref)}>{parent.title}</FakeLink>
              </li>
            ))}
          </ul>
        </>
      )}

      {/* Children */}
      {formulaChildren?.length > 0 && (
        <>
          {formulaChildren.length === 1 && <h2>{t('FORMULA.DOCUMENTATION.DETAIL_PAGE')}</h2>}
          {formulaChildren.length > 1 && <h2>{t('FORMULA.DOCUMENTATION.DETAIL_PAGES')}</h2>}
          <ul className="pl20">
            {_.map(formulaChildren, (child: FormulaChild, index) => (
              <React.Fragment key={index}>
                <li className={isLastChild(index) ? 'mb10' : 'mb5'}>
                  <FakeLink onClick={() => requestDocumentation(child.documentationHref)}>{child.title}</FakeLink>
                  <span>
                    &nbsp;-&nbsp;
                    <ContainerWithHTML content={child.shortDescription} />
                  </span>
                </li>

                <ul className="pl20">
                  {_.map(child.children, (grandchild, index) => (
                    <li key={index} className={isLastGrandchild(index, child.children) ? 'mb10' : 'mb5'}>
                      <FakeLink onClick={() => requestDocumentation(grandchild.documentationHref)}>
                        {grandchild.title}
                      </FakeLink>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </ul>
        </>
      )}
    </>
  );
};
