// @ts-strict-ignore
import { REPORT_CONTENT, ReportContentSummary } from '@/reportEditor/report.constants';
import _ from 'lodash';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';

/**
 * Parses the given summary out into it's backend representation
 *
 * @param summary - The summary being parsed
 * @return - an object containing the type and value of the given summary
 */
export const parseSummaryToTypeAndValue = (
  summary?: ReportContentSummary,
): {
  summaryType: SummaryTypeEnum | undefined;
  summaryValue: string | undefined;
} => {
  const undefinedSummary = _.constant({
    summaryType: undefined,
    summaryValue: undefined,
  });
  return _.cond([
    [(summary) => _.isUndefined(summary), undefinedSummary],
    [
      (summary: ReportContentSummary) => summary.key === REPORT_CONTENT.SUMMARY.NONE.key,
      _.constant({ summaryType: SummaryTypeEnum.NONE, summaryValue: '0' }),
    ],
    [
      (summary: ReportContentSummary) => summary.key === REPORT_CONTENT.SUMMARY.DISCRETE.key,
      ({ value }) => ({
        summaryType: SummaryTypeEnum.DISCRETE,
        summaryValue: `${value.value}${value.units}`,
      }),
    ],
    [
      (summary: ReportContentSummary) => summary.key === REPORT_CONTENT.SUMMARY.AUTO.key,
      ({ value }) => ({
        summaryType: SummaryTypeEnum.AUTO,
        summaryValue: value,
      }),
    ],
    [_.stubTrue, undefinedSummary],
  ])(summary);
};
