// @ts-strict-ignore
import { Plugin } from '@ckeditor/ckeditor5-core';
import { WidgetResize } from '@ckeditor/ckeditor5-widget';
import {
  BasePluginDependencies,
  CONTENT_MODEL_ATTRIBUTES,
  ImageContentListenerCommand,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import priorities from '@ckeditor/ckeditor5-utils/src/priorities';
import Rect from '@ckeditor/ckeditor5-utils/src/dom/rect';
import {
  getContentSpecificCommand,
  getContentWidgetViewElement,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import _ from 'lodash';
import { Observer } from '@ckeditor/ckeditor5-engine';

export const CONTENT_LOADED_EVENT = 'seeqContentLoaded';
const RESIZED_CLASS = 'contentResized';
const EDITOR_ID = 'reportEditor';

export class ContentResizeHandles extends Plugin {
  static get requires() {
    return [WidgetResize];
  }

  init() {
    const editor = this.editor;
    this.setupResizerCreator();
  }

  setupResizerCreator() {
    const editor = this.editor;
    const deps = editor.config.get(PluginDependencies.pluginName) as BasePluginDependencies;
    if (!deps.canModify || deps.isPDF) {
      return;
    }

    const editingView = editor.editing.view;

    this.listenTo(editingView.document, CONTENT_LOADED_EVENT, (event, domEvent) => {
      const widgetView = getContentWidgetViewElement(domEvent.target, editor);
      let resizer = editor.plugins.get(WidgetResize).getResizerByViewElement(widgetView);

      if (resizer) {
        // If the image refetches, we don't want to generate new resizers
        resizer.redraw();
        return;
      }

      const mapper = editor.editing.mapper;
      const imageModel = mapper.toModelElement(widgetView);
      const contentId = imageModel.getAttribute(CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT) as string;

      let isResizing = false;
      resizer = editor.plugins.get(WidgetResize).attachTo({
        unit: '%',
        modelElement: imageModel,
        viewElement: widgetView,
        editor,
        isCentered() {
          return false;
        },

        getHandleHost(domWidgetElement) {
          return domWidgetElement.querySelector('img');
        },

        getResizeHost(domWidgetElement) {
          return domWidgetElement.querySelector('img');
        },

        onCommit(newWidth) {
          isResizing = false;
          this.editor.editing.view.change((writer) => {
            writer.setStyle('width', newWidth, this.viewElement);
            writer.removeClass(RESIZED_CLASS, this.viewElement);
          });
          this.editor.model.change((writer) =>
            writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT, newWidth, this.modelElement),
          );
        },
      });

      // This is mostly the same as the default `updateSize` function that the resizer uses, but it also sets some
      // styles on the image, and stops the default event from firing.
      resizer.on(
        'updateSize',
        function (event, [domEventData]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const domHandleHost = this._getHandleHost();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!this._initialViewWidth || _.isNaN(parseFloat(this._initialViewWidth))) {
            // Fixes issues when the content has never been resized before and randomly growing to 100%
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.state._originalWidthPercents =
              (domHandleHost.width / document.getElementById(EDITOR_ID).clientWidth) * 100;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (_.isNaN(parseFloat(this._initialViewWidth))) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this._initialViewWidth = '100%';
            }
          } else if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.state._originalWidthPercents === 100 &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            parseFloat(this._initialViewWidth) !== 100 &&
            !isResizing
          ) {
            // Fixes issue where resizing content after its been resized causes it to start at 100%
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.state._originalWidthPercents = parseFloat(this._initialViewWidth);
          }

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const newSize = this._proposeNewSize(domEventData);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const editingView = this._options.editor.editing.view;

          if (!widgetView.hasClass(RESIZED_CLASS)) {
            isResizing = true;
            editingView.change((writer) => {
              writer.addClass(RESIZED_CLASS, widgetView);
            });

            // Need to fire this here as opposed to listening to model because no model changes occur until the
            // resizing is finished
            editor.fire(getContentSpecificCommand(ImageContentListenerCommand.RESIZE, contentId), true);
          }

          editingView.change((writer) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const unit = this._options.unit || '%';
            const newWidth = (unit === '%' ? newSize.widthPercents : newSize.width) + unit;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            writer.setStyle('width', newWidth, this._options.viewElement);
          });

          // Get an actual image width, and:
          // * reflect this size to the resize wrapper
          // * apply this **real** size to the state
          const domHandleHostRect = new Rect(domHandleHost);

          newSize.handleHostWidth = Math.round(domHandleHostRect.width);
          newSize.handleHostHeight = Math.round(domHandleHostRect.height);

          // Handle max-width limitation.
          const domResizeHostRect = new Rect(domHandleHost);

          newSize.width = Math.round(domResizeHostRect.width);
          newSize.height = Math.round(domResizeHostRect.height);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.redraw(domHandleHostRect);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.state.update(newSize);
          event.stop();
        },
        { priority: priorities.high },
      );

      resizer.bind('isEnabled').to(this);
    });
  }
}

export class ContentLoadObserver extends Observer {
  stopObserving(): void {}

  observe(domRoot) {
    // This is the browser's load event, which gets triggered when images finish loading.
    this.listenTo(
      domRoot,
      'load',
      (event, domEvent) => {
        const domElement = domEvent.target;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.checkShouldIgnoreEventFromTarget(domElement)) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (domElement.tagName === 'IMG' && domElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT)) {
          this.document.fire(CONTENT_LOADED_EVENT, domEvent);
        }
      },
      { useCapture: true },
    );
  }
}
