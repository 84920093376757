// @ts-strict-ignore
import { Timezone } from '@/datetime/timezone.service';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { DateTimeEntry } from '@/core/DateTimeEntry.atom';

export interface RangeField<T> {
  start: T;
  end: T;
}

type DateTimeRangeProps = {
  startTime: moment.Moment;
  endTime: moment.Moment;
  timezone: Timezone;
  hideTimezone: boolean;
  onStartTimeChange?: (value: moment.Moment) => void;
  onEndTimeChange?: (value: moment.Moment) => void;
  onChange?: (value: RangeField<moment.Moment>) => void;
  dateFormat?: string;
  trackCategory?: string;
  trackAction?: string;
  trackInformation?: RangeField<string>;
  fieldIdentifier?: RangeField<string>;
  disabled?: boolean;
};

export const DateTimeRange: React.FC<DateTimeRangeProps> = ({
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  dateFormat,
  timezone,
  trackCategory,
  trackAction,
  trackInformation,
  fieldIdentifier,
  hideTimezone,
  onChange,
  disabled,
}) => {
  const [range, setRange] = useState({
    start: startTime,
    end: endTime,
  });

  useEffect(() => {
    setRange({
      start: startTime,
      end: endTime,
    });
  }, [startTime.valueOf(), endTime.valueOf()]);

  const updateStartDate = (date: moment.Moment) => {
    const newRange = { ...range, start: date };
    if (newRange.start.isAfter(newRange.end)) {
      newRange.end = moment(newRange.start);
      setRange(newRange);
      onEndTimeChange?.(newRange.end);
    }

    onStartTimeChange?.(newRange.start);
    onChange?.(newRange);
  };

  const updateEndDate = (date: moment.Moment) => {
    const newRange = { ...range, end: date };
    if (newRange.end.isBefore(newRange.start)) {
      newRange.start = moment(newRange.end);
      setRange(newRange);
      onStartTimeChange?.(newRange.start);
    }

    onEndTimeChange?.(newRange.end);
    onChange?.(newRange);
  };

  return (
    <div className="flexColumnContainer text-center flexCenter">
      <DateTimeEntry
        testId="rangeStart"
        extraClassName="specRange specRangeStart"
        textExtraClassNames="sq-text-primary flexRowContainer"
        date={range.start}
        updateDate={updateStartDate}
        dateFormat={dateFormat}
        timezone={timezone}
        hideTimezone={hideTimezone}
        fieldIdentifier={fieldIdentifier?.start}
        trackCategory={trackCategory}
        trackAction={trackAction}
        trackInformation={trackInformation?.start}
        readOnly={disabled}
      />
      <span className="pr5 pl5">-</span>
      <DateTimeEntry
        testId="rangeEnd"
        extraClassName="specRange specRangeEnd"
        textExtraClassNames="sq-text-primary flexRowContainer"
        date={range.end}
        updateDate={updateEndDate}
        dateFormat={dateFormat}
        timezone={timezone}
        hideTimezone={hideTimezone}
        fieldIdentifier={fieldIdentifier?.end}
        trackCategory={trackCategory}
        trackAction={trackAction}
        trackInformation={trackInformation?.end}
        readOnly={disabled}
      />
    </div>
  );
};
