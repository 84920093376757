import { doTrack } from '@/track/track.service';
import { warnToast } from '@/utilities/toast.utilities';
import React, { useEffect, useState } from 'react';
import { deleteChat, getChats } from './aiAssistant.utiilities';
import { AssistantOrigin, ChatMessage } from './aiAssistant.types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';

interface ChatHistoryProps {
  onChatSelected: (chatId: string, messages: ChatMessage[], origin?: AssistantOrigin) => void;
  isRunning: boolean;
  selectedChatId: string;
}

export const ChatHistory: React.FunctionComponent<ChatHistoryProps> = ({
  isRunning,
  onChatSelected,
  selectedChatId,
}) => {
  const { t } = useTranslation();
  const [history, setHistory] = useState<Omit<HistoryEntryProps, 'onChatSelected' | 'setRefresh' | 'isSelected'>[]>([]);
  const [refresh, setRefresh] = useState<boolean | undefined>(true);

  useEffect(() => {
    setRefresh(!isRunning);
  }, [isRunning]);

  useEffect(() => {
    if (refresh) {
      const fetchData = async () => {
        try {
          const chats = await getChats();

          setHistory(
            Object.entries(chats).map(([chatId, { messages, origin, name, sharedBy, shared }]) => ({
              displayText: name || messages[0].dialog.substring(0, 100),
              chatId,
              messages,
              origin: origin as AssistantOrigin,
              sharedBy,
              shared: shared === 'true',
            })),
          );
        } catch (error) {
          warnToast({ messageKey: 'AI_ASSISTANT.HISTORY_LOADING_ERROR' });
        }
      };

      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div className="flexRowContainer aiAssistantHistory pt25 pl5 pr5">
      <div className="flexRowContainer mb25 overflowYAuto">
        <div className="pointInTime ml8">{t('AI_ASSISTANT.HISTORY_HEADER')}</div>
        {history.map(({ displayText, chatId, messages }, index) => (
          <HistoryEntry
            key={`history_${index}`}
            displayText={displayText}
            chatId={chatId}
            messages={messages}
            onChatSelected={onChatSelected}
            origin={history[index].origin}
            setRefresh={setRefresh}
            isSelected={chatId === selectedChatId}
            isRunning={isRunning}
            sharedBy={history[index].sharedBy}
            shared={history[index].shared}
          />
        ))}
      </div>
    </div>
  );
};

interface HistoryEntryProps {
  displayText: string;
  chatId: string;
  messages: ChatMessage[];
  onChatSelected: (chatId: string, messages: ChatMessage[], origin?: AssistantOrigin) => void;
  origin?: AssistantOrigin;
  setRefresh: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  isSelected: boolean;
  isRunning?: boolean;
  name?: string;
  sharedBy?: string;
  shared?: boolean;
}

const HistoryEntry: React.FunctionComponent<HistoryEntryProps> = ({
  chatId,
  messages,
  origin,
  displayText,
  onChatSelected,
  setRefresh,
  isSelected,
  isRunning,
  sharedBy,
  shared,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flexColumnContainer aiHistoryButton flexAlignCenter displayIconOnHover ${
        isSelected ? 'selected' : ''
      } ${!isRunning ? 'cursorPointer' : ''}`}
      onClick={() => {
        if (!isRunning) {
          doTrack('AiAssistant', 'chat history entry');
          onChatSelected?.(chatId, messages, origin);
        }
      }}>
      <span className="flexFill ai-text">{displayText}</span>
      {sharedBy && (
        <Icon icon="fa-share-nodes" tooltip={t('AI_ASSISTANT.SHARE.SHARED_WITH_ME')} extraClassNames="pr6 ai-button" />
      )}
      {shared && (
        <Icon icon="fa-solid fa-share-nodes" tooltip={t('AI_ASSISTANT.SHARE.SHARED')} extraClassNames="pr6 ai-button" />
      )}
      {(!isRunning || !isSelected) && (
        <span className="deleteIconContainer cursorPointer">
          <Icon
            icon="fa-remove"
            type="danger"
            tooltip={t('AI_ASSISTANT.DELETE_CHAT')}
            extraClassNames="pr3 deleteIcon ai-button"
            onClick={async (e) => {
              e?.stopPropagation();
              await deleteChat(chatId);
              setRefresh(true);
            }}
          />
        </span>
      )}
    </div>
  );
};
