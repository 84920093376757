import React from 'react';
import { useTranslation } from 'react-i18next';
import { Acl, DetailTableAce } from '@/accessControl/itemAclModal.utilities';
import { Table } from '@/core/Table.atom';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { Checkbox } from '@/core/Checkbox.atom';

interface AclModalAdvancedTabProps {
  loadingDetails: boolean;
  detailTableEntries: DetailTableAce[];
  togglePermissionsInheritance: () => void;
  itemAcl: Acl;
}

export const AclModalAdvancedTab: React.FunctionComponent<AclModalAdvancedTabProps> = (props) => {
  const { loadingDetails, detailTableEntries, togglePermissionsInheritance, itemAcl } = props;
  const { t } = useTranslation();

  const columns = [
    {
      accessor: 'name',
      sortable: false,
      filterable: false,
      cellStyle: { width: 100, padding: '0 7px' },
      header: 'ACCESS_CONTROL.NAME',
    },
    {
      accessor: 'permissions',
      sortable: false,
      filterable: false,
      cellStyle: { width: 100, padding: '0 7px' },
      header: 'ACCESS_CONTROL.PERMISSIONS',
    },
    {
      accessor: 'inheritedFrom',
      sortable: false,
      filterable: false,
      cellStyle: { width: 100, padding: '0 7px' },
      header: 'ACCESS_CONTROL.INHERITED_FROM',
    },
  ];

  return loadingDetails ? (
    <div className="flexRowContainer flexCenter mt30 mb15">
      <span className="mb5">{t('ACCESS_CONTROL.LOADING_DETAILS')}</span>
      <IconWithSpinner spinning={true} />
    </div>
  ) : (
    <div className="flexRowContainer flexFill mt15" id="aclGrid">
      {t('ITEM_ACL.LABEL')}
      {itemAcl.permissionsInheritanceDisabled && (
        <span className="sq-text-warning cursorPointer link-no-underline">
          {t('ITEM_ACL.ADVANCED.DISABLED_INDICATOR')}
        </span>
      )}
      <div className="flexFill aclGrid">
        <Table columns={columns} items={detailTableEntries} />
      </div>
      <div
        className="animateHeight250Linear lightGreyBorder border-radius-4 flexRowContainer flexJustifyCenter
      flexFill height-100 mb10">
        <div
          className="checkbox m10 cursorPointer flexRowContainer"
          onClick={(e) => {
            e.preventDefault();
            togglePermissionsInheritance();
          }}
          id="specDisableInheritanceCheckbox">
          <div className="flexColumnContainer">
            <Checkbox
              id="togglePermissionsCheckbox"
              skipMemo={true}
              isChecked={itemAcl.permissionsInheritanceDisabled}
              label="ITEM_ACL.ADVANCED.DISABLE_INHERITED_LABEL"
            />
          </div>
          <div className="ml21 mr10">{t('ITEM_ACL.ADVANCED.DISABLE_INHERITED_DESCRIPTION')}</div>
        </div>
      </div>
    </div>
  );
};
