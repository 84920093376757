import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/sql/sql';
import 'codemirror/lib/codemirror.css';

interface FormulaDisplayOnlyProps {
  formula: string;
  extraClassNames?: string;
}

export const FormulaDisplayOnly: React.FunctionComponent<FormulaDisplayOnlyProps> = ({ formula, extraClassNames }) => (
  <CodeMirror
    value={formula}
    options={{
      readOnly: true,
      mode: { name: 'php', startOpen: true },
      theme: 'seeq',
      cursorHeight: 0,
      lineWrapping: true,
      lineNumbers: false,
    }}
    onBeforeChange={() => {}}
    className={extraClassNames}
  />
);
