// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { COMPARISON_OPERATORS_SYMBOLS, PREDICATE_API } from '@/toolSelection/investigate.constants';
import { ItemPreviewV1 } from 'sdk/model/ItemPreviewV1';
import { FormulaRunOutputV1 } from 'sdk/model/FormulaRunOutputV1';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { sqFormulasApi } from '@/sdk/api/FormulasApi';
import { sqTreesApi } from '@/sdk/api/TreesApi';

import { getAssetFromAncestors } from '@/utilities/httpHelpers.utilities';
import { ASSET_PATH_SEPARATOR, STRING_UOM } from '@/main/app.constants';
import {
  encodeParameters,
  generateTemporaryId,
  getShortIdentifier,
  isAsset,
  isPresentationWorkbookMode,
  isStringSeries as isStringSeriesUtil,
} from '@/utilities/utilities';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { RangeExport } from '@/trendData/duration.store';
import { sqDurationStore, sqTrendSeriesStore, sqTrendStore } from '@/core/core.stores';
import {
  CAPSULE_PANEL_TREND_COLUMNS,
  COLUMNS_AND_STATS,
  ITEM_TYPES,
  TREND_CONDITION_STATS,
  TREND_METRIC_STATS,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
} from '@/trendData/trendData.constants';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { getCapsuleFormula, nanosToMillis } from '@/datetime/dateTime.utilities';
import { getViewCapsuleParameter } from '@/utilities/tableHelper.utilities';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { AssetSelection, Range } from '@/reportEditor/report.constants';
import { flux } from '@/core/flux.module';
import { fetchCapsuleProperties } from '@/utilities/investigateHelper.utilities';
import { isItemRedacted } from '@/utilities/redaction.utilities';
import {
  BuildAdditionalCapsuleTableFormulaCallback,
  BuildConditionFormulaCallback,
  BuildStatFormulaCallback,
  CapsuleFormulaTable,
  ComputeCapsuleTableParams,
  DEFAULT_CONDITION_TABLE_SORT,
  FetchParamsForColumn,
  NAME_SEARCH_TYPES,
  ParametersMap,
  PropertyColumn,
  RunFormulaInput,
  StatColumn,
  TableSortParams,
} from '@/utilities/formula.constants';
import { runFormula } from '@/utilities/formulaHelper.utilities';
import { ITEM_UOM } from '@/tableBuilder/tableBuilder.constants';
import { AnyProperty } from '@/utilities.types';
import { escapeRegex } from '@/utilities/stringHelper.utilities';

export const isPropertyColumn = (column: PropertyColumn | StatColumn): column is PropertyColumn =>
  column.key.includes('properties');

export const isStatColumn = (column: PropertyColumn | StatColumn): column is StatColumn =>
  column.key.includes('statistics');

export function computeSamples(args: RunFormulaInput) {
  return runFormula(['SAMPLE_SERIES', 'SAMPLE_GROUP', 'SAMPLE_AND_STATS'], args);
}

export function computeCapsules(args: RunFormulaInput) {
  return runFormula(['CAPSULE_SERIES', 'CAPSULE'], args);
}

export function computeScalar(args: RunFormulaInput) {
  return runFormula('SCALAR', args);
}

export function computeTable(args: RunFormulaInput) {
  return runFormula('TABLE', args);
}

export function computePredictionModel(args: RunFormulaInput) {
  return runFormula('PREDICTION_TABLE', args);
}

/**
 * Determines if the calculated item can successfully fetch data for the given time range by using a simple
 * identity formula. Works for any type of item the formula endpoint supports that can be calculated using a start
 * and end time.
 *
 * @param item -The item to check
 * @param range - The date range to query
 * @param cancellationGroup - The cancellation group
 * @return Promise that resolves with true if it fetched successfully or false if it failed
 */
export function canFetchData(item, range: RangeExport, cancellationGroup: string) {
  let formula = '$item';
  let start = range.start.toISOString();
  let end = range.end.toISOString();
  if (item.itemType === ITEM_TYPES.METRIC && item.definition.processType === ProcessTypeEnum.Simple) {
    formula = `group(${getCapsuleFormula(range)}).toTable('test').addSimpleMetricColumn('item', $item)`;
    start = undefined;
    end = undefined;
  } else if (item.itemType === ITEM_TYPES.METRIC) {
    formula = '$item.toCondition()';
  } else if (item.itemType === ITEM_TYPES.SCALAR) {
    start = undefined;
    end = undefined;
  }

  return sqFormulasApi.runFormula(
    {
      formula,
      parameters: encodeParameters({ item: item.id }),
      start,
      end,
      limit: 1, // Data all still has to be processed on the backend, but limits how much is returned
    },
    { cancellationGroup },
  );
}

/**
 *  Column name used on the backend for property columns is 'propertyName' otherwise it's 'key' attribute.
 * @param column - a property or statistics column to retrieve the name
 *
 * @return The right key to use as that columns name
 * */
export function getColumnNameForAnyColumn(column: PropertyColumn | StatColumn): string {
  return isPropertyColumn(column) ? column.propertyName : column.key;
}

/**
 * At some point between the boundary of here, appserver, and the compute service, backslashes can get stripped from
 * the filter input. This is problematic when escaping, as it can turn a regex looking like `a\\+b`, to `a+b`, which
 * matches very different things. Solution: double escape it.
 */
function maybeEscapeFilterInput(input: string): string {
  const escaped = escapeRegex(input);
  if (escaped !== input) {
    return escapeRegex(escaped);
  }
  return input;
}

/**
 * build a predicate for filtering out rows from a table.
 *
 * @param filter - filter to be applied on the provided column's values
 * */
export function buildTableFilterPredicate(filter: TableColumnFilter): string {
  let predicate: string;
  if (
    _.includes(
      [PREDICATE_API[COMPARISON_OPERATORS_SYMBOLS.IS_MATCH], PREDICATE_API[COMPARISON_OPERATORS_SYMBOLS.IS_NOT_MATCH]],
      filter.operator,
    )
  ) {
    if (filter.usingSelectedValues && filter.values.length > 1) {
      const regex = filter.values.map((value) => maybeEscapeFilterInput(value as string)).join('|');
      predicate = `${filter.operator}('/${regex}/')`;
    } else if (filter.usingSelectedValues) {
      predicate = `${filter.operator}('${escapeRegex(filter.values[0] as string)}')`;
    } else {
      predicate = `${filter.operator}('${filter.values[0]}')`;
    }
  } else {
    const formattedValues = _.chain(filter.values)
      .map((value) =>
        _.has(value, 'value') && _.has(value, 'units')
          ? `${(value as ValueWithUnitsItem).value}${(value as ValueWithUnitsItem).units}`
          : value,
      )
      .join(', ')
      .value();
    predicate = `${filter.operator}(${formattedValues})`;
  }

  return predicate;
}

/**
 * Build up a formula for filtering capsules on trend. Used to match filters applied on the capsules panel.
 *
 * @param conditionIdentifierFormula - identifier for the  condition ex. $series, $a
 * @param conditionId - Id for the condition being filtered
 * @param queryRangeCapsule - formula that represents the range
 * @param propertyColumns - all property columns with filters assigned
 * @param statColumns - all statistic columns with filters assigned
 * @param parameters - parameters that map variables to guids
 * @param isUnbounded - true if the condition is unbounded
 *
 * @returns {formula, parameters} formula that will be run by runFormula and parameters holding all variables of
 * the formula
 * */
export function buildFilterFragmentForConditions(
  conditionIdentifierFormula: string,
  conditionId: string,
  queryRangeCapsule: string,
  propertyColumns: PropertyColumn[],
  statColumns: StatColumn[],
  parameters,
  isUnbounded = true,
) {
  const durationFilterFragment = '.keep($capsule -> $capsule.duration()';

  const buildFilterFragmentForProperties = (column: PropertyColumn) =>
    column.propertyName === SeeqNames.Properties.Duration
      ? buildDurationFormula(column)
      : `.keep('${getColumnNameForAnyColumn(column)}', ${buildTableFilterPredicate(column.filter)})`;

  const buildDurationFormula = ({ filter: { values, operator } }: PropertyColumn) => {
    const formattedValues = _.chain(values)
      .map(({ value, units }: ValueWithUnitsItem) => `${value}${units}`)
      .join(', ')
      .value();

    return `${durationFilterFragment}.${operator}(${formattedValues}))`;
  };

  const statisticsSetPropertyFragment = _.chain(statColumns)
    .reject((column) => _.isUndefined(column.filter))
    .map((column, index) => {
      const variableName = `${getShortIdentifier(index)}Stat`;
      parameters[variableName] = column.signalId;
      return `.setProperty('${getColumnNameForAnyColumn(column)}', $${variableName}, ${column.stat})`;
    })
    .join('')
    .value();

  const statisticsFilterFragment = _.chain(statColumns)
    .reject((column) => _.isUndefined(column.filter))
    .map((column) => `.keep('${getColumnNameForAnyColumn(column)}', ${buildTableFilterPredicate(column.filter)})`)
    .join('')
    .value();

  const propertyFilterFragment = _.chain(propertyColumns)
    .reject((column) => _.isUndefined(column.filter))
    .map((column) => buildFilterFragmentForProperties(column))
    .join('')
    .value();

  // fragment limits condition to current view range because condition needs maximum duration to use .setProperty()
  const limitToViewRangeFragment =
    !_.isEmpty(statisticsFilterFragment) || _.includes(propertyFilterFragment, durationFilterFragment)
      ? `.${isUnbounded ? 'inside' : 'touches'}(condition(${queryRangeCapsule}))`
      : '';

  return `$${conditionIdentifierFormula}${limitToViewRangeFragment}${statisticsSetPropertyFragment}${statisticsFilterFragment}${propertyFilterFragment}`;
}

/**
 * Request capsules for a capsule set and group them using bucketize if there are more than the allowed limit.
 *
 * @param {Object} args - Object container for arguments
 * @param propertyColumns - All enabled property columns used to build filter formula
 * @param statisticsColumns - All enabled statistics columns used to build filter formula
 *
 * @return {Promise} Promise that is resolved with capsule results
 */
export function computeCapsulesWithLimit(
  args: {
    /** ID of the capsule series */
    id: string;
    /** displayRange or investigateRange from duration store */
    range: RangeExport;
    /** If the total number of individual capsules is below this threshold, no grouping is done, otherwise bucketize will be used to group them. */
    limit: number;
    /** A group name that can be used to cancel the requests */
    cancellationGroup: string;
    isUnbounded: boolean;
  },
  propertyColumns: PropertyColumn[],
  statisticsColumns: StatColumn[],
) {
  if (args.limit <= 0) {
    throw new Error('bucketize requires limit to be greater than 0');
  }

  const conditionIdentifierFormula = 'series';
  const parameters = { series: args.id };
  const rangeFormula = getCapsuleFormula(args.range);
  const formula = buildFilterFragmentForConditions(
    conditionIdentifierFormula,
    args.id,
    rangeFormula,
    propertyColumns,
    statisticsColumns,
    parameters,
    args.isUnbounded,
  );

  args['parameters'] = parameters;
  return computeCapsules({ formula, ...args }).then((result) => {
    // If maximum were returned then switch to grouped results
    const bucketWidthArg = `${args.range.duration.asMilliseconds() / args.limit}ms`;

    return result.capsules.length < args.limit
      ? result
      : computeCapsules(
          _.assign(
            {
              formula: `${formula}.bucketize(${bucketWidthArg})`,
            },
            args,
          ),
        );
  });
}

/**
 * Produces a formula to tack onto the end of a formula for a Table, which will determine which rows to keep. Uses
 * the .keepRows() operator.
 *
 * @param columnName - name of the column in the table we want to filter
 * @param filter - the filter to apply, containing the operator and values
 * @returns a formula for the backend to use to filter the table
 */
export function buildTableFilterFormulaFragment(columnName: string, filter: TableColumnFilter): string {
  const fragmentStart = `.keepRows('${columnName}'`;
  const predicate = buildTableFilterPredicate(filter);

  return `${fragmentStart}, ${predicate})`;
}

/**
 * build formula fragment for property columns and statistics columns.
 *
 * @param propertyColumns - property columns
 * @param statisticsColumns - statistics columns
 * */
function buildFilterFormula(propertyColumns: PropertyColumn[], statisticsColumns: StatColumn[]): string[] {
  const propertyFilterFormulas = _.chain(propertyColumns)
    .filter((column) => !!column.filter)
    .map((column) => buildTableFilterFormulaFragment(column.propertyName, column.filter))
    .value();
  const statFilterFormulas = _.chain(statisticsColumns)
    .filter((column) => !!column.filter)
    .map((column) => buildTableFilterFormulaFragment(`${column.signalId} ${column.columnSuffix}`, column.filter))
    .value();

  return _.concat(statFilterFormulas, propertyFilterFormulas);
}

/**
 * Returns a function that builds up part of the formula that filters table rows.
 * For condition table it builds additional formula fragments.
 *
 * @param propertyColumns - property columns
 * @param statisticsColumns - statistics columns
 * @param isCapsulesPanelTable - true if building filter formula for capsules pane, false if for condition table
 * @param metricFilterFormulas - formula fragment for metric filters
 * @param isRunAcrossAssets - true if condition table is run across asserts
 * @param convertUnitsIds - ids of units to convert
 * @param isHomogenizeUnits - true if units need to be homogenized
 */
export function getBuildAdditionalFormula(
  propertyColumns: PropertyColumn[],
  statisticsColumns: StatColumn[],
  isCapsulesPanelTable = false,
  metricFilterFormulas = [],
  isRunAcrossAssets = false,
  convertUnitsIds: string[] = [],
  isHomogenizeUnits = false,
): BuildAdditionalCapsuleTableFormulaCallback {
  return (ids, parameters) => {
    const filters = _.concat(buildFilterFormula(propertyColumns, statisticsColumns), metricFilterFormulas);
    if (isCapsulesPanelTable) {
      return filters.join('');
    }

    const idToShortName = _.invert(parameters);

    let convertUnitsFormula = '';
    if (isRunAcrossAssets) {
      if (isHomogenizeUnits) {
        _.forEach(convertUnitsIds, (id) => {
          const itemReference = idToShortName[id];
          const itemUomReference = `fixed_${itemReference}_${ITEM_UOM}`;
          parameters[itemUomReference] = `$${id}.property('${SeeqNames.Properties.ValueUom}')`;
          convertUnitsFormula += `.convertUnits('${id}_value', $${itemUomReference})`;
        });
      } else {
        _.forEach(convertUnitsIds, (id) => {
          convertUnitsFormula += `.convertUnits('${id}_value', '')`;
        });
      }
    }

    return `.mergeRows()${convertUnitsFormula}${filters.join('')}`;
  };
}

/**
 * Returns filtered and decorated property and statistic columns that are in the capsules panel.
 *
 * @return {Object} - an Object containing decorated property columns, statistic columns and custom column keys.
 * */
export function getPropertyAndStatisticsColumns(): {
  allDecoratedPropertyColumns: PropertyColumn[];
  allDecoratedStatColumns: StatColumn[];
  customColumnKeys: string[];
} {
  const decorateColumnsWithFilters = (columns: any[]) =>
    _.map(columns, (column) => {
      const filter = sqTrendStore.getColumnFilter(getColumnNameForAnyColumn(column));

      return filter ? _.assign(column, { filter }) : _.omit(column, ['filter']);
    });

  const propertyColumns = _.map(sqTrendStore.propertyColumns(TREND_PANELS.CAPSULES), (column: PropertyColumn) => ({
    key: column.key,
    propertyName: column.propertyName,
    invalidsFirst: true,
  }));

  const statColumns = _.chain(sqTrendStore.customColumns(TREND_PANELS.CAPSULES))
    .reject((column: any) => isItemRedacted(sqTrendSeriesStore.findItem(column.referenceSeries)))
    // Do not compute statistics in presentation mode unless they can be on the trend
    .reject(
      (column: any) =>
        isPresentationWorkbookMode() && !sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, column.key),
    )
    .map((column: any) => _.assign({}, _.find(TREND_SIGNAL_STATS, ['key', column.statisticKey]), column))
    .map((column) => {
      column.signalId = column.referenceSeries;
      delete column.referenceSeries;

      return column;
    })
    .sortBy((column) => column.signalId)
    .value();

  const REQUIRED_COLUMN_KEYS = ['startTime', 'endTime', 'isReferenceCapsule'];
  const customColumnKeys = _.map(propertyColumns.concat(statColumns), 'key');
  const combinedColumns = _.chain(CAPSULE_PANEL_TREND_COLUMNS as PropertyColumn[])
    .filter(
      (column) =>
        _.includes(REQUIRED_COLUMN_KEYS, column.key) || sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, column.key),
    )
    .concat<PropertyColumn>({
      key: 'isReferenceCapsule',
      propertyName: SeeqNames.CapsuleProperties.ReferenceCapsule,
      invalidsFirst: true,
    })
    .concat(propertyColumns)
    .concat(statColumns)
    .value();

  const FIXED_COLUMNS: PropertyColumn[] = [
    {
      key: 'capsuleId',
      invalidsFirst: true,
      propertyName: SeeqNames.CapsuleProperties.CapsuleId,
    },
    {
      key: 'cursorKey',
      invalidsFirst: true,
      propertyName: SeeqNames.CapsuleProperties.OriginalUncertainty,
    },
    {
      key: 'conditionId',
      invalidsFirst: true,
      propertyName: SeeqNames.CapsuleProperties.ConditionId,
    },
    {
      key: 'startTime',
      invalidsFirst: true,
      propertyName: SeeqNames.CapsuleProperties.Start,
    },
    {
      key: 'endTime',
      invalidsFirst: false,
      propertyName: SeeqNames.CapsuleProperties.End,
    },
  ];

  const columnsFiltered = _.reject(combinedColumns, (column) =>
    _.includes(
      _.flatMap(FIXED_COLUMNS, (col) => col.key),
      column.key,
    ),
  );
  const allColumns = _.concat(FIXED_COLUMNS as Partial<StatColumn & PropertyColumn>[], columnsFiltered);
  const allPropertyColumns = _.filter(allColumns, (column) => column.propertyName) as PropertyColumn[];
  const allStatColumns = _.filter(allColumns, (column) => column.stat) as StatColumn[];

  const allDecoratedPropertyColumns = decorateColumnsWithFilters(allPropertyColumns);
  const allDecoratedStatColumns = decorateColumnsWithFilters(allStatColumns);

  return {
    allDecoratedPropertyColumns,
    allDecoratedStatColumns,
    customColumnKeys,
  };
}

/**
 * Runs a formula function that generates a table and uses "unbound" parameters.
 *
 * @param {String} tableId - the id of the table formula to run.
 * @param {String} cancellationGroup - the group used to cancel the requests
 * @param {Object} args - Object container for arguments
 * @param {Object} args.fragments - A formula fragment object where the keys are the names of unbound formula function
 * variables and the values are the corresponding formula fragments that are used to compute the value of the
 * variable.

 * @return {Promise} that resolves when the formula run is complete and the data is available.
 */
export function runTable(tableId: string, cancellationGroup, args?): Promise<FormulaRunOutputV1> {
  const viewCapsule = getViewCapsuleParameter();
  let fragment = args && args.fragments ? args.fragments : {};
  fragment = _.set(fragment, viewCapsule.name, viewCapsule.formula);

  return sqFormulasApi
    .runFormula(
      {
        _function: tableId,
        fragments: encodeParameters(fragment),
        formula: null,
        parameters: null,
      },
      { cancellationGroup },
    )
    .then(({ headers, data }) => {
      if (!_.isNil(headers['server-timing'])) {
        (data as any).timingInformation = headers['server-timing'];
      }
      if (!_.isNil(headers['server-meters'])) {
        (data as any).meterInformation = headers['server-meters'];
      }

      return data;
    });
}

/**
 * Extracts a map of short identifiers, used in Formula input, to their corresponding real id's
 *
 * @param conditionIds - The id's associated with the conditions in the intended Formula
 * @param statColumns - the statColumns that will be added in this Formula
 *
 * @returns a map of short identifiers to real ids, in the form used by runFormula
 */
export function extractParametersMap(conditionIds: string[], statColumns: StatColumn[]): ParametersMap {
  let formulaVariableIndex = 0;

  return _.merge(
    _.transform(
      conditionIds,
      (memo, id) => {
        memo[getShortIdentifier(formulaVariableIndex++)] = id;
      },
      {} as Record<string, string>,
    ),
    _.chain(statColumns)
      .uniqBy('signalId')
      .transform((memo, column) => {
        memo[getShortIdentifier(formulaVariableIndex++)] = column.signalId;
      }, {})
      .value(),
  );
}

/**
 * Convert the condition ids to a formula counterpart
 *
 * @param ids - ids of all conditions in the table
 * @param parameters - The map between the parameter identifier and item id
 *
 * @return The formula snippet that represents all the items and the parameter map.
 */
export function buildBasicConditionFormula(ids: string[], parameters) {
  const idToShortName = _.invert(parameters);
  return {
    formula: _.map(ids, (id) => `$${idToShortName[id]}`).join(', '),
    parameters,
  };
}

/**
 * Builds up the Formula fragment for the stat columns desired for a particular table.
 *    Ex: ".addStatColumn('s1', $s1, average()).addStatColumn('s2', $s2, range(), average())"
 *
 * @param statColumns - ordered list of stats for this table. Stats are ordered by their signalId first, then by
 * their stat formula. E.g., in the above example, the statColumns would be in the order [s1.average, s2.range,
 * s2.average]
 * @param parameters - Formula parameters map. We may add additional parameters in the map
 *
 * @returns the Formula segment for the given StatColumn(s), which can be appended to a table Formula. The order
 * of the columns will be ordered first by signalId, then by stat.
 */
export function buildStatFormulaFragment(statColumns: StatColumn[], parameters: ParametersMap): string {
  const mapIdsToShortIdentifiers = _.invert(parameters);

  return _.chain(statColumns)
    .transform((memo, column: StatColumn) => {
      memo[column.signalId] = (memo[column.signalId] ?? []).concat(column.stat);
    }, {} as Record<string, string[]>)
    .flatMap((statsList: string[], signalId) => {
      const identifier = mapIdsToShortIdentifiers[signalId];
      const commaSeparatedStats = _.join(statsList, ', ');

      return `.addStatColumn('${signalId}', $${identifier}, ${commaSeparatedStats})`;
    })
    .join('')
    .value();
}

/**
 * throws an error if there is not at least one propertyColumn
 *
 * @param propertyColumns - The columns that are properties of the capsule. At least one required.
 *    Ex: 'Start', 'End', 'Condition ID', 'Capsule ID', etc.
 * @returns true if there is at least one property column
 * @throws Error if there is not at least one property column
 */
function assertAtLeastOnePropertyColumn(propertyColumns: PropertyColumn[]) {
  if (propertyColumns.length < 1) {
    throw new TypeError('There must be at least one property column');
  }
}

/**
 * Builds the Formula fragment that groups all the given PropertyColumns together
 *
 * @param propertyColumns - The columns that are properties of the capsule. At least one required.
 *    Ex: 'Start', 'End', 'Condition ID', 'Capsule ID', etc.
 *
 * @returns the Formula fragment that groups together the property columns
 *
 * @throws Error if there is not at least one property column
 */
export function buildGroupFormulaFragment(propertyColumns: PropertyColumn[]): string {
  assertAtLeastOnePropertyColumn(propertyColumns);
  const capsuleProperties = _.chain(propertyColumns)
    .map((column) => `'${column.propertyName}'`)
    .join(', ')
    .value();

  return `group(${capsuleProperties})`;
}

/**
 * Builds the Formula fragment that constructs the CapsuleTable, with only the property columns.
 *
 * @param conditionFormula - The formula fragment the conditions
 * @param propertyColumns - The columns that are properties of the capsule. At least one required.
 *    Ex: 'Start', 'End', 'Condition ID', 'Capsule ID', etc.
 * @param range - The time range that this query covers
 *
 * @returns the CapsuleTable() Formula fragment
 *
 * @throws Error if there is not at least one property column
 */
export function buildPropertyColumnsFragment(
  conditionFormula: string,
  propertyColumns: PropertyColumn[],
  range: Range,
) {
  assertAtLeastOnePropertyColumn(propertyColumns);
  const propertyColumnFragment = buildGroupFormulaFragment(propertyColumns);

  return `capsuleTable(capsule('${moment(range.start).toISOString()}', '${moment(
    range.end,
  ).toISOString()}'), CapsuleBoundary.Overlap, ${propertyColumnFragment}, ${conditionFormula})`;
}

/**
 * Builds the table .sort() Fragment.
 *
 * @param sortParams
 * @param sortParams.sortBy - The column key to sort by
 * @param sortParams.sortAsc - False to sort descending
 * @param sortParams.orderedAdditionalSortPairs - a list of additional sort pairs. These will be secondary sorts
 * @param propertyColumns - The columns that are properties of the capsuleTable.
 * @param statColumns - The columns of the table that are statistics of a signal during a given capsule. Ex:
 * $series.range(), $series.percentGood, etc
 *
 * @returns the Formula fragment that can be used to sort a table in Calc Engine
 */
export function buildCapsuleTableSortFragment(
  sortParams: TableSortParams,
  propertyColumns: PropertyColumn[],
  statColumns: StatColumn[],
) {
  assertAtLeastOnePropertyColumn(propertyColumns);

  const columns = _.concat(propertyColumns as Partial<StatColumn & PropertyColumn>[], statColumns);
  const { sortBy, sortAsc, orderedAdditionalSortPairs } = sortParams;

  const buildSecondarySortOrderString = (sortOrder: { sortBy: string; sortAsc: boolean }) => {
    const index = _.findIndex(columns, { key: sortOrder.sortBy });
    const column = columns[index] || { propertyName: sortOrder.sortBy };
    const identifier = (column as StatColumn).signalId;
    const propertyName = (column as PropertyColumn).propertyName;
    const columnSuffix = (column as StatColumn).columnSuffix;
    const columnHeader = propertyName ? propertyName : `${identifier} ${columnSuffix}`;
    const direction = sortOrder.sortAsc ? 'asc' : 'desc';

    return `'${columnHeader}', '${direction}'`;
  };

  let indexSortBy = _.findIndex(columns, (column) => column.key === sortBy);
  if (indexSortBy < 0 && !sortParams.isCustomColumn) {
    indexSortBy = _.findIndex(columns, {
      key: COLUMNS_AND_STATS.startTime.key,
    });
    indexSortBy = indexSortBy >= 0 ? indexSortBy : 0;
  }

  const column = columns[indexSortBy] || { propertyName: sortParams.sortBy };
  // Depending on the column type, the column MUST have either columnSuffix or propertyName. There is at least one
  // property column, so only one  of these will be undefined.
  const columnSuffix: string | undefined = (column as StatColumn).columnSuffix;
  const propertyName: string | undefined = (column as PropertyColumn).propertyName;

  // Sort invalids first is required for Capsule Panel Table when sorting ascending, except when sorting by endTime,
  // but default treatment of invalids is desired when sorting descending.
  const direction = column.invalidsFirst ? "'inv, asc'" : "'asc'";
  const identifier = (column as StatColumn).signalId;
  const columnToSort = propertyName ? propertyName : `${identifier} ${columnSuffix}`;
  const primarySortArgs = `'${columnToSort}', ${direction}`;
  const additionalSorts = _.chain(orderedAdditionalSortPairs)
    .flatMap((sortOrder) => buildSecondarySortOrderString(sortOrder))
    .join(', ')
    .value();
  const finalSortArguments = _.chain([primarySortArgs, additionalSorts]).reject(_.isEmpty).join(', ').value();
  const initialSortFragment = `.sort(${finalSortArguments})`;

  return sortAsc ? initialSortFragment : `${initialSortFragment}.reverse()`;
}

/**
 * Build the table limit() Formula fragment
 *
 * @param rowOffset - number of rows to offset into
 * @param maxRows - max number of rows to return
 *
 * @returns the Formula fragment for setting a table limit
 */
export function buildTableLimitFormulaFragment(rowOffset: number, maxRows: number) {
  // the API says we start at zero, but the table object is 1 based, so we add 1
  const startRow = rowOffset + 1;
  // we also want one extra row than the maxRows in order to determine if there is more data
  const endRow = startRow + maxRows;

  return `.limit(${startRow}, ${endRow})`;
}

/**
 * Builds the table formula and sends it to the backend, returning the result as a CapsuleTable. Returns a row for
 * each capsule, and columns that are either capsule properties or signal statistics during that capsule. Both
 * propertyColumns and statColumns should be given in the desired output order.
 *
 * @param columns - The ordered columns of the desired tables, split into CapsulePropertyColumns and StatColumns
 * @param columns.propertyColumns - The columns that are properties of the capsule. These would be grouped using
 * group() in Formula. Ex: 'Start', 'End', 'Duration', etc
 * @param columns.statColumns - The columns of the table that are statistics of a signal during a given capsule. Ex:
 * $series.range(), $series.percentGood, etc
 * @param range - The time range that this query covers
 * @param itemIds - The array of Condition or Metric Ids. These will be converted to formulas using
 *    buildConditionFormula
 * @param sortParams - For sorting the table
 * @param cancellationGroup - the cancellation group
 * @param offset - Row offset into the table results
 * @param limit - max number of rows desired in returned table. If formula generates more than this, the
 * hasNextPage flag of the return value will be true.
 * @param additionalFormula - extra formula segment to be tacked onto the end of the capsule table formula
 * @param buildAdditionalFormula - Can be used to add additional formula snippet to the end of the formula
 * @param buildConditionFormula - converts the items ids and corresponding parameters to a formula
 * representation of the condition. (e.g. for metrics, the method passed in converts a metric to a condition
 * using formula and the parameters modified to support extra properties)
 * @param root - Used to run a formula across assets. The ID of the root asset.
 * @param reduceFormula - Used when running a formula across assets, a formula that can further reduce the results of
 *   each asset result.
 *
 * @returns FormulaTable with the table and headers, and a flag for whether there are additional results
 *
 * @throws Error if there is not at least one property column
 */
export function computeCapsuleTable({
  columns,
  range,
  itemIds,
  sortParams,
  offset,
  limit,
  buildAdditionalFormula = () => '',
  buildConditionFormula = buildBasicConditionFormula,
  buildStatFormula = buildStatFormulaFragment,
  cacheTableFormula = '',
  root,
  reduceFormula,
  cancellationGroup,
}: ComputeCapsuleTableParams): Promise<CapsuleFormulaTable> {
  const { propertyColumns, statColumns } = columns;
  if (!_.some(propertyColumns, { key: 'capsuleSortKey' })) {
    propertyColumns.push({
      key: 'capsuleSortKey',
      propertyName: SeeqNames.CapsuleProperties.CapsuleIdSafe,
      invalidsFirst: true,
    });
  }
  if (!_.isUndefined(sortParams)) {
    const { orderedAdditionalSortPairs } = sortParams;
    const defaultSort = { sortBy: 'capsuleSortKey', sortAsc: true };
    sortParams.orderedAdditionalSortPairs = orderedAdditionalSortPairs || [];
    if (
      !_.some(sortParams.orderedAdditionalSortPairs, {
        sortBy: 'capsuleSortKey',
      })
    ) {
      sortParams.orderedAdditionalSortPairs.push(defaultSort);
    }
  }
  const itemParameters = extractParametersMap(itemIds, statColumns);

  const { formula: conditionFormulas, parameters } = buildConditionFormula(itemIds, itemParameters);

  const formula =
    buildPropertyColumnsFragment(conditionFormulas, propertyColumns, range) +
    buildStatFormula(statColumns, itemParameters) +
    cacheTableFormula +
    buildAdditionalFormula(itemIds, itemParameters) +
    (!_.isUndefined(sortParams) ? buildCapsuleTableSortFragment(sortParams, propertyColumns, statColumns) : '') +
    buildTableLimitFormulaFragment(offset, limit);

  let columnIndex = 0;
  const mapColumnKeysToColumnIndex = _.chain(propertyColumns)
    .concat(
      _.flatMap((column: PropertyColumn) => _.pick(column, 'key')),
      statColumns.map((statColumn) => ({ ...statColumn, propertyName: undefined })),
    )
    .reject((column) => column.propertyName === SeeqNames.CapsuleProperties.CapsuleIdSafe)
    .flatMap((column) => column.key)
    .transform((result, key) => {
      result[key] = columnIndex++;
    }, {} as Record<string, number>)
    .value();

  return computeTable({
    formula,
    cancellationGroup,
    limit,
    offset,
    parameters,
    root,
    reduceFormula,
    usePost: true, // Formula can be very long
  }).then((results) => {
    // one column will be the Capsule SortKey, which we added w/out telling the caller, so we do not want to pass
    // it through to the caller
    const indexOfColumnToRemove = _.findIndex(results.headers, {
      name: SeeqNames.CapsuleProperties.CapsuleIdSafe,
    });
    _.pullAt(results.headers, [indexOfColumnToRemove]);
    _.forEach(results.data, (row) => {
      _.pullAt(row, [indexOfColumnToRemove]);
    });

    // Make it easy to find the header column for stat columns
    _.forEach(statColumns, (statColumn) => {
      if (results.headers && results.headers[mapColumnKeysToColumnIndex[statColumn.key]]) {
        results.headers[mapColumnKeysToColumnIndex[statColumn.key]].name = statColumn.key;
      }
    });

    // Add any columns that were dynamically added via .addColumn()
    _.forEach(results.headers, (header, index: number) => {
      if (!_.has(mapColumnKeysToColumnIndex, header.name)) {
        mapColumnKeysToColumnIndex[header.name] = index;
      }
    });

    results.table = _.map(results.data, (row) => {
      const indexOfCapsuleId = _.findIndex(results.headers, {
        name: SeeqNames.CapsuleProperties.CapsuleId,
      });
      if (indexOfCapsuleId >= 0 && !row[indexOfCapsuleId]) {
        // Generate a temporary ID for results missing one
        row[indexOfCapsuleId] = generateTemporaryId();
      }
      const newRow = _.transform(
        mapColumnKeysToColumnIndex,
        (rowObject, index: number, key) => {
          rowObject[key] = row[index];
        },
        {} as AnyProperty,
      );
      newRow.isUncertain = !_.isNil(newRow.cursorKey);
      newRow.startTime = newRow.startTime ? nanosToMillis(newRow.startTime) : null;
      newRow.endTime = newRow.endTime ? nanosToMillis(newRow.endTime) : null;
      if (newRow.cursorKey === -1) {
        newRow.cursorKey = null;
      }

      return newRow;
    });
    const hasNextPage = results.data.length > limit;
    if (hasNextPage) {
      results.table = results.table.splice(0, limit);
    }

    return {
      data: {
        table: results.table,
        hasNextPage,
        headers: results.headers,
        timingInformation: results.timingInformation,
        meterInformation: results.meterInformation,
        warningCount: results.warningCount,
        warningLogs: results.warningLogs,
      },
    };
  });
}

/**
 * Produces a formula to tack onto the end of a formula for a Simple Table, which will
 * determine which rows to keep.
 * Uses the .keepColumnValues() operator.
 *
 * @param columnName - name of the column in the table we want to filter
 * @param filter - the filter to apply, containing the operator and values
 * @param [aggregationFunction] - an aggregation function, relevant only if the stat doesn't map directly
 * to a column name (MinValue and MaxValue)
 *
 * @returns a formula for the backend to use to filter the table
 */
export function buildSimpleTableFilterFormulaFragment(columnName: string, filter: TableColumnFilter): string {
  const predicate = buildTableFilterPredicate(filter);

  return `.keepColumnValues('${columnName}', ${predicate})`;
}

/**
 * Finds the column suffix for the specified aggregation function.
 *
 * @param aggregationFunction - the aggregation function
 *
 * @returns the column suffix or an empty string
 */
export function findColumnSuffix(aggregationFunction: string): string {
  const statColumn = _.find([...TREND_SIGNAL_STATS, ...TREND_CONDITION_STATS, ...TREND_METRIC_STATS], (column) => {
    if (column.prefix) {
      return _.startsWith(aggregationFunction, column.prefix);
    }

    return column.stat === aggregationFunction;
  });

  return statColumn?.columnSuffix ?? '';
}

/**
 * Gets params needed to produce a pick-list for string-value property columns in a table.
 *
 * @param conditions - conditions to request capsule properties for.
 * @param propertyColumn - property column that matches columnKey that is being filtered.
 * @param assetId - The root asset id if it is run across assets.
 *
 * @returns Promise that resolves to an array of params needed to build up a table formula that will return the
 * pick-list of values for the property.
 */
export function getStringPropertyFetchParams(
  conditions,
  propertyColumn: PropertyColumn,
  assetId?: string,
): Promise<FetchParamsForColumn> {
  return Promise.all(
    _.map(conditions, (condition) =>
      // Find all conditions that have the property
      fetchCapsuleProperties(condition.id).then((capsuleProperties) =>
        _.some(
          capsuleProperties,
          (capsuleProperty) =>
            capsuleProperty.name === propertyColumn.propertyName && capsuleProperty.unitOfMeasure === STRING_UOM,
        )
          ? condition.id
          : undefined,
      ),
    ),
  ).then((conditionIds) => {
    const buildAdditionalFormula = () => `.distinctColumnValues('${propertyColumn.propertyName}')`;

    return {
      columnKeyAndName: {
        columnKey: propertyColumn.key,
        columnName: propertyColumn.key,
      },
      fetchParams: {
        ids: _.compact(conditionIds),
        propertyColumns: [propertyColumn],
        statColumns: [],
        assetId,
        buildAdditionalFormula: assetId ? undefined : buildAdditionalFormula,
        reduceFormula: assetId ? `$result${buildAdditionalFormula()}` : undefined,
        sortParams: DEFAULT_CONDITION_TABLE_SORT(),
        itemColumnsMap: undefined,
      },
    };
  });
}

/**
 * Determines the default name for an item that can be created. Given a prefix it finds all other items
 * starting with that prefix and then finds the maximum in that set.
 *
 * @param {String} prefix - The prefix of the item, assumed to not have regex special characters
 * @param {String|undefined} scope - IDs of workbooks which will limit the results to those items that are scoped
 *   to the workbooks or are in the global scope, undefined to search all items.
 *
 * @returns {Object} Empty object that fills in the name property when the promise resolves
 */
export function getDefaultName(prefix: string, scope?: string, filters: string[] = []): Promise<string> {
  // Get any items named with the prefix "Prefix" or the prefix with a number "Prefix 2" etc
  const searchRegex = new RegExp(`^\\s*${_.escapeRegExp(prefix)}\\s*(\\d+)?\\s*$`, 'i');

  return sqItemsApi
    .searchItems({
      filters: [`name==/${searchRegex.source}/`, ...filters],
      types: NAME_SEARCH_TYPES,
      scope: scope ? [scope] : undefined,
      limit: 10000,
    })
    .then(({ data }) =>
      _.chain(data?.items)
        .map(({ name }) => {
          const match = searchRegex.exec(name);

          // Treat a "Prefix" as if it was "Prefix 1" since they are somewhat ambiguous. This lets us generate a
          // series of names like "Prefix", "Prefix 2", "Prefix 3" instead of "Prefix", "Prefix 1", "Prefix 2"
          return match && (_.isNil(match[1]) ? 1 : parseInt(match[1], 10));
        })
        .max()
        .thru((num) => `${prefix} ${(_.isFinite(num) ? num : 0) + 1}`)
        .value(),
    );
}

/**
 * Find the items whose asset parents are the asset parents of this item.
 *
 * If this item has an asset parent, we will use that parent directly. Otherwise we'll (effectively) recurse
 * through the parameters until we find items with asset parents, or leaf nodes. See CRAB-16532.
 *
 * @param item The root item
 * @param dependencies The item's dependencies
 */
function getDependenciesWithRelevantAssets(item, dependencies) {
  // *******************************************************************************************************************
  //  This function has been ported 1:1 to Python as sdk/pypi/seeq/spy/_swap.py#_get_dependencies_with_relevant_assets()
  //  so that the swap functionality there behaves identically to Seeq Workbench.
  //
  //  IF YOU CHANGE THIS FUNCTION, YOU MUST ALSO CHANGE THE PYTHON VERSION.
  // *******************************************************************************************************************

  // Keep track of every ID we find that matters. This should end up being items with assets, or assetless leaf nodes.
  const resultIdSet = [];

  const findParameters = _.chain(dependencies)
    .flatMap((dependency) => _.map(dependency.parameterOf, (item) => [item.id, dependency]))
    .groupBy(_.first)
    .map((pairs, dependee) => [dependee, _.map(pairs, (pair) => pair[1])])
    .fromPairs()
    .value();

  // Items whose assets we're currently trying to find out. Start with the root item.
  let queue = [item];

  while (!_.isEmpty(queue)) {
    // take the first param.
    const current = queue.pop();

    if (_.includes(resultIdSet, current.id)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!_.isEmpty(current.ancestors)) {
      // if it has an ancestor, put its id in the resultIds, we're done with it.
      resultIdSet.push(current.id);
    } else {
      // if not, add its parameters to the queue of possible ancestors
      const parameters = findParameters[current.id];

      if (_.isEmpty(parameters)) {
        // leaf node; add to results so we don't bother with it anymore
        resultIdSet.push(current.id);
      } else {
        // not already examined, no asset ancestor - recurse to its parameters
        queue = queue.concat(parameters);
      }
    }
  }

  // Include the original item in the possible results we pick from
  const possibleSet = _.concat(dependencies, item);

  return _.filter(possibleSet, (d) => _.includes(resultIdSet, d.id));
}

/**
 * Extracts dependency information from the provided item and adds the assets property.
 *
 * @param {Object} options - Item information
 * @param {String} options.id - Id of the item
 *
 * @returns {Object} Transformed object containing new properties.
 *                   {Object[]} .assets - Array of asset dependencies
 *                   {String} .assets[].id - ID of asset dependency
 *                   {String} .assets[].name - Name of asset dependency
 *                   {String} .assets[].formattedName - Name that includes the full asset path
 */
export function getDependencies(options: { id: string }): Promise<any> {
  return sqItemsApi.getFormulaDependencies(options).then(({ data }) => {
    const items = getDependenciesWithRelevantAssets(data, data.dependencies);

    const assets = _.chain(items)
      .map('ancestors')
      .reject(_.isEmpty)
      .map(getAssetFromAncestors)
      .sortBy(['formattedName'])
      .uniqBy('id')
      .value();

    return { ...data, assets };
  });
}

/**
 * Get the fetch params needed to get a pick-list of options for a statistic column. Only the endValue statistic
 * supports this.
 */
export function getStringStatFetchParams(
  columnKey: string,
  statColumns: StatColumn[],
  allColumns: any[],
  ids: string[],
  getBuildStatFormula: (statColumn: StatColumn) => BuildStatFormulaCallback,
  assetId?: string,
  buildConditionFormula?: BuildConditionFormulaCallback,
): FetchParamsForColumn | undefined {
  const statColumn = _.find(
    statColumns,
    (column: any) =>
      column.statisticKey === 'statistics.endValue' &&
      column.key === columnKey &&
      isStringSeriesUtil(sqTrendSeriesStore.findItem(column.signalId)),
  );
  if (statColumn) {
    const buildAdditionalFormula = () => `.distinctColumnValues('${statColumn.signalId} ${statColumn.columnSuffix}')`;

    return {
      columnKeyAndName: { columnKey: statColumn.key, columnName: statColumn.key },
      fetchParams: {
        ids,
        assetId,
        propertyColumns: [
          _.find(allColumns, {
            key: COLUMNS_AND_STATS.startTime.key,
          }) || COLUMNS_AND_STATS.startTime,
          _.find(allColumns, { key: COLUMNS_AND_STATS.endTime.key }) || COLUMNS_AND_STATS.endTime,
        ],
        statColumns: [statColumn],
        reduceFormula: assetId ? `$result${buildAdditionalFormula()}` : undefined,
        buildAdditionalFormula: assetId ? undefined : buildAdditionalFormula,
        buildConditionFormula,
        sortParams: DEFAULT_CONDITION_TABLE_SORT(),
        itemColumnsMap: undefined,
        buildStatFormula: getBuildStatFormula(statColumn),
      },
    };
  }
}

/**
 * Gets the necessary information to fetch distinct string values that appear in the column.
 * The fetch params correspond to a capsule table that has only the necessary information to get the
 * column's string values.
 *
 * @param allColumns - all columns enabled in the table
 * @param conditions - all conditions in capsules panel
 * @param statColumns - all enabled statistic columns
 * @param propertyColumns - all enabled property columns
 * @param columnKey - column for which to fetch string values
 *
 * @returns obj.fetchParams: fetch param object, with a table formula
 *          obj.columnKeyAndName: array of {columnKey, columnNames} objects where columnKey is the key of
 *            the displayed frontend table, and columnNames contains the names of the corresponding columns in
 *            the computed table returned from the backend, since those columns are combined to form the
 *            displayed table.
 */
export function getCapsulesPanelStringColumnFetchParams(
  allColumns,
  conditions,
  statColumns: StatColumn[],
  propertyColumns: PropertyColumn[],
  columnKey: string,
): Promise<FetchParamsForColumn> {
  const statParams = getStringStatFetchParams(
    columnKey,
    statColumns,
    allColumns,
    _.map(conditions, 'id'),
    (statColumn) => getBuildStatFormulaFunctionCallback([statColumn]),
  );
  if (statParams) {
    return Promise.resolve(statParams);
  }

  const propertyColumn = _.find(propertyColumns, ({ propertyName }) => propertyName === columnKey);
  if (propertyColumn) {
    return getStringPropertyFetchParams(conditions, propertyColumn);
  }

  throw new Error('trying to fetch string values for an invalid column');
}

/**
 * Get the callback to pass to the formula service, that creates formulas for signal statistic columns
 * Used for computing the table.
 *
 * @param statColumns - list of statistic columns in the table
 * @param itemTransformer - callback function that returns item reference formula
 *
 * @returns callback that gets the condition formulas for conditions
 */
export function getBuildStatFormulaFunctionCallback(
  statColumns: any[],
  itemTransformer: (itemIdentifier: string, signalId: string, parameters: ParametersMap) => string = (i) => i,
): BuildStatFormulaCallback {
  return (statsList: StatColumn[], parameters: ParametersMap) => {
    const mapIdsToShortIdentifiers = _.invert(parameters);
    const statColumnsFormula = _.chain(statColumns)
      .transform((memo, column: StatColumn) => {
        memo[column.signalId] = _.concat(memo[column.signalId] ?? [], column.stat);
      }, {} as Record<string, string[]>)
      .flatMap((statsList: string[], signalId) => {
        const itemReference = itemTransformer(`$${mapIdsToShortIdentifiers[signalId]}`, signalId, parameters);
        const commaSeparatedStats = _.join(statsList, ', ');

        return `.addStatColumn('${signalId}', ${itemReference}, ${commaSeparatedStats})`;
      })
      .join('')
      .value();

    return `${statColumnsFormula}`;
  };
}

/**
 * Get distinct string values for a table
 *
 * Fetch the distinct string values for each string-valued column in a table
 * noop in presentation mode since the filters can't be changed.
 *
 * @param fetchParams - fetch param object, for the column that values are being fetched for
 * @param columnKeyAndName - column key and name for the table that will fetch values for string column
 * @param cancellationGroup - cancellation group
 * @param dispatchAction - action that is invoked on the returned string values
 *
 * @returns promise for computing the table with values
 */
export function fetchTableDistinctStringValues(
  fetchParams,
  columnKeyAndName: { columnKey: string; columnName: string },
  dispatchAction: string,
  cancellationGroup: string,
): Promise<void> {
  const noResultsPayload = { data: { table: [] } };
  return (
    (
      _.isEmpty(fetchParams)
        ? Promise.resolve(noResultsPayload)
        : computeCapsuleTable({
            columns: {
              propertyColumns: fetchParams.propertyColumns,
              statColumns: fetchParams.statColumns,
            },
            range: {
              start: sqDurationStore.displayRange.start.valueOf(),
              end: sqDurationStore.displayRange.end.valueOf(),
            },
            itemIds: fetchParams.ids,
            buildConditionFormula: fetchParams.buildConditionFormula,
            sortParams: fetchParams.sortParams,
            root: fetchParams.assetId,
            reduceFormula: fetchParams.reduceFormula,
            buildAdditionalFormula: fetchParams.buildAdditionalFormula,
            buildStatFormula: fetchParams.buildStatFormula,
            cacheTableFormula: '',
            offset: 0,
            limit: 10000,
            cancellationGroup,
          })
    )
      // CRAB-27265: Do not error if run across assets and one of the conditions is not asset-based
      .catch(() => noResultsPayload)
      .then((stringValueTable) => {
        flux.dispatch(dispatchAction, {
          stringValueTable,
          columnKeyAndName,
        });
      })
  );
}

/**
 * Get the asset's path in its asset tree as a string, with >> as a separator
 *
 * @param asset - the child asset whose upstream path you want
 * @param maxDepth - The max number of layers to return of the path. 1 results in just the asset name, 2 results
 * in a path that includes the asset name, and the immediate parent's name, like PARENT >> CHOSEN_ASSET. Use
 * undefined for no limit
 */
export function getAssetPath(asset: any, maxDepth?: number | undefined): Promise<string> {
  return getDependencies({ id: asset.id }).then(({ ancestors, name }) =>
    getAssetPathFromAncestors(ancestors.concat({ name }), maxDepth),
  );
}

/**
 * Get the asset's path from ancestors as a string, with >> as a separator
 *
 * @param ancestors - asset ancestors
 * @param maxDepth - The max number of layers to return of the path. 1 results in just the asset name, 2 results
 * in a path that includes the asset name, and the immediate parent's name, like PARENT >> CHOSEN_ASSET. Use
 * undefined for no limit
 */
export function getAssetPathFromAncestors(ancestors: ItemPreviewV1[], maxDepth?: number): string {
  return _.chain(!_.isNil(maxDepth) ? _.takeRight(ancestors, Math.max(1, maxDepth)) : ancestors)
    .map('name')
    .join(ASSET_PATH_SEPARATOR)
    .value();
}

/**
 * Fetch the siblings of an asset so we can display / change the values inside an AssetSelection
 * @param selection the specific assetSelection
 *
 * @returns Promise<Asset[]> List of assets available for this AssetSelection
 */
export function getAssetSiblings(selection: AssetSelection) {
  const parent = _.last(selection.asset.ancestors);

  return sqTreesApi.getTree({ id: parent.id }).then(
    ({
      data: {
        item: { ancestors },
        children,
      },
    }) =>
      _.chain(children)
        .filter(isAsset)
        .map((child) => ({
          ..._.omit(child, ['properties']), // Omit properties to save memory and because not needed
          ancestors: ancestors.concat(parent), // Ensures parent can be found when sibling is selected
          name: getAssetPathFromAncestors(ancestors.concat([parent, child]), selection.assetPathDepth || undefined),
        }))
        .value(),
  );
}
